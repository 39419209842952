import { POST } from "../API/axios";
import axios, { AxiosError } from "axios";
import printJS from "print-js";
import { PDFDocument } from "pdf-lib";

interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}

export const uploadFile = (file: Blob): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    POST("upload", formData, true)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const renameFileByUrl = async (url: string, fileName: string) => {
  return await axios({
    url: url,
    method: "GET",
    responseType: "arraybuffer",
  })
    .then(async (response) => {
      const pdfDoc = await PDFDocument.load(response.data);
      pdfDoc.setTitle(fileName);
      const pdfBytes = await pdfDoc.save();

      const href = URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
      return { error: false, data: href, response: response };
    })
    .catch((e) => ({ error: true, data: null, response: null }));
};

export const fileDownloaderWithRename = async (url: string, fileName: string) => {
  return await renameFileByUrl(url, fileName)
    .then((res) => {
      const link = document.createElement("a");
      link.href = res.data || "";
      link.setAttribute("download", `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      return { error: false, data: res.response };
    })
    .catch((e) => ({ error: true, data: null }));
};

export const fileDownloaderDefault = async (url: string, fileName: string) => {
  return await axios({
    url: url,
    method: "GET",
    responseType: "arraybuffer",
  })
    .then(async (response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      return { error: false, data: response };
    })
    .catch((e) => ({ error: true, data: null }));
};

export const fileDownloader = async (url: string, fileName: string) => {
  try {
    const res = await fileDownloaderWithRename(url, fileName);
    if (res?.error) {
      const res = await fileDownloaderDefault(url, fileName);
    }
  } catch (e) {
    console.log("fileDownloader error", e);
  }
};

export const openFilePrint = async (url: string, fileName: string) => {
  try {
    console.log(3);
    const res = await renameFileByUrl(url, fileName);
    console.log(4);
    printJS({
      printable: !res.error ? res.data : url,
      documentTitle: fileName,
      showModal: false,
      type: "pdf",
    });
    return;
  } catch (e) {
    printJS({
      printable: url,
      documentTitle: fileName,
      showModal: false,
      type: "pdf",
    });
    return;
  }
};
