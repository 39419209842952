import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { ChecklistIcon } from "../../assets/icons";
import Button from "../Button";
import styles from "./styles.module.scss";
import "rc-slider/assets/index.css";
import clsx from "clsx";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import {
  setIsOpenWeightTracker,
  setProgress,
  setProgressDay,
} from "../../store/reducers/ProgressSlice";
import TrackersInput from "../TrackersInput";
import { ITrackers } from "../../models/ITrackers";
import { setTrackers } from "../../services/progress.service";
import { convertIbsToKg, convertKgToIbs } from "../../utils";
import { IProgress } from "../../models/IProgress";
import { updateProgressDay } from "../../utils/progress";

interface IProps {
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
}

const WeightTracker: FC<IProps> = ({
  currentProgress,
  setCurrentProgress,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isOpenWeightTracker, currentDay, progress } = useAppSelector(
    (state) => state.processReducer
  );
  const [activeType, setActiveType] = useState<number>(0);
  const [value, setValue] = useState<string>("0");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const onClose = () => {
    dispatch(setIsOpenWeightTracker(false));
  };

  const onChangeWeight = async () => {
    try {
      setIsLoadingSubmit(true);

      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          weight: {
            kg: activeType === 1 ? +Number(value).toFixed(1) : convertIbsToKg(+value),
            ibs: activeType === 0 ? +Number(value).toFixed(1) : convertKgToIbs(+value),
          },
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          onClose();
        }
      }

      setIsLoadingSubmit(false);
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (!isOpenWeightTracker) return;
    const weightvalue = handledProgress?.days[currentDay]?.trackers?.weight?.kg;
    setValue(`${weightvalue || 0}`);
    setActiveType(1);
  }, [handledProgress, isOpenWeightTracker, currentDay]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpenWeightTracker}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <ChecklistIcon />
          </div>
          <h5>
            What’s your weight
            <br /> today?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderValueWrapper}>
            {/* <span>{options[activeIndex]}</span> */}
            <div className={styles.inputWrapper}>
              {/* <Input
                placeholder="10"
                type={"number"}
                value={value}
                onChange={onChangeValue}
              /> */}
              <TrackersInput value={value} setValue={setValue} />
            </div>
            <div className={styles.btnContainer}>
              <div
                onClick={() => setActiveType(0)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 0,
                })}
              >
                lbs
              </div>
              <div
                onClick={() => setActiveType(1)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 1,
                })}
              >
                kg
              </div>
            </div>
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          backgroundColor={"#60C055"}
          loading={isLoadingSubmit}
          onClick={onChangeWeight}
        />
      </div>
    </Modal>
  );
};

export default WeightTracker;
