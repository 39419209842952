import React, { FC, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setActiveTrackingBtn } from "../../store/reducers/StatisticSlice";
import styles from "./styles.module.scss";

interface IProps {}

const TrackingBtn: FC<IProps> = ({}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTrackingType, activeTrackingBtn } = useAppSelector(
    (state) => state.statisticsReducer
  );

  if (activeTrackingType === "sleep") {
    return <></>;
  } else if (activeTrackingType === "water") {
    return (
      <div className={styles.trackingTypeBtnContainer}>
        <div
          className={
            activeTrackingBtn === 0
              ? styles.trackingTypeBtnActive
              : styles.trackingTypeBtn
          }
          style={activeTrackingBtn === 0 ? { backgroundColor: "#79DCD5" } : {}}
          onClick={() => dispatch(setActiveTrackingBtn(0))}
        >
          Fl Oz
        </div>
        <div
          className={
            activeTrackingBtn === 1
              ? styles.trackingTypeBtnActive
              : styles.trackingTypeBtn
          }
          style={activeTrackingBtn === 1 ? { backgroundColor: "#79DCD5" } : {}}
          onClick={() => dispatch(setActiveTrackingBtn(1))}
        >
          L
        </div>
      </div>
    );
  } else if (activeTrackingType === "weight") {
    return (
      <div className={styles.trackingTypeBtnContainer}>
        <div
          className={
            activeTrackingBtn === 0
              ? styles.trackingTypeBtnActive
              : styles.trackingTypeBtn
          }
          style={activeTrackingBtn === 0 ? { backgroundColor: "#60C055" } : {}}
          onClick={() => dispatch(setActiveTrackingBtn(0))}
        >
          lbs
        </div>
        <div
          className={
            activeTrackingBtn === 1
              ? styles.trackingTypeBtnActive
              : styles.trackingTypeBtn
          }
          style={activeTrackingBtn === 1 ? { backgroundColor: "#60C055" } : {}}
          onClick={() => dispatch(setActiveTrackingBtn(1))}
        >
          kg
        </div>
      </div>
    );
  } else if (activeTrackingType === "body") {
    return (
      <div className={styles.trackingTypeBtnContainer}>
        <div
          className={
            activeTrackingBtn === 0
              ? styles.trackingTypeBtnActive
              : styles.trackingTypeBtn
          }
          style={activeTrackingBtn === 0 ? { backgroundColor: "#FCD930" } : {}}
          onClick={() => dispatch(setActiveTrackingBtn(0))}
        >
          in
        </div>
        <div
          className={
            activeTrackingBtn === 1
              ? styles.trackingTypeBtnActive
              : styles.trackingTypeBtn
          }
          style={activeTrackingBtn === 1 ? { backgroundColor: "#FCD930" } : {}}
          onClick={() => dispatch(setActiveTrackingBtn(1))}
        >
          cm
        </div>
      </div>
    );
  }
  return <></>;
};

export default TrackingBtn;
