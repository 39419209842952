import React, { FC, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import styles from "./styles.module.scss";
import { CrossIcon } from "../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import DayItem from "../DayItem";
import { useParams } from "react-router-dom";
import { useWindowDimensions } from "../../hooks";
import { setIsOpenDayList } from "../../store/reducers/ModalSlice";
import OnboardingTooltip from "../OnboardingTooltip";
import clsx from "clsx";
import { setBoostcampOnboardingStep } from "../../store/reducers/OnboardingSlice";
import { finishOnboarding } from "../../store/actions/OnboardingAction";
import { BoostOnboardingStepsEnum } from "../../models/ITooltip";
import { IProgress } from "../../models/IProgress";

interface IProps {
  currentProgress?: IProgress | null;
  currentIsAvailableTracker?: boolean;
}

const DaysList: FC<IProps> = ({
  currentProgress,
  currentIsAvailableTracker,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { currentDay, progress, isAvailableTrackers } = useAppSelector(
    (state) => state.processReducer
  );
  const { isOpenDayList } = useAppSelector((state) => state.modalReducer);
  const { user } = useAppSelector((state) => state.userReducer);
  const {
    showBoostcapmOnboarding,
    boostcampOnboardingStep,
    boostcampOnboardingSteps,
    boostcampOnboardingStepIndex,
  } = useAppSelector((state) => state.onboardingReducer);
  const { id } = useParams();
  const { height } = useWindowDimensions();

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );
  const handledIsAvailableTrackers = useMemo(
    () => currentIsAvailableTracker || isAvailableTrackers,
    [isAvailableTrackers, currentIsAvailableTracker]
  );

  const closeHandler = () => {
    dispatch(setIsOpenDayList(false));
  };

  const checkDayStatus = (
    item: any,
    index: number,
    currentDay: number
  ):
    | "opened"
    | "complete"
    | "closed"
    | "opened-completed"
    | "current"
    | "not-completed"
    | "opened-not-completed" => {
    const sameDay = index.toString() === currentDay?.toString();

    // //For test users and beta testers
    if (user?.testMode || user?.betaTest) {
      if (user?.betaTest && index > 3) return "closed";
      if (sameDay && item?.isCompleted) return "opened-completed";
      if (sameDay && !item?.isCompleted) return "opened";
      if (!sameDay && item?.isCompleted) return "complete";
      else return "current";
    }

    //For non-test user
    if (!handledIsAvailableTrackers) return "closed";
    if (sameDay) {
      if (item?.isCompleted) {
        return "opened-completed";
      } else if (item?.isClose && !item?.isCompleted) {
        return "opened-not-completed";
      } else {
        return "opened";
      }
    } else {
      if (item?.isCompleted) {
        return "complete";
      } else if (item?.isClose && !item?.isCompleted) {
        return "not-completed";
      } else if (index === handledProgress?.passedDays) {
        return "current";
      } else {
        return "closed";
      }
    }
    // if (!handledIsAvailableTrackers) return "closed";
    // if (sameDay && (item?.isCompleted || item?.isClose)) {
    //   return "opened-completed";
    // }
    // if (sameDay && !(item?.isCompleted || item?.isClose)) {
    //   return "opened";
    // } else if (!sameDay && (item?.isCompleted || item?.isClose)) {
    //   return "complete";
    // } else if (!sameDay && index === handledProgress?.passedDays) {
    //   return "current";
    // } else if (!sameDay && !(item?.isCompleted || item?.isClose)) {
    //   return "closed";
    // } else {
    //   return "closed";
    // }
  };

  useEffect(() => {
    if (isOpenDayList) {
      document.body.style.position = "fixed";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.position = "relative";
      document.body.style.overflowY = "visible";
    }
  }, [isOpenDayList]);

  const isActiveClosedDay = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.CLOSED_DAY,
    [boostcampOnboardingStep]
  );
  const isActiveOpenDay = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.OPEN_DAY,
    [boostcampOnboardingStep]
  );

  const goToNextOnboardingStep = () => {
    if (showBoostcapmOnboarding && (isActiveClosedDay || isActiveOpenDay))
      dispatch(setBoostcampOnboardingStep(boostcampOnboardingStepIndex + 1));
    if (boostcampOnboardingStepIndex + 1 >= boostcampOnboardingSteps?.length)
      dispatch(finishOnboarding({ boostCamp: true }));
  };

  const skipOnboarding = () => {
    dispatch(finishOnboarding({ boostCamp: true }));
  };

  if (!isOpenDayList) {
    return <></>;
  }

  return (
    <motion.div
      initial={{ top: height }}
      animate={{ top: 0 }}
      transition={{ duration: 0.4 }}
      style={{}}
      className={styles.wrapper}
    >
      {showBoostcapmOnboarding && (isActiveClosedDay || isActiveOpenDay) && (
        <div className={styles.overlay} />
      )}
      <div className={styles.header}>
        <div className={styles.closeBtn} onClick={closeHandler}>
          <CrossIcon />
        </div>
        <h4>Boost Camp Calendar</h4>
      </div>
      <div className={styles.list}>
        {handledProgress?.days?.map((item, index) => {
          if (!item?.picture) {
            return;
          }
          return (
            <div
              className={clsx(
                styles.daysListItem,
                showBoostcapmOnboarding &&
                  ((isActiveClosedDay && index === 0) ||
                    (isActiveOpenDay && index === 1)) &&
                  styles.daysListItemActive
              )}
            >
              <DayItem
                index={index}
                idBoostcamp={id || ""}
                status={
                  showBoostcapmOnboarding && isActiveClosedDay && index === 0
                    ? "complete"
                    : showBoostcapmOnboarding && isActiveOpenDay && index === 1
                    ? "opened"
                    : checkDayStatus(item, index, currentDay)
                }
                title={item?.title}
                description={item?.shortDescription}
                image={item?.picture}
                key={item?.id}
              />
              {showBoostcapmOnboarding &&
                ((isActiveClosedDay && index === 0) ||
                  (isActiveOpenDay && index === 1)) && (
                  <OnboardingTooltip
                    step={boostcampOnboardingStepIndex + 1}
                    maxSteps={boostcampOnboardingSteps.length}
                    title={boostcampOnboardingStep?.title}
                    text={boostcampOnboardingStep?.description}
                    onClickBtn={goToNextOnboardingStep}
                    className={styles.daysListItemTooltip}
                    onClickSkip={skipOnboarding}
                  />
                )}
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default DaysList;
