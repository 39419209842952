import React, { FC, useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import Slider from "rc-slider";

import styles from "./styles.module.scss";

import { WaterIcon } from "../../assets/icons";

import { ITrackers } from "../../models/ITrackers";
import { IProgress } from "../../models/IProgress";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";

import Button from "../Button";
import TeckerMobile from "../TeckerMobile";

import { convertLitrToOz, convertOztoLitr } from "../../utils";
import { updateProgressDay } from "../../utils/progress";

import { setTrackers } from "../../services/progress.service";
import { setProgressDay } from "../../store/reducers/ProgressSlice";
import { TRACKERS } from "../../constants";

interface IProps {
  value: ITrackers | null;
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
  currentIsAvailableTracker?: boolean;
}

const WaterTrackerMobile: FC<IProps> = ({
  value,
  currentProgress,
  setCurrentProgress,
  currentIsAvailableTracker,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { progress, currentDay } = useAppSelector((state) => state.processReducer);

  const [options, setOptions] = useState(TRACKERS.lOptions);
  const [isOpenInfo, setIsOpenInfo] = useState<boolean>(false);
  const [isOpenTracker, setIsOpenTracker] = useState<boolean>(false);
  const [formattedValue, setFormattedValue] = useState<string>("");

  const [activeType, setActiveType] = useState<number>(0);
  const [count, setCount] = useState<string>("0");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(0);

  useEffect(() => {
    if (activeType === 0) {
      setOptions(TRACKERS.lOptions);
    }
    if (activeType === 1) {
      setOptions(TRACKERS.ozOptions);
    }
  }, [activeType]);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const isClosed =
    handledProgress?.days[currentDay]?.isClose ||
    handledProgress?.days[currentDay]?.isCompleted;

  const formatValue = (value: ITrackers | null) => {
    if (value === null) {
      return;
    }
    setFormattedValue(
      value?.water?.l === 4 ? `>3 l` : value?.water?.l === 0 ? "" : `${value?.water?.l} l`
    );
  };

  const calculateRecommendedWater = () => {
    const weightKg = handledProgress?.days[currentDay]?.trackers?.weight?.kg;
    const weightIbs = handledProgress?.days[currentDay]?.trackers?.weight?.ibs;

    const recommendedL = weightKg ? Number(weightKg) * 0.035 : 0;
    const recommendedOz = weightKg ? Number(weightIbs) * 0.6 : 0;
    if (activeType === 0) {
      return recommendedL.toFixed(1);
    } else {
      return recommendedOz.toFixed(1);
    }
  };

  const onClick = () => {
    setIsOpenTracker(true);
  };

  const onChangeWater = async () => {
    try {
      setIsLoadingSubmit(true);
      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          water: {
            l:
              activeType === 0
                ? +Number(currentValue).toFixed(1)
                : convertOztoLitr(currentValue),
            flOz:
              activeType === 1
                ? +Number(currentValue).toFixed(1)
                : convertLitrToOz(currentValue),
          },
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          setIsOpenTracker(false);
        }
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      setIsLoadingSubmit(false);
      console.log(error);
    }
  };

  useEffect(() => {
    formatValue(value);
  }, [value]);

  useEffect(() => {
    const waterValue = handledProgress?.days[currentDay]?.trackers?.water?.l;
    setCurrentValue(waterValue || 0);
    setActiveType(0);
    const index = TRACKERS.lOptions.findIndex((item) => item.value === waterValue);
    setActiveIndex(index > 0 ? index : 0);
  }, [handledProgress, currentDay, isOpenTracker]);

  const color = "#79DCD533";
  const btnColor = "#79DCD5";

  return (
    <div className={styles.wrapper}>
      <TeckerMobile
        title="Water intake"
        isClosed={isClosed}
        formattedValue={formattedValue}
        currentIsAvailableTracker={currentIsAvailableTracker}
        color={color}
        btnColor={btnColor}
        isOpenTracker={isOpenTracker}
        onClick={onClick}
        isOpenInfo={isOpenInfo}
        setIsOpenInfo={setIsOpenInfo}
        infoTitle="How much water have you had today?"
        icon={<WaterIcon />}
        children={
          <div className={styles.btnContainer}>
            <div
              onClick={() => setActiveType(0)}
              className={clsx(styles.typeBtn, {
                [styles.typeBtnActive]: activeType === 0,
              })}
            >
              L
            </div>
            <div
              onClick={() => setActiveType(1)}
              className={clsx(styles.typeBtn, {
                [styles.typeBtnActive]: activeType === 1,
              })}
            >
              Fl Oz
            </div>
          </div>
        }
      />
      {isOpenTracker && (
        <div className={styles.contentBox}>
          <div className={styles.sliderWrapper}>
            <div className={styles.sliderValueWrapper}>
              <span>{options[activeIndex]?.label}</span>
            </div>
            <p className={styles.recommended}>
              Recommended amount of water:{" "}
              <b>
                {calculateRecommendedWater() !== `0.0`
                  ? `${calculateRecommendedWater()} ${activeType === 0 ? "L" : "Fl Oz"} `
                  : "—"}
              </b>
            </p>
            <Slider
              min={0}
              max={7}
              defaultValue={activeIndex}
              onChange={(value: any) => {
                setActiveIndex(value);
                setCurrentValue(options[value]?.value);
              }}
              trackStyle={{ height: "15px", backgroundColor: "#79DCD5" }}
              railStyle={{ height: "15px", backgroundColor: "#E4E4E4" }}
              handleStyle={{
                height: "26px",
                width: "26px",
                backgroundColor: "#fff",
                borderColor: "#79DCD5",
                borderWidth: "8px",
                opacity: 1,
              }}
            />
            <div className={styles.sliderOptionsWrapper}>
              {options?.map((item, index) => {
                return (
                  <span className={styles.sliderOption} key={index}>
                    {item?.label}
                  </span>
                );
              })}
            </div>
          </div>

          <Button
            title={"SUBMIT"}
            styleType={"filled"}
            size={"small"}
            backgroundColor={"#79DCD5"}
            loading={isLoadingSubmit}
            onClick={onChangeWater}
          />
        </div>
      )}
    </div>
  );
};
export default WaterTrackerMobile;
