import React, { FC, ReactNode, useEffect } from "react";
import styles from "./styles.module.scss";
import TrackingItem from "../TrackingItem";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  setActiveTrackingType,
  setTrackingOption,
} from "../../store/reducers/StatisticSlice";
import { SleepIcon, WaterIcon, FastingIcon, CalendarIcon } from "../../assets/icons";

import Fasting from "../../assets/images/fasting.png";

interface IProps {}

const StatisticWidget: FC<IProps> = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { statistics, trackingOption, activeTrackingType, activeTrackingBtn } =
    useAppSelector((state) => state.statisticsReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    if (user?.testMode && trackingOption !== "month")
      dispatch(setTrackingOption("month"));
  }, [user, trackingOption]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h4 className={styles.title}>Tracking</h4>
        <div className={styles.navigation}>
          {!user?.testMode && (
            <>
              <div
                onClick={() => dispatch(setTrackingOption("day"))}
                className={clsx(styles.navBtn, {
                  [styles.activeNavBtn]: trackingOption === "day",
                })}
              >
                D
              </div>
              <div
                onClick={() => dispatch(setTrackingOption("week"))}
                className={clsx(styles.navBtn, {
                  [styles.activeNavBtn]: trackingOption === "week",
                })}
              >
                W
              </div>
            </>
          )}
          <div
            onClick={() => dispatch(setTrackingOption("month"))}
            className={clsx(styles.navBtn, {
              [styles.activeNavBtn]: trackingOption === "month",
            })}
          >
            M
          </div>
        </div>
      </div>
      <div className={styles.tackingList}>
        <TrackingItem
          icon={<SleepIcon />}
          title={"Average sleep time"}
          value={statistics?.averageStats?.sleep || 0}
          valueType={"h"}
          isActive={activeTrackingType === "sleep"}
          iconColor={"#8C42E3"}
          borderColor={"#8C42E336"}
          onClick={() => dispatch(setActiveTrackingType("sleep"))}
        />
        <TrackingItem
          icon={<WaterIcon />}
          title={"Water consumption"}
          value={
            (activeTrackingBtn === 0
              ? statistics?.averageStats?.water?.flOz?.toFixed(1)
              : statistics?.averageStats?.water?.l?.toFixed(1)) || 0
          }
          valueType={activeTrackingBtn === 0 ? "Fl Oz" : "l"}
          isActive={activeTrackingType === "water"}
          iconColor={"#79DCD5"}
          borderColor={"#79DCD536"}
          onClick={() => dispatch(setActiveTrackingType("water"))}
        />
        <TrackingItem
          icon={<img src={Fasting} style={{ width: "26px", height: "auto" }} />}
          title={"Fasting"}
          value={statistics?.averageStats?.eat || 0}
          valueType={"h"}
          isActive={activeTrackingType === "eat"}
          iconColor={"#F05122"}
          borderColor={"#F0512236"}
          onClick={() => dispatch(setActiveTrackingType("eat"))}
        />
        <TrackingItem
          icon={<CalendarIcon style={{ color: "#60C055" }} />}
          title={"Weight loss since start"}
          value={
            (activeTrackingBtn === 0
              ? statistics?.averageStats?.ibs?.toFixed(1)
              : statistics?.averageStats?.kg?.toFixed(1)) || 0
          }
          valueType={activeTrackingBtn === 0 ? "lbs" : "kg"}
          isActive={activeTrackingType === "weight"}
          iconColor={"#60C055"}
          borderColor={"#60C05536"}
          onClick={() => dispatch(setActiveTrackingType("weight"))}
        />
      </div>
    </div>
  );
};

export default StatisticWidget;
