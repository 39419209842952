import React, { FC, useState, useEffect, useMemo } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

import { ChecklistIcon } from "../../assets/icons";

import { ITrackers } from "../../models/ITrackers";
import { IProgress } from "../../models/IProgress";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";

import Button from "../Button";
import TrackersInput from "../TrackersInput";
import TeckerMobile from "../TeckerMobile";

import { convertIbsToKg, convertKgToIbs } from "../../utils";
import { updateProgressDay } from "../../utils/progress";

import { setTrackers } from "../../services/progress.service";
import { setProgressDay } from "../../store/reducers/ProgressSlice";

interface IProps {
  value: ITrackers | null;
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
  currentIsAvailableTracker?: boolean;
}

const WeightTrackerMobile: FC<IProps> = ({
  value,
  currentProgress,
  setCurrentProgress,
  currentIsAvailableTracker,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { progress, currentDay } = useAppSelector((state) => state.processReducer);

  const [isOpenInfo, setIsOpenInfo] = useState<boolean>(false);
  const [isOpenTracker, setIsOpenTracker] = useState<boolean>(false);
  const [formattedValue, setFormattedValue] = useState<string>("");

  const [activeType, setActiveType] = useState<number>(0);
  const [count, setCount] = useState<string>("0");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const isClosed =
    handledProgress?.days[currentDay]?.isClose ||
    handledProgress?.days[currentDay]?.isCompleted;

  const formatValue = (value: ITrackers | null) => {
    if (value === null) {
      return;
    }
    setFormattedValue(value?.weight?.kg > 0 ? `${value?.weight.kg} kg` : "");
  };

  const onClick = () => {
    setIsOpenTracker(true);
  };

  const onChangeWeight = async () => {
    try {
      setIsLoadingSubmit(true);

      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          weight: {
            kg: activeType === 1 ? +Number(count).toFixed(1) : convertIbsToKg(+count),
            ibs: activeType === 0 ? +Number(count).toFixed(1) : convertKgToIbs(+count),
          },
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          setIsOpenTracker(false);
        }
      }

      setIsLoadingSubmit(false);
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    formatValue(value);
  }, [value]);

  useEffect(() => {
    if (!isOpenTracker) return;
    const weightvalue = handledProgress?.days[currentDay]?.trackers?.weight?.kg;
    setCount(`${weightvalue || 0}`);
    setActiveType(1);
  }, [handledProgress, currentDay, isOpenTracker]);

  const color = "#60C05533";
  const btnColor = "#60C055";

  return (
    <div className={styles.wrapper}>
      <TeckerMobile
        title="Current weight"
        isClosed={isClosed}
        formattedValue={formattedValue}
        currentIsAvailableTracker={currentIsAvailableTracker}
        color={color}
        btnColor={btnColor}
        isOpenTracker={isOpenTracker}
        onClick={onClick}
        isOpenInfo={isOpenInfo}
        setIsOpenInfo={setIsOpenInfo}
        infoTitle="How much do you weigh?"
        icon={<ChecklistIcon />}
        children={
          <div className={styles.btnContainer}>
            <div
              onClick={() => setActiveType(0)}
              className={clsx(styles.typeBtn, {
                [styles.typeBtnActive]: activeType === 0,
              })}
            >
              lbs
            </div>
            <div
              onClick={() => setActiveType(1)}
              className={clsx(styles.typeBtn, {
                [styles.typeBtnActive]: activeType === 1,
              })}
            >
              kg
            </div>
          </div>
        }
      />
      {isOpenTracker && (
        <div className={styles.contentBox}>
          <div className={styles.sliderWrapper}>
            <div className={styles.sliderValueWrapper}>
              {/* <span>{options[activeIndex]}</span> */}
              <div className={styles.inputWrapper}>
                {/* <Input
                placeholder="10"
                type={"number"}
                value={value}
                onChange={onChangeValue}
              /> */}
                <TrackersInput value={count} setValue={setCount} />
              </div>
            </div>
          </div>

          <Button
            title={"SUBMIT"}
            styleType={"filled"}
            size={"small"}
            backgroundColor={"#60C055"}
            loading={isLoadingSubmit}
            onClick={onChangeWeight}
          />
        </div>
      )}
    </div>
  );
};
export default WeightTrackerMobile;
