import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Modal from "react-modal";
import format from "date-fns/format";
import clsx from "clsx";
import { ClipLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";

import styles from "./styles.module.scss";
import "./styles.scss";
import { IngredientIcon, CrossIcon, CalendarIcon, PrintIcon } from "../../assets/icons";
import { getRecipe } from "../../services/boostcamps.service";
import Button from "../Button";
import { useAppSelector } from "../../hooks/redux";

const RecipePreview = ({
  isOpen,
  setIsOpen,
  preview = null,
  title = null,
  ingredients = [],
  description = null,
  tags = [],
  cookTimeHours,
  cookTimeMinutes,
  isClosed = true,
  date,
  id = null,
}) => {
  const { progress } = useAppSelector((state) => state.processReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      console.log("onBeforePrint");
      // setIsPrintLoading(false)
    },
    onAfterPrint: () => {
      console.log("onAfterPrint");
      setIsPrintLoading(false);
    },
    documentTitle: title || "Recipe Title",
  });

  const onPrint = () => {
    try {
      setIsPrintLoading(true);
      handlePrint();
    } catch (e) {
      console.error(e);
      setIsPrintLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  const renderName = (item) => {
    if (!!item?.hyperLink) {
      return (
        <span>
          <a
            className={clsx(styles.ingredientItemLink, "print_ingredientItemLink")}
            href={item?.hyperLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item?.ingredient?.name}
          </a>
        </span>
      );
    } else {
      return <span>{item?.ingredient?.name}</span>;
    }
  };

  function addVideoLinks(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const iframes = doc.querySelectorAll("iframe");
    iframes.forEach((iframe) => {
      const url = iframe.getAttribute("src");
      if (url) {
        const videoLink = doc.createElement("a");
        videoLink.textContent = "Video Link";
        videoLink.href = url;
        videoLink.className = "print_videoLink";
        iframe.insertAdjacentElement("afterend", videoLink);
      }
    });

    return doc.documentElement.outerHTML;
  }

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        className={styles.modal}
        onRequestClose={() => setIsOpen(false)}
        overlayClassName={styles.overlay}
        shouldFocusAfterRender={false}
      >
        <div className={styles.modalInner}>
          {isLoading ? (
            <div className={styles.loader}>
              <ClipLoader color={"#8C42E3"} size={30} />
            </div>
          ) : (
            <>
              <div className={styles.header}>
                <img src={preview} className={styles.headerImg} />

                {isClosed && (
                  <div onClick={() => setIsOpen(false)} className={styles.closeBtn}>
                    <CrossIcon width={30} height={30} style={{ color: "#fff" }} />
                  </div>
                )}
                {progress?.printRecipe && (
                  <div className={styles.headerBtn}>
                    <Button
                      onClick={onPrint}
                      size={"x-small"}
                      styleType={"filled"}
                      loading={isPrintLoading}
                      title={""}
                      leftIcon={<PrintIcon width={20} height={20} />}
                      style={{ background: "#60C055", borderRadius: 24 }}
                    />
                  </div>
                )}
                <div className={styles.headerInfo}>
                  <p className={styles.title}>{title}</p>
                  <div className={styles.footer}>
                    <div className={styles.footerItem}>
                      <IngredientIcon style={{ color: "#fff" }} />
                      <span>{ingredients?.length} ingredients</span>
                    </div>
                    {date && (
                      <div className={styles.footerItem}>
                        <CalendarIcon style={{ color: "#fff" }} width={20} height={20} />
                        <span>
                          {date && format(new Date(date), "yyyy-MM-dd")}
                          {/* {cookTimeHours ? `${cookTimeHours}` : ""}{" "}
                  {cookTimeMinutes ? `${cookTimeMinutes}` : ""} */}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={clsx(styles.contentContainer, "print_contentContainer")}
                ref={componentRef}
              >
                <h2 className={"print_headerTitle"}>{title}</h2>
                <img src={preview} className={"print_headerImg"} />
                <p className={clsx(styles.sectionTitle, "print_sectionTitle")}>
                  Ingredients
                </p>
                {ingredients?.map((item, index) => {
                  return (
                    <div
                      key={`ingredients-${index}`}
                      className={clsx(styles.ingredientItem, "print_ingredientItem")}
                    >
                      <div className={clsx(styles.amountWrapper, "print_amountWrapper")}>
                        {item?.units?.map((el, i) => {
                          return (
                            <p key={`units-${i}`}>
                              {el?.amount} {el?.unit?.text}
                              {item?.units?.length - 1 !== i ? <span>/</span> : ""}
                            </p>
                          );
                        })}
                      </div>
                      {renderName(item)}
                    </div>
                  );
                })}
                <p className={clsx(styles.sectionTitle, "print_sectionTitle")}>
                  Preparation
                </p>
                <div className={"html-wrapper"}>
                  <div
                    className={clsx(styles.shortOverview, "print_shortOverview")}
                    dangerouslySetInnerHTML={{
                      __html: addVideoLinks(description || ""),
                    }}
                  />
                </div>
                <div className={clsx(styles.tagsWrapper, "print_tagsWrapper")}>
                  {tags?.map((item, index) => (
                    <div className={clsx(styles.tag, "print_tag")} key={`tags-${index}`}>
                      {item?.text}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
export default RecipePreview;
