import { POST, GET, PUT } from "../API/axios";
import { AxiosError } from "axios";

interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}

export const getTooltips = (type: "GENERAL" | "BOOST"): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`tooltips/?type=${type}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
