const GOOGLE_API_KEY = "AIzaSyDTQ5UmY9KUzi5R_qiPm4FEJTn5wS6tbh0";

export async function getLocation(query: string) {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${query}&language=en&key=${GOOGLE_API_KEY}`
  );

  if (res.status === 200) {
    const response = await res.json();
    // console.log("getLocation response", response);
    return response;
  } else {
    return [];
  }
}
