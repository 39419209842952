import React, { FC } from "react";
import { CheckBigIcon, CloseDayIcon } from "../../assets/icons";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface IProps {
  remainders: Array<string> | undefined;
  tutorial?: boolean;
  handledIsAvailableTrackers?: boolean;
}

const DailyReminders: FC<IProps> = ({
  remainders,
  tutorial = false,
  handledIsAvailableTrackers,
}): JSX.Element => {
  const renderReminders = (index: number, item: string): JSX.Element => {
    return (
      <div key={item} className={styles.reminderWrapper}>
        <CheckBigIcon width={24} height={24} style={{ minWidth: "24px" }} />
        <p className={styles.reminder}>{item}</p>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      {!handledIsAvailableTrackers && !tutorial && (
        <CloseDayIcon className={styles.blockIcon} />
      )}
      <div
        className={clsx({
          [styles.blurWrapper]: !handledIsAvailableTrackers && !tutorial,
        })}
      >
        <h4 className={styles.title}>Daily reminders</h4>
        <div className={styles.remindersList}>
          {remainders?.map((item, index) => renderReminders(index, item))}
        </div>
        <h5>
          Love, <br />
          <span>Bridge XX</span>
        </h5>
      </div>
    </div>
  );
};

export default DailyReminders;
