import { ZOHO_BEFORE_PAY_URL, ZOHO_AFTER_PAY_URL } from "../config";

const ZOHO_TYPES = {
  beforePay: "zoho-before-pay",
  afterPay: "zoho-after-pay",
};

export const loadScriptZoho = (hasPlan = false) => {
  const existingScript = document.getElementById("zoHo");

  if (!existingScript) {
    createScriptZoho(hasPlan);
  } else {
    const zohoType = existingScript?.getAttribute("data-zoho-type");
    if (
      (hasPlan && zohoType === ZOHO_TYPES.beforePay) ||
      (!hasPlan && zohoType === ZOHO_TYPES.afterPay)
    ) {
      deleteScriptZoho();
      createScriptZoho(hasPlan);
    }
  }
};

export const createScriptZoho = (hasPlan = false) => {
  const script = document.createElement("script");
  script.src = hasPlan ? ZOHO_AFTER_PAY_URL : ZOHO_BEFORE_PAY_URL;
  script.id = "zoHo";
  script.setAttribute(
    "data-zoho-type",
    hasPlan ? ZOHO_TYPES.afterPay : ZOHO_TYPES.beforePay
  );
  document.body.appendChild(script);
  script.onload = () => {};
};

export const deleteScriptZoho = () => {
  const existingScript = document.getElementById("zoHo");
  if (existingScript) {
    existingScript.remove();
  }
  const existingWidget = document.getElementById("zohohc-asap-web-helper-main");
  if (existingWidget) {
    existingWidget.remove();
  }
};
