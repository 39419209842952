import { AppDispatch } from "../store";
import { getBoughtBoostcamps, getMyBoostcamps } from "../../services/boostcamps.service";
import { allBoostcampsSlice } from "../reducers/AllBoostcampsSlice";

export const fetchMyBoostcamps = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(allBoostcampsSlice.actions.allBoostcampsFetching());
    const { error, result } = await getMyBoostcamps(1, 30);
    if (error) {
      dispatch(
        allBoostcampsSlice.actions.allBoostcampsFetchingError(
          error?.message || "Something went wrong"
        )
      );
    }
    if (!error && result?.status === 200) {
      dispatch(
        allBoostcampsSlice.actions.allBoostcampsFetchingSuccess(result?.data?.data?.rows)
      );
    }
  } catch (e) {
    dispatch(
      allBoostcampsSlice.actions.allBoostcampsFetchingError("Something went wrong")
    );
  }
};

export const fetchBuyedBoostcamps = () => async (dispatch: AppDispatch) => {
  try {
    console.log("fetchBuyedBoostcamps action");
    const { error, result } = await getBoughtBoostcamps();
    if (!error && result?.data) {
      dispatch(allBoostcampsSlice.actions.buyedBoostcampsFetching(result?.data?.data));
    }
  } catch (e) {
    console.log(e);
  }
};
