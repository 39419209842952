import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBoostcamp } from "../../models/IBoostcamp";

interface IPaymentsState {
  actualBoostcamp: IBoostcamp | null;
  isLoading: boolean;
  error: string;
  selectedMonday: string | null;
  selectedBoostcamp: IBoostcamp | null;
  selectedPrice: number;
  isUseMysteryBox: boolean;
}

const initialState: IPaymentsState = {
  actualBoostcamp: null,
  isLoading: false,
  error: "",
  selectedMonday: null,
  selectedBoostcamp: null,
  selectedPrice: 0,
  isUseMysteryBox: false,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    actualBoostcampFetching: (state) => {
      state.isLoading = true;
    },
    actualBoostcampFetchingSuccess: (state, action: PayloadAction<IBoostcamp | null>) => {
      state.isLoading = false;
      state.error = "";
      state.actualBoostcamp = action.payload;
    },
    actualBoostcampFetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    actualBoostcampClear: (state) => {
      state.actualBoostcamp = null;
      state.error = "";
      state.isLoading = false;
    },
    setSelectedMonday: (state, action: PayloadAction<string>) => {
      state.selectedMonday = action.payload;
    },
    setSelectedBoostcamp: (state, action: PayloadAction<IBoostcamp>) => {
      state.selectedBoostcamp = action.payload;
    },
    setSelectedPrice: (state, action: PayloadAction<number>) => {
      state.selectedPrice = action.payload;
    },
    clearSelectedPrice: (state) => {
      state.selectedPrice = 0;
    },
    clearSelectedBoostcamp: (state) => {
      state.selectedBoostcamp = null;
    },
    clearSelectedMonday: (state) => {
      state.selectedMonday = null;
    },
    setIsUseMysteryBox: (state, action: PayloadAction<boolean>) => {
      state.isUseMysteryBox = action.payload;
    },
  },
});

export const {
  actualBoostcampFetching,
  actualBoostcampFetchingSuccess,
  actualBoostcampFetchingError,
  actualBoostcampClear,
  setSelectedMonday,
  clearSelectedMonday,
  setSelectedBoostcamp,
  clearSelectedBoostcamp,
  setSelectedPrice,
  clearSelectedPrice,
  setIsUseMysteryBox,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
