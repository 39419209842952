import React, { FC, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setIsOpenTrackerList } from "../../store/reducers/ModalSlice";
import styles from "./styles.module.scss";
import {
  FastingTrackerMobile,
  OnboardingTooltip,
  SleepTrackerMobile,
  WaterTrackerMobile,
  WeightTrackerMobile,
} from "../index";
import { motion } from "framer-motion";
import { isIOS } from "../../utils";
import { setGeneralOnboardingStep } from "../../store/reducers/OnboardingSlice";
import { finishOnboarding } from "../../store/actions/OnboardingAction";
import clsx from "clsx";
import { GeneralOnboardingStepsEnum } from "../../models/ITooltip";
import { IProgress } from "../../models/IProgress";

interface IProps {
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
  currentIsAvailableTracker?: boolean;
}

const TrackerListMenu: FC<IProps> = ({
  currentProgress,
  setCurrentProgress,
  currentIsAvailableTracker,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isOpenTrackerList } = useAppSelector((state) => state.modalReducer);
  const { currentDay, progress } = useAppSelector((state) => state.processReducer);
  const {
    showGeneralOnboarding,
    generalOnboardingStep,
    generalOnboardingSteps,
    generalOnboardingStepIndex,
  } = useAppSelector((state) => state.onboardingReducer);
  const { buyedBoostcamps } = useAppSelector((state) => state.allBoostcampsReducer);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const isIos: boolean = isIOS();

  const onCloseTrackerList = () => {
    dispatch(setIsOpenTrackerList(false));
  };

  useEffect(() => {
    if (isOpenTrackerList) {
      document.body.style.position = "fixed";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.position = "relative";
      document.body.style.overflowY = "visible";
    }
  }, [isOpenTrackerList]);

  const isActiveTasks = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.TASKS,
    [generalOnboardingStep]
  );

  const hasBuyedBoostacamp = useMemo(
    () => !!buyedBoostcamps && buyedBoostcamps?.length > 0,
    [buyedBoostcamps]
  );
  const showGeneralOnbAndHasBuyed = useMemo(
    () => showGeneralOnboarding && hasBuyedBoostacamp,
    [showGeneralOnboarding, hasBuyedBoostacamp]
  );

  const goToNextOnboardingStep = () => {
    if (showGeneralOnbAndHasBuyed && isActiveTasks)
      dispatch(setGeneralOnboardingStep(generalOnboardingStepIndex + 1));
    if (generalOnboardingStepIndex + 1 >= generalOnboardingSteps?.length)
      dispatch(finishOnboarding({ general: true }));
  };

  const skipOnboarding = () => {
    dispatch(finishOnboarding({ general: true }));
  };

  if (!isOpenTrackerList) {
    return <></>;
  }

  return (
    <div
      // style={{ height: isIos ? "-webkit-fill-available" : "100vh" }}
      className={styles.wrapper}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        onClick={onCloseTrackerList}
        // style={{ height: isIos ? "-webkit-fill-available" : "100vh" }}
        className={styles.overlay}
      />
      <motion.div
        initial={{ bottom: -500 }}
        animate={{ bottom: isIos ? 0 : 0 }}
        transition={{ duration: 0.4 }}
        onClick={() => true}
        className={clsx(styles.contentContainer, {
          [styles.contentContainerDisable]: showGeneralOnbAndHasBuyed && isActiveTasks,
        })}
        // style={{ paddingBottom: isIos ? "0px" : "60px" }}
      >
        {showGeneralOnbAndHasBuyed && isActiveTasks && (
          <OnboardingTooltip
            step={generalOnboardingStepIndex + 1}
            maxSteps={generalOnboardingSteps.length}
            title={generalOnboardingStep?.title}
            text={generalOnboardingStep?.description}
            onClickBtn={goToNextOnboardingStep}
            className={styles.tooltip}
            onClickSkip={skipOnboarding}
          />
        )}
        <WeightTrackerMobile
          value={handledProgress?.days[currentDay]?.trackers || null}
          currentProgress={currentProgress}
          setCurrentProgress={setCurrentProgress}
          currentIsAvailableTracker={currentIsAvailableTracker}
        />
        {/* <DailyTaskSetItem
          icon={<ChecklistIcon />}
          title="What’s your weight today?"
          color={"#60C05533"}
          btnColor={"#60C055"}
          onClick={() => dispatch(setIsOpenWeightTracker(true))}
          value={handledProgress?.days[currentDay]?.trackers || null}
          type={"weight"}
          infoPosition={"bottom"}
        /> */}
        <FastingTrackerMobile
          value={handledProgress?.days[currentDay]?.trackers || null}
          currentProgress={currentProgress}
          setCurrentProgress={setCurrentProgress}
          currentIsAvailableTracker={currentIsAvailableTracker}
        />
        <WaterTrackerMobile
          value={handledProgress?.days[currentDay]?.trackers || null}
          currentProgress={currentProgress}
          setCurrentProgress={setCurrentProgress}
          currentIsAvailableTracker={currentIsAvailableTracker}
        />
        <SleepTrackerMobile
          value={handledProgress?.days[currentDay]?.trackers || null}
          currentProgress={currentProgress}
          setCurrentProgress={setCurrentProgress}
          currentIsAvailableTracker={currentIsAvailableTracker}
        />
      </motion.div>
    </div>
  );
};

export default TrackerListMenu;
