import { AppDispatch } from "../store";
import { onboardingSlice } from "../reducers/OnboardingSlice";
import { getOnboarding, updateOnboarding } from "../../services/onboarding.service";
import { IOnboardingUpdate } from "../../models/IOnboarding";
import { getTooltips } from "../../services/tooltips.service";

export const fetchOnboarding = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(onboardingSlice.actions.onboardingFetching());
    const { error, result } = await getOnboarding();
    if (error) {
      dispatch(
        onboardingSlice.actions.onboardingFetchingError(
          error.message || "Something went wrong"
        )
      );
    }
    if (!error && result?.status === 200) {
      dispatch(
        onboardingSlice.actions.onboardingFetchingSuccess(result.data.data || null)
      );
    }
  } catch (e) {
    dispatch(onboardingSlice.actions.onboardingFetchingError("Something went wrong"));
  }
};

export const fetchOnboardingSteps =
  (type: "GENERAL" | "BOOST") => async (dispatch: AppDispatch) => {
    try {
      dispatch(onboardingSlice.actions.onboardingStepsFetching());
      const { error, result } = await getTooltips(type);
      if (error) {
        dispatch(
          onboardingSlice.actions.onboardingStepsFetchingError(
            error.message || "Something went wrong"
          )
        );
      }
      if (!error && result?.status === 200) {
        dispatch(
          onboardingSlice.actions.onboardingStepsFetchingSuccess({
            steps: result.data.data || [],
            type: type,
          })
        );
      }
    } catch (e) {
      dispatch(
        onboardingSlice.actions.onboardingStepsFetchingError("Something went wrong")
      );
    }
  };

export const finishOnboarding =
  ({ general, boostCamp }: IOnboardingUpdate) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(onboardingSlice.actions.onboardingUpdating({ general, boostCamp }));
      const { error, result } = await updateOnboarding({ general, boostCamp });
      console.log("finishOnboarding result", result);
      if (error) {
        dispatch(
          onboardingSlice.actions.onboardingUpdatingError(
            error.message || "Something went wrong"
          )
        );
      }
      if (!error && (result?.status === 200 || result?.status === 202)) {
        dispatch(
          onboardingSlice.actions.onboardingUpdatingSuccess(result.data.data || null)
        );
      }
    } catch (e) {
      dispatch(onboardingSlice.actions.onboardingUpdatingError("Something went wrong"));
    }
  };
