import { GET, POST, PUT } from "../API/axios";
import { AxiosError } from "axios";
import { ITrackers } from "../models/ITrackers";

interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}

export const getMyAllProgress = (): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`progress/my`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getMyActiveProgress = (): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET("progress/my/active")
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const completeDay = (dayId: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      isCompleted: true,
    };
    PUT(`progress/my/active/${dayId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
export const completeBoostcamp = (dayId: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      isCompleteBoostCamp: true,
    };
    PUT(`progress/my/active/${dayId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const setTask = (dayId: string, taskId: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      taskId,
    };
    PUT(`progress/my/active/${dayId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const setTrackers = (dayId: string, value: ITrackers): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      trackers: value,
    };
    PUT(`progress/my/active/${dayId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const setSleep = (dayId: string, value: number): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      trackers: {
        sleep: value,
      },
    };
    PUT(`progress/my/active/${dayId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const setWater = (
  dayId: string,
  value: number,
  type: "l" | "flOz"
): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      trackers: {
        water: { type, count: value },
      },
    };
    PUT(`progress/my/active/${dayId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
