import { AppDispatch } from "../store";
import { getBoostcamp } from "../../services/boostcamps.service";
import { boostcampSlice } from "../reducers/BoostcampSlice";

export const fetchBoostcamp = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(boostcampSlice.actions.boostcampFetching());
    const { error, result } = await getBoostcamp(id);
    if (error) {
      dispatch(
        boostcampSlice.actions.boostcampFetchingError(
          error?.message || "Somethin went wrong"
        )
      );
    }
    if (!error && result?.status === 200) {
      dispatch(boostcampSlice.actions.boostcampFetchingSuccess(result?.data?.data));
    }
  } catch (e) {
    dispatch(boostcampSlice.actions.boostcampFetchingError("Something went wrong"));
  }
};
