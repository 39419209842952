import React, { FC } from "react";
import Modal from "react-modal";
import { useWindowDimensions } from "../../hooks";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Button } from "../index";
import { fetchProgress } from "../../store/actions/ProgressActions";
import { DiamondIcon } from "../../assets/icons";

interface IProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

const CompleteDayWhithoutSoundModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
}): JSX.Element => {
  const { width } = useWindowDimensions();

  const dispatch = useAppDispatch();

  const { progress, currentDay, maxPoints, currentPoints } = useAppSelector(
    (state) => state.processReducer
  );

  const onClose = () => {
    dispatch(fetchProgress());
    setIsOpen(false);
  };

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.6)",
          zIndex: 9999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          outline: "none",
          overflow: "hidden",
          padding: "0px",
          backgroundColor: "transparent",
          border: "none",
          width: width <= 768 ? "95%" : "auto",
        },
      }}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className={styles.wrapper}>
        <h4>Day was successfully completed</h4>
        <DiamondIcon />
        <h3>+{currentPoints} points</h3>
        <Button
          title={"Got it"}
          styleType={"filled"}
          size={width <= 768 ? "small" : "medium"}
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default CompleteDayWhithoutSoundModal;
