import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../models/IUser";
interface IUserState {
  user: IUser | null;
  isLoading: boolean;
  error: string;
  token: string;
  currency: "USD" | "AUD";
}

const initialState: IUserState = {
  user: null,
  isLoading: false,
  error: "",
  token: "",
  currency: "AUD",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userFetching: (state) => {
      state.isLoading = true;
    },
    userFetchingSuccess: (state, action: PayloadAction<IUser>) => {
      state.isLoading = false;
      state.error = "";
      state.user = action.payload;
    },
    userFetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    userClear: (state) => {
      state.user = null;
      state.token = "";
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setCurrency: (state, action: PayloadAction<"USD" | "AUD">) => {
      state.currency = action.payload;
    },
  },
});

export const {
  userFetching,
  userFetchingSuccess,
  userFetchingError,
  userClear,
  setUser,
  setToken,
  setCurrency,
} = userSlice.actions;

export default userSlice.reducer;
