import React, { ChangeEvent, FC } from "react";
import { ArrowUpSmallIcon } from "../../assets/icons";
import styles from "./styles.module.scss";

interface IProps {
  label?: string;
  value: string;
  setValue: (val: string) => void;
}

const TrackersInput: FC<IProps> = ({ label, value, setValue }): JSX.Element => {
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const increaseValue = () => {
    // @ts-ignore
    setValue((prev) => +prev + 1);
  };

  const descreaseValue = () => {
    // @ts-ignore
    if (value > 0) {
      // @ts-ignore
      setValue((prev) => prev - 1);
    }
  };

  return (
    <div>
      {label && <p className={styles.label}>{label}</p>}
      <div className={styles.wrapper}>
        <input
          value={value}
          onChange={onChangeValue}
          type={"string"}
          className={styles.input}
          maxLength={4}
        />

        <div className={styles.arrowBtnWrapper}>
          <div onClick={increaseValue} className={styles.increaseBtn}>
            <ArrowUpSmallIcon />
          </div>
          <div onClick={descreaseValue} className={styles.decreaseBtn}>
            <ArrowUpSmallIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackersInput;
