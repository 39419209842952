import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { RecipePreview } from "../../components";
import { withMainLayout } from "../../layouts";
import { getRecipe } from "../../services/boostcamps.service";
import styles from "./styles.module.scss";
import PulseLoader from "react-spinners/PulseLoader";

const RecipePreviewPage: FC = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [recipes, setRecipes] = useState<any>(null);

  const params = useParams();

  const onGetRecipe = async () => {
    try {
      console.log("onGetRecipe");

      setIsLoading(true);
      const { result, error } = await getRecipe(params?.id || "");
      if (result?.data.success) {
        console.log("result?.data?.data", result?.data?.data);

        setRecipes(result?.data?.data);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onGetRecipe();
  }, []);

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <PulseLoader color="#8c42e3" />
        </div>
      )}
      <RecipePreview
        isOpen={!isLoading && recipes}
        setIsOpen={() => {}}
        preview={recipes?.image || ""}
        title={recipes?.title}
        ingredients={recipes?.ingredients}
        tags={recipes?.tags}
        description={recipes?.preparation}
        cookTimeHours={recipes?.cookTime?.hours ? `${recipes?.cookTime?.hours} h` : ""}
        cookTimeMinutes={
          recipes?.cookTime?.minutes ? `${recipes?.cookTime?.minutes} mins` : ""
        }
        date={recipes?.date}
        isClosed={false}
      />
    </div>
  );
};

export default withMainLayout(RecipePreviewPage, true, true, true);
