import React, {
  ChangeEvent,
  FC,
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  JcbIcon,
  AmericanexpressIcon,
  LockIcon,
  MastecardIcon,
  StripeIcon,
  VisaIcon,
  DinnerIcon,
  UnionIcon,
  DiscoverIcon,
  UnitedStatesIcon,
  AustlariaIcon,
  ArrowDownIcon,
} from "../../assets/icons";
import { Progress, Input, Checkbox, Button } from "../index";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { format } from "date-fns";
import {
  buyBoostcamp,
  buyBoostcampTest,
  getBoughtBoostcamps,
} from "../../services/boostcamps.service";
import ClipLoader from "react-spinners/ClipLoader";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearSelectedBoostcamp,
  clearSelectedMonday,
  clearSelectedPrice,
} from "../../store/reducers/PaymentsSlice";
import Modal from "react-modal";
import { useOutsideClick, useWindowDimensions } from "../../hooks";
import { setStartDate } from "../../store/reducers/ProgressSlice";
import { setCurrency } from "../../store/reducers/UserSlice";
import { motion } from "framer-motion";

dayjs.extend(dayOfYear);

interface IProps {
  setIsPaySuccess: (val: boolean) => void;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  activeCountry: number;
  setActiveCountry: React.Dispatch<React.SetStateAction<number>>;
}

const BuyBoostcampModal: FC<IProps> = ({
  setIsPaySuccess,
  isOpen,
  setIsOpen,
  activeCountry,
  setActiveCountry,
}): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [countryItems, setCountryItems] = useState([
    { icon: <UnitedStatesIcon />, title: "USD" },
    { icon: <AustlariaIcon />, title: "AUD" },
  ]);
  // const [activeCountry, setActiveCountry] = useState<number>(0);
  const [isCountryListOpen, setIsCountryListOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currency, user } = useAppSelector((state) => state.userReducer);

  const currencyRef = useRef<any>(null);
  useEffect(() => {
    console.log({ currency });
    setActiveCountry(currency === "USD" ? 0 : 1);
  }, [currency]);
  useEffect(() => {
    console.log({ activeCountry });
    dispatch(setCurrency(activeCountry === 0 ? "USD" : "AUD"));
  }, [activeCountry]);

  const { width, height } = useWindowDimensions();

  const { selectedMonday, selectedBoostcamp, selectedPrice } = useAppSelector(
    (state) => state.paymentsReducer
  );

  const stripe = useStripe();
  const elements = useElements();

  const checkIsUserBuyBoostcamp = async (): Promise<{
    success: boolean;
    error: string;
  }> => {
    return new Promise((resolve, reject) => {
      getBoughtBoostcamps()
        .then(({ error, result }) => {
          if (error) {
            reject(error);
          } else if (!error && result?.data) {
            const isBuyed = result?.data?.data?.bought?.includes(selectedBoostcamp?.id);
            const isActive = result?.data?.data?.active;
            if (isBuyed) {
              reject({
                success: false,
                error: "You have already purchased this boostcamp",
              });
            } else if (isActive) {
              reject({ success: false, error: "You already have active boostcamp" });
            }
            if (!isBuyed && !isActive) {
              resolve({ success: true, error: "" });
            }
          }
        })
        .catch((e) => reject(e));
    });
  };
  useOutsideClick(currencyRef, () => {
    setIsCountryListOpen(false);
  });
  const onBuyBoostcamp = async () => {
    try {
      setIsLoadingPayment(true);

      // @ts-ignore  -- this is needed due to a bug in the type defs for createToken?
      const { token } = await stripe?.createToken(
        // @ts-ignore  -- this is needed due to a bug in the type defs for createToken?
        elements?.getElement(CardElement)
      );

      const selectedDates = selectedMonday ? JSON.parse(selectedMonday) : selectedMonday;

      const date = {
        year: new Date(selectedDates).getFullYear(),
        date: new Date(selectedDates).getDate(),
        month: new Date(selectedDates).getMonth(),
      };

      if (token?.id && selectedMonday && selectedBoostcamp?.id) {
        const res = await checkIsUserBuyBoostcamp();
        if (res?.success) {
          const { error, result } = await buyBoostcamp(
            selectedBoostcamp.id,
            date,
            activeCountry === 0 ? "usd" : "aud",
            isChecked,
            token.id
          );
          if (!error && result?.status === 201) {
            if (result?.data?.data?.boostCamp) {
              dispatch(setStartDate(selectedMonday));

              dispatch(clearSelectedMonday());
              dispatch(clearSelectedPrice());
              // setIsPaySuccess(true);
              navigate("/intro");
              setError("");
            } else {
              setError(
                result?.data?.data.decline_code ||
                  "Something went wrong. Please check your data and try again"
              );
            }
          }
        }
      }

      setIsLoadingPayment(false);
    } catch (error: any) {
      setIsLoadingPayment(false);
      //@ts-ignore
      setError(
        error?.error || "Something went wrong. Please check your data and try again"
      );
      console.log("error", error);
    }
  };

  const onBuyBoostcampTest = async () => {
    try {
      setIsLoadingPayment(true);
      const res = await checkIsUserBuyBoostcamp();
      if (res?.success && selectedBoostcamp?.id) {
        const { error, result } = await buyBoostcampTest(selectedBoostcamp.id);
        if (!error && result?.status === 201) {
          if (result?.data?.data?.boostCamp) {
            dispatch(setStartDate(result?.data?.data?.startDate || new Date()));
            dispatch(clearSelectedMonday());
            dispatch(clearSelectedPrice());
            navigate("/intro");
            setError("");
          } else {
            setError(
              result?.data?.data.decline_code ||
                "Something went wrong. Please check your data and try again"
            );
          }
        }
      }

      setIsLoadingPayment(false);
    } catch (error: any) {
      setIsLoadingPayment(false);
      //@ts-ignore
      setError(
        error?.error || "Something went wrong. Please check your data and try again"
      );
      console.log("error", error);
    }
  };

  const onBuyBoostcampHandle = () => {
    if (user?.testMode) {
      onBuyBoostcampTest();
    } else {
      onBuyBoostcamp();
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#3a3a3a",
        fontFamily: '"Gilroy", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#7c8691",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  console.log("selectedBoostcamp", selectedBoostcamp);

  const calcSelectedPrice = useMemo(
    () =>
      selectedBoostcamp?.printRecipe?.price && isChecked
        ? selectedPrice + selectedBoostcamp?.printRecipe?.price
        : selectedPrice,
    [selectedPrice, selectedBoostcamp, isChecked]
  );

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: width <= 768 ? "20px" : "30px",
          outline: "none",
          overflow: "hidden",
          padding: "0px",
          width: width <= 768 ? "95%" : "auto",
        },
      }}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h4>Order Details</h4>
          <div
            ref={currencyRef}
            // onClick={() => setIsCountryListOpen((prev) => !prev)}
            className={styles.selectCountry}
          >
            {countryItems[activeCountry].icon}
            <span>{countryItems[activeCountry].title}</span>
            {/* <ArrowDownIcon
              style={{
                transform: isCountryListOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.2s linear",
              }}
            /> */}
            {/* {isCountryListOpen && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                transition={{ duration: 0.2 }}
                className={styles.countryList}
              >
                {countryItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setActiveCountry(index)}
                      className={styles.selectCountry}
                    >
                      {countryItems[index].icon}
                      <span>{countryItems[index].title}</span>
                    </div>
                  );
                })}
              </motion.div>
            )} */}
          </div>
        </div>
        <div className={styles.boostcampItem}>
          <img src={selectedBoostcamp?.picture} className={styles.boostcampImg} />
          <div className={styles.boostcampContent}>
            <h5 className={styles.dateTitle}>
              {selectedMonday &&
                format(new Date(JSON.parse(selectedMonday)), "EEEE, MMMM, d, yyyy")}
            </h5>

            <h5>{selectedBoostcamp?.title}</h5>
            <p>
              {selectedMonday && format(new Date(JSON.parse(selectedMonday)), "MMM yyyy")}{" "}
              Boost Camp + eBook
            </p>
            {width <= 768 && <h4>${calcSelectedPrice}</h4>}
          </div>
          {width > 768 && <h4>${calcSelectedPrice}</h4>}
        </div>
        {/* <div className={styles.divider} /> */}

        <CardElement options={CARD_ELEMENT_OPTIONS} />

        {selectedBoostcamp?.printRecipe && (
          <div className={styles.checkBoxWrapper}>
            <div className={styles.checkbox_header}>
              <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} />
              <div className={styles.checkbox_headerContent}>
                <p className={styles.checkbox_headerContentTitle}>
                  {selectedBoostcamp?.printRecipe?.title ||
                    "Include Print Recipe feature"}
                </p>
              </div>
            </div>
            <div className={styles.checkbox_footer}>
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedBoostcamp?.printRecipe?.text || "",
                }}
              />
            </div>
          </div>
        )}
        {error && <p className={styles.error}>{error}</p>}
        <div onClick={onBuyBoostcampHandle} className={styles.startBtn}>
          {isLoadingPayment ? <ClipLoader color={"#fff"} /> : <p>I’M IN. LET’S START!</p>}
        </div>
        <div className={styles.footer}>
          <div className={styles.stripeBlock}>
            <LockIcon />
            <p>
              Guaranteed <span>safe and secure</span> checkout
            </p>
            <StripeIcon />
          </div>
          <div className={styles.paymentMehods}>
            <VisaIcon />
            <MastecardIcon />
            <AmericanexpressIcon />
            <JcbIcon />
            <DinnerIcon />
            <UnionIcon />
            <DiscoverIcon />
          </div>
          <p>
            <span>We hate SPAM</span> and promise to keep your email address safe.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default BuyBoostcampModal;
