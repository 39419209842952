import clsx from "clsx";
import React, { ChangeEvent, FC, useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailVerificationIcon } from "../../assets/icons";
import { Button, Progress } from "../../components";
import { useWindowDimensions } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { withSignLayout } from "../../layouts";
import { setToken } from "../../localStorage";
import { resendCode, verifyEmail } from "../../services/auth.service";
import { setUser } from "../../store/reducers/UserSlice";
import styles from "./styles.module.scss";

interface IProps {}

const EmailVerification: FC<IProps> = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [isResendActive, setIsResendActive] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(60);

  const { width } = useWindowDimensions();

  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const navigate = useNavigate();

  const location = useLocation();

  const selectedMonday = location?.state?.selectedMonday;

  const dispatch = useAppDispatch();

  const timerRef = useRef<any>();

  const startTimer = () => {
    timerRef.current = setTimeout(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timer > 0) {
      startTimer();
    } else if (timer === 0) {
      setIsResendActive(true);
      clearTimeout(timerRef.current);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [timer]);

  const onResendCode = async () => {
    try {
      setTimer(60);
      setIsResendActive(false);
      startTimer();
      await resendCode(location?.state?.email);
    } catch (error) {
      console.log(error);
    }
  };

  const onVerifyEmail = async () => {
    try {
      setIsLoading(true);

      const { error, result } = await verifyEmail(location?.state?.email, code);
      if (error) {
        setError("Wrong verify code, please try again");
      } else if (!error && result?.status === 201) {
        setToken(result?.data?.data?.token);
        dispatch(setUser(result.data?.data?.user));
        if (selectedMonday) {
          navigate("/signup-buy", {
            state: {
              fromSignUp: location?.state?.fromSignUp ? true : false,
              selectedMonday: selectedMonday,
            },
          });
        } else {
          navigate("/home");
        }
        setError("");
      }
      setIsLoading(false);
    } catch (error) {
      setError("Wrong verify code, please try again");
      setIsLoading(false);

      console.log(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      {selectedMonday && <Progress currentStep={2} maxStep={3} />}
      <h4>Confirm your email address</h4>
      <div className={styles.imgWrapper}>
        <EmailVerificationIcon />
      </div>
      <p className={styles.subtitle}>We sent an email to</p>
      <p className={styles.email}>{location?.state?.email}</p>

      <p className={styles.inputLabel}>
        Please find the code in your inbox and type it in the input below:
      </p>
      <div className={styles.inputWrapper}>
        <input
          className={styles.codeInput}
          maxLength={6}
          placeholder={"# # # # #"}
          value={code}
          onChange={onChangeCode}
        />
      </div>
      <p className={styles.subtitle}>
        Didn’t receive the email?{" "}
        <span
          style={{ cursor: isResendActive ? "pointer" : "default" }}
          onClick={() => isResendActive && onResendCode()}
        >
          {isResendActive ? "Send it again." : `${timer} seconds`}
        </span>
      </p>
      {error && <p className={styles.error}>{error}</p>}
      <Button
        title={"Proceed to Payment"}
        size={width <= 768 ? "small" : "medium"}
        styleType={"filled"}
        style={{ marginTop: width <= 768 ? "20px" : "30px" }}
        loading={isLoading}
        onClick={onVerifyEmail}
      />
    </div>
  );
};

export default withSignLayout(EmailVerification);
