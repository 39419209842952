import clsx from "clsx";
import React, { FC, InputHTMLAttributes, useState, ReactNode } from "react";
import { ArrowUpSmallIcon, EyeIcon, EyeOffIcon } from "../../assets/icons";
import styles from "./styles.module.scss";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: null | string;
  icon?: ReactNode;
  multiline?: boolean;
}

const Input: FC<IProps> = ({
  value,
  label,
  type = "text",
  error = "",
  multiline = false,
  icon,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [inputType, setInputType] = useState(type);
  const togglePassword = () => {
    if (inputType === "password") {
      setInputType("text");
      return;
    }
    setInputType("password");
  };

  return (
    <div className={styles.inputContainer}>
      {label && <p className={styles.label}>{label}</p>}
      <div
        className={clsx(styles.inputWrapper, {
          [styles.inputWrapperFocus]: isFocus,
          [styles.inputWrapperError]: error,
        })}
      >
        <input
          onBlur={() => setIsFocus(false)}
          onFocus={() => setIsFocus(true)}
          className={styles.input}
          value={value}
          type={inputType}
          {...props}
        />

        {type === "password" && (
          <div onClick={togglePassword} className={styles.icon}>
            {inputType === "password" ? <EyeOffIcon /> : <EyeIcon />}
          </div>
        )}
        {icon && <div className={styles.icon}>{icon}</div>}
      </div>
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default Input;
