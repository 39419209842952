import { GET } from "../API/axios";

import { AxiosError } from "axios";

interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}
export const getStatistics = (type: "day" | "week" | "month"): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`progress/stats?type=${type}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
