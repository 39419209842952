import React, { FC } from "react";
import styles from "./styles.module.scss";
import {
  ArrowRightBigIcon,
  ArrowRightSmallIcon,
  LoseWeightIcon,
} from "../../assets/icons";
import { Button } from "../index";
import { motion } from "framer-motion";

interface IProps {
  goToTrackers: () => void;
}

const CompleteTrackersPopUp: FC<IProps> = ({ goToTrackers }): JSX.Element => {
  return (
    <motion.div
      initial={{ scale: 0.5 }}
      animate={{ scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      className={styles.wrapper}
    >
      <LoseWeightIcon />
      <h5>You must complete at least one tracker before you end the day</h5>
      <Button
        style={{ borderRadius: "15px" }}
        title={"Go to trackers"}
        styleType={"filled"}
        size={"small"}
        rightIcon={<ArrowRightSmallIcon />}
        onClick={goToTrackers}
      />
    </motion.div>
  );
};

export default CompleteTrackersPopUp;
