import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BoostOnboardingStepsOrder,
  GeneralOnboardingStepsOrder,
  ITooltip,
} from "../../models/ITooltip";

interface IOnboardingState {
  showGeneralOnboarding: boolean;
  generalOnboardingStep: ITooltip | null;
  generalOnboardingStepIndex: number;
  generalOnboardingSteps: ITooltip[];

  showBoostcapmOnboarding: boolean;
  boostcampOnboardingStep: ITooltip | null;
  boostcampOnboardingStepIndex: number;
  boostcampOnboardingSteps: ITooltip[];

  isLoading: boolean;
  error: string;
}

const initialState: IOnboardingState = {
  showGeneralOnboarding: false,
  generalOnboardingStep: null,
  generalOnboardingStepIndex: 0,
  generalOnboardingSteps: [],

  showBoostcapmOnboarding: false,
  boostcampOnboardingStep: null,
  boostcampOnboardingStepIndex: 0,
  boostcampOnboardingSteps: [],

  isLoading: false,
  error: "",
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    //fetch onboarding show
    onboardingFetching: (state) => {
      state.isLoading = true;
    },
    onboardingFetchingSuccess: (
      state,
      action: PayloadAction<{ general: boolean; boostCamp: boolean }>
    ) => {
      console.log("onboardingFetchingSuccess", action.payload);

      state.isLoading = false;
      state.error = "";
      state.showGeneralOnboarding = !action.payload.general;
      if (!action.payload.general) state.generalOnboardingStepIndex = 0;
      state.showBoostcapmOnboarding = !action.payload.boostCamp;
      if (!action.payload.boostCamp) state.boostcampOnboardingStepIndex = 0;
    },
    onboardingFetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    //fetch onboarding steps
    onboardingStepsFetching: (state) => {},
    onboardingStepsFetchingSuccess: (
      state,
      action: PayloadAction<{ steps: ITooltip[]; type: "GENERAL" | "BOOST" }>
    ) => {
      const steps = action.payload.steps;
      if (action.payload.type === "BOOST") {
        state.boostcampOnboardingSteps = steps;
        const findedStep =
          steps?.length > 0
            ? steps.find((st) => st.step === BoostOnboardingStepsOrder[0]) || null
            : null;
        state.boostcampOnboardingStep = findedStep;
      } else {
        state.generalOnboardingSteps = steps;
        const findedStep =
          steps?.length > 0
            ? steps.find((st) => st?.step === GeneralOnboardingStepsOrder[0]) || null
            : null;
        state.generalOnboardingStep = findedStep;
      }
    },
    onboardingStepsFetchingError: (state, action: PayloadAction<string>) => {},
    //update states
    onboardingUpdating: (
      state,
      action: PayloadAction<{ general?: boolean; boostCamp?: boolean }>
    ) => {
      console.log("onboardingUpdating", action.payload);

      if (action.payload.general != undefined) {
        state.showGeneralOnboarding = !action.payload.general;
        if (!action.payload.general) {
          state.generalOnboardingStepIndex = 0;
          state.generalOnboardingStep = null;
        }
      }
      if (action.payload.general != undefined) {
        state.showBoostcapmOnboarding = !action.payload.boostCamp;
        if (!action.payload.boostCamp) {
          state.boostcampOnboardingStepIndex = 0;
          state.boostcampOnboardingStep = null;
        }
      }
    },
    onboardingUpdatingSuccess: (
      state,
      action: PayloadAction<{ general: boolean; boostCamp: boolean }>
    ) => {
      console.log("onboardingUpdatingSuccess", action.payload);

      state.error = "";
      state.showGeneralOnboarding = !action.payload.general;
      if (!action.payload.general) {
        state.generalOnboardingStepIndex = 0;
        state.generalOnboardingStep = null;
      }
      state.showBoostcapmOnboarding = !action.payload.boostCamp;
      if (!action.payload.boostCamp) {
        state.boostcampOnboardingStepIndex = 0;
        state.boostcampOnboardingStep = null;
      }
    },
    onboardingUpdatingError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    //default setters
    setGeneralOnboardingStep: (state, action: PayloadAction<number>) => {
      console.log("setStep", action.payload);

      state.generalOnboardingStepIndex = action.payload;
      const findedStep =
        state.generalOnboardingSteps?.length > 0
          ? state.generalOnboardingSteps.find(
              (st) => st?.step === GeneralOnboardingStepsOrder[action.payload]
            ) || null
          : null;
      state.generalOnboardingStep = findedStep;
    },
    setShowGeneralOnboarding: (state, action: PayloadAction<boolean>) => {
      state.showGeneralOnboarding = action.payload;
    },
    setBoostcampOnboardingStep: (state, action: PayloadAction<number>) => {
      state.boostcampOnboardingStepIndex = action.payload;
      const findedStep =
        state.boostcampOnboardingSteps?.length > 0
          ? state.boostcampOnboardingSteps.find(
              (st) => st?.step === BoostOnboardingStepsOrder[action.payload]
            ) || null
          : null;
      state.boostcampOnboardingStep = findedStep;
    },
    setShowBoostcampOnboarding: (state, action: PayloadAction<boolean>) => {
      state.showBoostcapmOnboarding = action.payload;
    },
  },
});

export const {
  setGeneralOnboardingStep,
  setShowGeneralOnboarding,
  setBoostcampOnboardingStep,
  setShowBoostcampOnboarding,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
