import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IModalState {
  isOpenDayList: boolean;
  isOpenTrackerList: boolean;
}

const initialState: IModalState = {
  isOpenDayList: false,
  isOpenTrackerList: false,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setIsOpenDayList: (state, action: PayloadAction<boolean>) => {
      state.isOpenDayList = action.payload;
    },
    setIsOpenTrackerList: (state, action: PayloadAction<boolean>) => {
      state.isOpenTrackerList = action.payload;
    },
  },
});

export const { setIsOpenDayList, setIsOpenTrackerList } = modalSlice.actions;

export default modalSlice.reducer;
