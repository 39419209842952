import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { getMealPlan, getShoppingList } from "../../services/boostcamps.service";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "../Button";
import { useAppSelector } from "../../hooks/redux";
import { FileDownloadIcon, PrintIcon } from "../../assets/icons";
import { fileDownloader, openFilePrint } from "../../services/file.service";

const ShoppingListMenu = ({ mealPlan, setIsOpen }) => {
  const [mealPlanButton, setMealPlanButton] = useState(
    mealPlan?.length === 2
      ? ["Week 1 + 3", "Week 2 + 4"]
      : mealPlan?.length === 4
      ? ["Week 1", "Week 2", "Week 3", "Week 4"]
      : []
  );
  const [activeWeek, setActiveWeek] = useState(0);
  const [isLoadingMealPlan, setIsLoadingMealPlan] = useState(false);
  const [currentMealPlan, setCurrentMealPlan] = useState(null);
  const [isLoadingShoppingList, setIsLoadingShoppingList] = useState(false);
  const [shoppingList, setShoppingList] = useState(null);
  const { progress } = useAppSelector((state) => state.processReducer);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const ref = useRef();
  const onGetMealPlan = async (id) => {
    try {
      setIsLoadingMealPlan(true);
      const { result, error } = await getMealPlan(id);
      if (result?.data?.success) {
        console.log("onGetMealPlan", id, result?.data?.data);
        setCurrentMealPlan(result?.data?.data);
      }
      setIsLoadingMealPlan(false);
    } catch (e) {
      setIsLoadingMealPlan(false);
    }
  };

  const onGetShoppingList = async (id) => {
    try {
      setIsLoadingShoppingList(true);
      const { result, error } = await getShoppingList(id);
      if (result?.data?.success) {
        console.log("onGetShoppingList", id, result?.data?.data);
        setShoppingList(result?.data?.data);
      }
      setIsLoadingShoppingList(false);
    } catch (e) {
      setIsLoadingShoppingList(false);
    }
  };

  useEffect(() => {
    if (currentMealPlan?.shoppingList) {
      onGetShoppingList(currentMealPlan?.shoppingList);
    }
  }, [currentMealPlan]);

  useEffect(() => {
    if (mealPlan && mealPlan?.length > 0 && mealPlan[activeWeek]) {
      onGetMealPlan(mealPlan[activeWeek]);
    }
  }, [activeWeek]);

  const onDownLoader = async () => {
    try {
      setDownloadLoading(true);
      // await fileDownloader(progress?.shoppingListPdf || "", "Shopping List");
      await openFilePrint(progress?.shoppingListPdf || "", "Shopping List");
      setDownloadLoading(false);
    } catch (error) {
      setDownloadLoading(false);
    }
  };

  return (
    <motion.div
      ref={ref}
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      className={styles.wrapper}
    >
      <div className={styles.header}>
        {mealPlanButton.map((item, index) => {
          return (
            <div
              onClick={() => {
                setActiveWeek(index);
              }}
              className={clsx(styles.dayBtn, {
                [styles.activeDayBtn]: activeWeek === index,
              })}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className={styles.contentContainer}>
        {(isLoadingMealPlan || isLoadingShoppingList) && (
          <div className={styles.loader}>
            <ClipLoader color={"#8C42E3"} size={30} />
          </div>
        )}
        <div className={styles.scrollList}>
          {!isLoadingMealPlan &&
            !isLoadingShoppingList &&
            shoppingList?.categories?.map((item, index) => {
              return (
                <div className={styles.list}>
                  <h1 className={styles.title}>{item?.category?.text}</h1>
                  {item?.ingredients?.map((item, index) => {
                    return (
                      <div className={styles.ingredients}>
                        <span className={styles.title1}>{item?.ingredient?.name}</span>
                        <div className={styles.amountWrapper}>
                          <span>
                            {item?.units[0]?.amount} {item?.units[0]?.unit?.text}
                          </span>
                          {item?.units?.length > 1 && (
                            <>
                              <span>/</span>
                              <span>
                                {item?.units[1]?.amount} {item?.units[1]?.unit?.text}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      {!isLoadingMealPlan && !isLoadingShoppingList && progress?.shoppingListPdf && (
        <Button
          onClick={onDownLoader}
          loading={downloadLoading}
          leftIcon={<PrintIcon width={20} height={20} />}
          title={"SHOPPING LIST"}
        />
      )}
    </motion.div>
  );
};

export default ShoppingListMenu;
