import React, { useEffect } from "react";
import Navigation from "./navigation";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getToken } from "./localStorage";
import { loadScriptZoho } from "./utils";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { fetchBuyedBoostcamps } from "./store/actions/AllBoostcampsActions";
const stripePromise = loadStripe("pk_test_dhnHczqbTANYrs5vOTC8hs5m");
// const stripePromise = loadStripe("pk_live_QkokeC9EQ1icg9TDx6JrYCbr");

function App() {
  const dispatch = useAppDispatch();
  const token = getToken();
  const { buyedBoostcamps } = useAppSelector((state) => state.allBoostcampsReducer);

  useEffect(() => {
    if (!!token) {
      loadScriptZoho(buyedBoostcamps?.length > 0 && !!token);
    } else {
      loadScriptZoho(!!token);
    }
  }, [buyedBoostcamps, buyedBoostcamps?.length, token]);

  useEffect(() => {
    if (!!token) {
      dispatch(fetchBuyedBoostcamps());
    }
  }, [token]);

  return (
    <>
      <Elements stripe={stripePromise}>
        <Navigation />
      </Elements>
    </>
  );
}

export default App;
