import { POST, GET, PUT } from "../API/axios";
import { AxiosError } from "axios";
interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}

interface IUserUpdate {
  email?: string;
  firstName?: string;
  lastName?: string;
  location?: string;
  picture?: string;
}

export const getMe = (): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET("users/me")
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const editUser = (
  { email = "", firstName = "", lastName = "", location = "", picture = "" }: IUserUpdate,
  userId: string
): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body: IUserUpdate = {
      // email,
      firstName,
      lastName,
      // location,
    };
    if (picture) body.picture = picture;

    PUT(`users/${userId}`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
