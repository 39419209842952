import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProgress, IProgressDay } from "../../models/IProgress";
import { checkIsTodayOrBefore } from "../../utils";

interface IProgressState {
  progress: IProgress | null;
  isLoading: boolean;
  currentDay: number;
  error: string;
  maxPoints: number;
  currentPoints: number;
  isOpenSleepTracker: boolean;
  isOpenWaterTracker: boolean;
  isOpenFastingTracker: boolean;
  isOpenWeightTracker: boolean;
  isOpenBodyTracker: boolean;
  isAvailableTrackers: boolean;
  startDate: string;
  isUpdatingTasks: boolean;
}

const initialState: IProgressState = {
  progress: null,
  isLoading: false,
  currentDay: 0,
  maxPoints: 0,
  currentPoints: 0,
  error: "",
  isOpenSleepTracker: false,
  isOpenFastingTracker: false,
  isOpenWaterTracker: false,
  isOpenWeightTracker: false,
  isOpenBodyTracker: false,
  isAvailableTrackers: false,
  startDate: "",
  isUpdatingTasks: false,
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    progressFetching: (state) => {
      state.isLoading = true;
    },
    progressFetchingSuccess: (state, action: PayloadAction<IProgress | null>) => {
      console.log("progressFetchingSuccess");
      state.isLoading = false;
      state.error = "";
      state.progress = action.payload;
      state.isAvailableTrackers = checkIsTodayOrBefore(
        new Date(),
        action.payload?.startDate
      );
      if (action.payload?.startDate)
        state.startDate = action.payload?.startDate.toString();
    },
    progressFetchingError: (state, action: PayloadAction<string>) => {
      console.log("progressFetchingError");
      state.isLoading = false;
      state.error = action.payload;
      state.progress = null;
      state.currentDay = 0;
      state.maxPoints = 0;
      state.currentPoints = 0;
      state.error = "";
      state.isOpenSleepTracker = false;
      state.isOpenFastingTracker = false;
      state.isOpenWaterTracker = false;
      state.isOpenWeightTracker = false;
      state.isOpenBodyTracker = false;
      state.isAvailableTrackers = false;
      state.startDate = "";
    },
    progressClear: (state) => {
      console.log("progressClear");
      state.progress = null;
      state.error = "";
      state.isLoading = false;
      state.currentDay = 0;
      state.maxPoints = 0;
      state.currentPoints = 0;
      state.isOpenSleepTracker = false;
      state.isOpenFastingTracker = false;
      state.isOpenWaterTracker = false;
      state.isOpenWeightTracker = false;
      state.isOpenBodyTracker = false;
      state.isAvailableTrackers = false;
    },
    setProgress: (state, action: PayloadAction<IProgress | null>) => {
      console.log("setProgress");

      state.progress = action.payload;
      state.error = "";
      state.isLoading = false;
      state.isAvailableTrackers = checkIsTodayOrBefore(
        new Date(),
        action.payload?.startDate
      );
    },
    setCurrentDay: (state, action: PayloadAction<number>) => {
      console.log("setCurrentDay", action.payload);
      state.currentDay = action.payload;
    },
    setMaxPoints: (state, action: PayloadAction<number>) => {
      state.maxPoints = action.payload;
    },
    setCurrentPoints: (state, action: PayloadAction<number>) => {
      state.currentPoints = action.payload;
    },
    setIsOpenSleepTracker: (state, action: PayloadAction<boolean>) => {
      state.isOpenSleepTracker = action.payload;
    },
    setIsOpenFastingTracker: (state, action: PayloadAction<boolean>) => {
      state.isOpenFastingTracker = action.payload;
    },
    setIsOpenWaterTracker: (state, action: PayloadAction<boolean>) => {
      state.isOpenWaterTracker = action.payload;
    },
    setIsOpenWeightTracker: (state, action: PayloadAction<boolean>) => {
      state.isOpenWeightTracker = action.payload;
    },
    setIsOpenBodyTracker: (state, action: PayloadAction<boolean>) => {
      state.isOpenBodyTracker = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setProgressIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setProgressDay: (state, action: PayloadAction<IProgressDay | null>) => {
      const newProgress = state.progress
        ? {
            ...state.progress,
            days: state.progress?.days
              ? state.progress.days.map((d) =>
                  d.id === action.payload?.id ? action.payload : d
                )
              : [],
          }
        : null;
      state.progress = newProgress;
    },
    setProgressCompleted: (state, action: PayloadAction<boolean>) => {
      if (state.progress)
        state.progress = {
          ...state.progress,
          isCompleted: action.payload,
        };
    },
    setIsUpdatingTasks: (state, action: PayloadAction<boolean>) => {
      state.isUpdatingTasks = action.payload;
    },
  },
});

export const {
  progressFetching,
  progressFetchingSuccess,
  progressFetchingError,
  progressClear,
  setProgress,
  setCurrentDay,
  setMaxPoints,
  setCurrentPoints,
  setIsOpenSleepTracker,
  setIsOpenFastingTracker,
  setIsOpenWaterTracker,
  setIsOpenWeightTracker,
  setIsOpenBodyTracker,
  setStartDate,
  setProgressIsLoading,
  setProgressDay,
  setProgressCompleted,
  setIsUpdatingTasks,
} = progressSlice.actions;

export default progressSlice.reducer;
