import { POST, GET, PUT } from "../API/axios";
import { AxiosError } from "axios";
import { IOnboardingUpdate } from "../models/IOnboarding";
interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}

export const getOnboarding = (): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET("onboarding")
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const updateOnboarding = ({
  general,
  boostCamp,
}: IOnboardingUpdate): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    let body: IOnboardingUpdate = {};
    if (general != undefined) body.general = general;
    if (boostCamp != undefined) body.boostCamp = boostCamp;

    PUT(`onboarding`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
