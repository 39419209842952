import clsx from "clsx";
import React, { FC, HTMLAttributes, ReactNode, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import Button from "../Button";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  text?: string;
  step: number;
  maxSteps: number;
  onClickBtn: () => any;
  timeout?: number;
  onClickSkip?: () => any;
}

const OnboardingTooltip: FC<IProps> = ({
  title,
  text,
  step,
  maxSteps,
  onClickBtn,
  className,
  timeout = 0,
  onClickSkip,
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current)
      setTimeout(() => {
        ref.current?.scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "smooth",
        });
      }, timeout);
  }, [ref, timeout]);

  return (
    <div className={clsx(styles.wrapper, className)} ref={ref}>
      <h3 className={styles.title}>{title}</h3>

      <p className={styles.text}>{text}</p>
      <div className={styles.footer}>
        <div className={styles.steps}>
          {step} of {maxSteps}
        </div>
        <div className={styles.btns}>
          {onClickSkip && (
            <Button
              title={"Skip"}
              styleType={"outlined"}
              size={"small"}
              onClick={onClickSkip}
              className={styles.skip}
            />
          )}
          <Button
            title={"Next"}
            styleType={"filled"}
            size={"small"}
            onClick={onClickBtn}
            className={styles.button}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingTooltip;
