import React, { FC } from "react";
import { NotificationIcon } from "../../assets/icons";
import { INotification } from "../../models/INotification";
import styles from "./styles.module.scss";

interface IProps {
  notification: INotification;
}

const NotificationItem: FC<IProps> = ({ notification }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <NotificationIcon />
      </div>
      <div className={styles.decription}>
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: notification?.title || "",
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: notification?.text || "",
          }}
          className={styles.description}
        />
      </div>
    </div>
  );
};

export default NotificationItem;
