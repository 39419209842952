import React, { FC, ReactNode, useState, useEffect, useRef, useMemo } from "react";
import { InfoIcon, LockIcon, PencilSmallIcon, PenIcon } from "../../assets/icons";
import { useOutsideClick } from "../../hooks";
import { useAppSelector } from "../../hooks/redux";
import { ITrackers } from "../../models/ITrackers";
import { Button } from "../index";
import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import clsx from "clsx";
import { IProgress } from "../../models/IProgress";

interface IProps {
  icon: ReactNode;
  title: string;
  color: string;
  btnColor: string;
  onClick?: () => void;
  value: ITrackers | null;
  type: "sleep" | "water" | "fasting" | "weight" | "body";
  infoPosition?: "bottom" | "top";
  currentProgress?: IProgress | null;
  currentIsAvailableTracker?: boolean;
  auto?: boolean;
}

const DailyTaskSetItem: FC<IProps> = ({
  icon,
  title,
  color,
  btnColor,
  onClick = () => {},
  value = null,
  type,
  infoPosition = "top",
  currentProgress,
  currentIsAvailableTracker,
  auto = false,
}): JSX.Element => {
  const [formattedValue, setFormattedValue] = useState<string>("");
  const [isOpenInfo, setIsOpenInfo] = useState<boolean>(false);
  const [infoText, setInfoText] = useState<string>("");

  const { progress, currentDay, isAvailableTrackers, isUpdatingTasks } = useAppSelector(
    (state) => state.processReducer
  );

  const handledIsAvailableTrackers = useMemo(
    () => currentIsAvailableTracker || isAvailableTrackers,
    [isAvailableTrackers, currentIsAvailableTracker]
  );
  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const infoRef = useRef(null);
  useOutsideClick(infoRef, () => setIsOpenInfo(false));

  const isClosed =
    handledProgress?.days[currentDay]?.isClose ||
    handledProgress?.days[currentDay]?.isCompleted;

  const formatValue = (value: ITrackers | null) => {
    if (type === "sleep") {
      setInfoText("How long did you sleep last night?");
    }
    if (type === "water") {
      setInfoText("How much water have you had today?");
    }
    if (type === "fasting") {
      setInfoText("How long has it been since your last meal or caloric beverage?");
    }
    if (type === "weight") {
      setInfoText("How much do you weigh?");
    }
    if (value === null) {
      return;
    }

    if (type === "sleep") {
      setFormattedValue(
        value?.sleep === 2
          ? "≤2 h"
          : value?.sleep === 9
          ? "≥9 h"
          : `${value?.sleep || ""}${value?.sleep ? " h" : ""}`
      );
    }
    if (type === "water") {
      setFormattedValue(
        value?.water?.l === 4
          ? `>3 l`
          : value?.water?.l === 0
          ? ""
          : `${value?.water?.l} l`
      );
      setInfoText("How much water have you had today?");
    }
    if (type === "fasting") {
      setFormattedValue(value?.eat > 0 ? `${value?.eat} h` : "");
      setInfoText("How long has it been since your last meal or caloric beverage?");
    }
    if (type === "weight") {
      setFormattedValue(value?.weight?.kg > 0 ? `${value?.weight.kg} kg` : "");
      setInfoText("How much do you weigh?");
    }
    if (type === "body") {
      setFormattedValue(
        value?.proportions.bust.cm > 0 &&
          value.proportions.waist.cm > 0 &&
          value.proportions.hips.cm > 0
          ? `${value.proportions.bust.cm}/${value.proportions.waist.cm}/${value.proportions.hips.cm}`
          : ""
      );
    }
  };

  const btnHandledColor = useMemo(
    () => (isUpdatingTasks ? "#7c8691" : btnColor),
    [btnColor, isUpdatingTasks]
  );

  const onClickHandled = () => {
    if (!isUpdatingTasks) onClick && onClick();
  };

  useEffect(() => {
    formatValue(value);
  }, [value]);

  return (
    <div className={clsx(styles.wrapper, auto && styles.wrapperAuto)}>
      <div style={{ background: color }} className={styles.iconWrapper}>
        <div
          onMouseOver={() => setIsOpenInfo(true)}
          onMouseLeave={() => setIsOpenInfo(false)}
          ref={infoRef}
          className={styles.infoBtn}
        >
          {isOpenInfo && (
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
              className={clsx(styles.infoBlock, {})}
            >
              {infoText}
            </motion.div>
          )}
          <InfoIcon style={{ color: btnColor }} />
        </div>
        {icon}
      </div>
      <p>{title}</p>
      <div className={styles.btnWrapper}>
        {formattedValue || isClosed ? (
          <div style={{ cursor: "pointer" }} onClick={onClickHandled}>
            {
              <div className={styles.editIcon}>
                <div
                  style={{ borderColor: btnHandledColor }}
                  className={styles.editIconWrapper}
                >
                  <PencilSmallIcon color={btnHandledColor} />
                </div>
              </div>
            }
            <Button
              styleType="filled"
              size={"medium"}
              title={isClosed ? (formattedValue ? formattedValue : "0") : formattedValue}
              style={{ padding: "15px 15px", backgroundColor: btnHandledColor }}
            />
          </div>
        ) : handledIsAvailableTrackers ? (
          <Button
            title="Set"
            styleType="outlined-dashed"
            size={"small"}
            color={btnHandledColor}
            backgroundColor={"#fff"}
            onClick={onClickHandled}
          />
        ) : (
          <div style={{ position: "relative" }}>
            <div
              style={{ backgroundColor: color, borderColor: btnHandledColor }}
              className={styles.lockedWrapper}
            />
            <div className={styles.lockedIconWrapper}>
              <LockIcon style={{ color: btnHandledColor }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyTaskSetItem;
