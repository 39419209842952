import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBoostcamp } from "../../models/IBoostcamp";

interface IBoostcampsState {
  boostcamps: Array<IBoostcamp>;
  isLoading: boolean;
  error: string;
  buyedBoostcamps: Array<string>;
  activeBoostcamps: string;
}

interface IBuyedBoostcampPayload {
  bought: Array<string>;
  active: string;
}

const initialState: IBoostcampsState = {
  boostcamps: [],
  isLoading: false,
  error: "",
  buyedBoostcamps: [],
  activeBoostcamps: "",
};

export const allBoostcampsSlice = createSlice({
  name: "allboostcamps",
  initialState,
  reducers: {
    allBoostcampsFetching: (state) => {
      state.isLoading = true;
    },
    allBoostcampsFetchingSuccess: (state, action: PayloadAction<Array<IBoostcamp>>) => {
      state.isLoading = false;
      state.error = "";
      state.boostcamps = action.payload;
    },
    allBoostcampsFetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearAllBoostcamps: (state) => {
      state.boostcamps = [];
      state.isLoading = false;
      state.error = "";
    },
    buyedBoostcampsFetching: (state, action: PayloadAction<IBuyedBoostcampPayload>) => {
      state.buyedBoostcamps = action.payload.bought;
      state.activeBoostcamps = action.payload.active;
    },
    clearBuyedBoostcamps: (state) => {
      state.buyedBoostcamps = [];
      state.activeBoostcamps = "";
    },
  },
});

export const {
  allBoostcampsFetchingError,
  allBoostcampsFetching,
  allBoostcampsFetchingSuccess,
  clearAllBoostcamps,
  buyedBoostcampsFetching,
  clearBuyedBoostcamps,
} = allBoostcampsSlice.actions;

export default allBoostcampsSlice.reducer;
