import React, { FC, useState, useEffect, useMemo } from "react";
import Modal from "react-modal";
import { WaterIcon } from "../../assets/icons";
import Button from "../Button";
import styles from "./styles.module.scss";
import Slider from "rc-slider";
import "./index.css";
import clsx from "clsx";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import {
  setIsOpenWaterTracker,
  setProgress,
  setProgressDay,
} from "../../store/reducers/ProgressSlice";
import { setTrackers } from "../../services/progress.service";
import { ITrackers } from "../../models/ITrackers";
import { convertLitrToOz, convertOztoLitr } from "../../utils";
import { IProgress } from "../../models/IProgress";
import { TRACKERS } from "../../constants";
import { updateProgressDay } from "../../utils/progress";

interface IProps {
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
}

const WaterTracker: FC<IProps> = ({
  currentProgress,
  setCurrentProgress,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isOpenWaterTracker, currentDay, progress } = useAppSelector(
    (state) => state.processReducer
  );
  const [options, setOptions] = useState(TRACKERS.lOptions);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeType, setActiveType] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const onClose = () => {
    dispatch(setIsOpenWaterTracker(false));
  };

  useEffect(() => {
    if (activeType === 0) {
      setOptions(TRACKERS.lOptions);
    }
    if (activeType === 1) {
      setOptions(TRACKERS.ozOptions);
    }
  }, [activeType]);

  const calculateRecommendedWater = () => {
    const weightKg = handledProgress?.days[currentDay]?.trackers?.weight?.kg;
    const weightIbs = handledProgress?.days[currentDay]?.trackers?.weight?.ibs;

    const recommendedL = weightKg ? Number(weightKg) * 0.035 : 0;
    const recommendedOz = weightKg ? Number(weightIbs) * 0.6 : 0;
    if (activeType === 0) {
      return recommendedL.toFixed(1);
    } else {
      return recommendedOz.toFixed(1);
    }
  };

  const onChangeWater = async () => {
    try {
      setIsLoadingSubmit(true);
      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          water: {
            l:
              activeType === 0
                ? +Number(currentValue).toFixed(1)
                : convertOztoLitr(currentValue),
            flOz:
              activeType === 1
                ? +Number(currentValue).toFixed(1)
                : convertLitrToOz(currentValue),
          },
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          onClose();
        }
      }

      setIsLoadingSubmit(false);
    } catch (error) {
      setIsLoadingSubmit(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const waterValue = handledProgress?.days[currentDay]?.trackers?.water?.l;
    setCurrentValue(waterValue || 0);
    setActiveType(0);
    const index = TRACKERS.lOptions.findIndex((item) => item.value === waterValue);
    setActiveIndex(index > 0 ? index : 0);
  }, [handledProgress, currentDay, isOpenWaterTracker]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpenWaterTracker}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <WaterIcon />
          </div>
          <h5>
            How much water did
            <br /> you drink today?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderValueWrapper}>
            <span>{options[activeIndex]?.label}</span>
            <div className={styles.btnContainer}>
              <div
                onClick={() => setActiveType(0)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 0,
                })}
              >
                L
              </div>
              <div
                onClick={() => setActiveType(1)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 1,
                })}
              >
                Fl Oz
              </div>
            </div>
          </div>
          <p className={styles.recommended}>
            Recommended amount of water:{" "}
            <b>
              {calculateRecommendedWater() !== `0.0`
                ? `${calculateRecommendedWater()} ${activeType === 0 ? "L" : "Fl Oz"} `
                : "—"}
            </b>
          </p>
          <Slider
            min={0}
            max={7}
            defaultValue={activeIndex}
            onChange={(value: any) => {
              setActiveIndex(value);
              setCurrentValue(options[value]?.value);
            }}
            trackStyle={{ height: "15px", backgroundColor: "#79DCD5" }}
            railStyle={{ height: "15px", backgroundColor: "#E4E4E4" }}
            handleStyle={{
              height: "26px",
              width: "26px",
              backgroundColor: "#fff",
              borderColor: "#79DCD5",
              borderWidth: "8px",
              opacity: 1,
            }}
          />
          <div className={styles.sliderOptionsWrapper}>
            {options?.map((item, index) => {
              return (
                <span className={styles.sliderOption} key={index}>
                  {item?.label}
                </span>
              );
            })}
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          backgroundColor={"#79DCD5"}
          loading={isLoadingSubmit}
          onClick={onChangeWater}
        />
      </div>
    </Modal>
  );
};

export default WaterTracker;
