import React, { FC } from "react";
import getYear from "date-fns/getYear";

import styles from "./styles.module.scss";

const Footer: FC = () => {
  const openZoho = () => {
    const element = document.getElementById("zohohc-asap-web-launcherbox");
    if (!!element) {
      element?.click();
    }
  };

  return (
    <footer className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <p className={styles.description}>
          © {getYear(new Date())} Bridget Davis trading as Bridgets Healthy Kitchen. All
          Rights Reserved.
          <br />
          Disclaimer: Individual results may vary. Proper diet and exercise are necessary
          to achieve and maintain weight loss.
          <br />
          Consult your healthcare professional before beginning any diet or fitness
          regime.
        </p>
        <div className={styles.supportWrapper}>
          <p className={styles.supportText}>
            <span className={styles.supportTextItem}>
              For support, <span onClick={openZoho}>contact</span>
            </span>
            {/* <span>our admin team</span> */}
            <span className={styles.supportTextItem}>
              <a
                href="https://bridgets.shop/pages/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </span>
            <span className={styles.supportTextItem}>
              <a
                href="https://bridgets.shop/pages/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
