export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const setToken = (token: string) => {
  localStorage.setItem("accessToken", token);
};

export const removeToken = () => {
  localStorage.removeItem("accessToken");
};

export const setSelectedMonday = (selectedMonday: string) => {
  localStorage.setItem("selectedMonday", selectedMonday);
};

export const clearSelectedMonday = () => {
  localStorage.removeItem("selectedMonday");
};
