export enum TooltipTypeEnum {
  GENERAL = "GENERAL",
  BOOST = "BOOST",
}

export enum GeneralOnboardingStepsEnum {
  TASKS = "TASKS",
  OPEN_BOOST = "OPEN_BOOST",
  CLOSED_BOOST = "CLOSED_BOOST",
  DISCOUNT = "DISCOUNT",
  PROFILE = "PROFILE",
  NOTIF = "NOTIF",
  SHOPPING = "SHOPPING",
  MEAL = "MEAL",
}
export const GeneralOnboardingStepsOrder = [
  GeneralOnboardingStepsEnum.TASKS,
  GeneralOnboardingStepsEnum.OPEN_BOOST,
  GeneralOnboardingStepsEnum.CLOSED_BOOST,
  GeneralOnboardingStepsEnum.DISCOUNT,
  GeneralOnboardingStepsEnum.PROFILE,
  GeneralOnboardingStepsEnum.NOTIF,
  GeneralOnboardingStepsEnum.SHOPPING,
  GeneralOnboardingStepsEnum.MEAL,
];

export enum BoostOnboardingStepsEnum {
  CLOSED_DAY = "CLOSED_DAY",
  OPEN_DAY = "OPEN_DAY",
  TASKS = "TASKS",
  TASKS_COMPL = "TASKS_COMPL",
  DAILY = "DAILY",
  BTN_MEAl = "BTN_MEAl",
  BTN_VIDEO = "BTN_VIDEO",
}
export const BoostOnboardingStepsOrder = [
  BoostOnboardingStepsEnum.CLOSED_DAY,
  BoostOnboardingStepsEnum.OPEN_DAY,
  BoostOnboardingStepsEnum.TASKS,
  BoostOnboardingStepsEnum.TASKS_COMPL,
  BoostOnboardingStepsEnum.DAILY,
  BoostOnboardingStepsEnum.BTN_MEAl,
  BoostOnboardingStepsEnum.BTN_VIDEO,
];

export type ITooltip = {
  id: string;
  type: TooltipTypeEnum;
  title: string;
  description: string;
  step: GeneralOnboardingStepsEnum | BoostOnboardingStepsEnum;
  screenshot: string;
};
