import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { withMainLayout } from "../../layouts";
import {
  BodyTracker,
  Button,
  ChecklistTasks,
  CompleteDayModal,
  CompleteTrackersPopUp,
  DailyReminders,
  DailyTaskSetItem,
  DayItem,
  FastingTracker,
  ReceipsModal,
  SleepTracker,
  StickyDayButton,
  StickyTrackerButton,
  WaterTracker,
  WeightTracker,
  DaysList,
  TrackerListMenu,
  CountDownTimer,
  CompleteDayWhithoutSoundModal,
  OnboardingTooltip,
} from "../../components";
import styles from "./styles.module.scss";
import {
  BodyIcon,
  CalendarColorIcon,
  CalendarIcon,
  ChecklistIcon,
  CloseDayIcon,
  CrossIcon,
  FacebookIcon,
  SaladIcon,
  SleepIcon,
  WaterIcon,
} from "../../assets/icons";
import { useWindowDimensions } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { boostcampClear } from "../../store/reducers/BoostcampSlice";
import { fetchBoostcamp } from "../../store/actions/BoostcampActions";
import { PulseLoader } from "react-spinners";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { fetchProgress } from "../../store/actions/ProgressActions";
import {
  completeBoostcamp,
  completeDay,
  getMyAllProgress,
  setTask,
} from "../../services/progress.service";
import {
  setCurrentDay,
  setCurrentPoints,
  setIsOpenFastingTracker,
  setIsOpenSleepTracker,
  setIsOpenWaterTracker,
  setIsOpenWeightTracker,
  setMaxPoints,
  setProgressCompleted,
  setProgressDay,
  setProgressIsLoading,
} from "../../store/reducers/ProgressSlice";
import { IProgress } from "../../models/IProgress";
import Fasting from "../../assets/images/fasting.png";
import { setIsOpenDayList, setIsOpenTrackerList } from "../../store/reducers/ModalSlice";
import clsx from "clsx";
import {
  fetchOnboarding,
  fetchOnboardingSteps,
  finishOnboarding,
} from "../../store/actions/OnboardingAction";
import { setBoostcampOnboardingStep } from "../../store/reducers/OnboardingSlice";
import { BoostOnboardingStepsEnum } from "../../models/ITooltip";
import { checkIsTodayOrBefore } from "../../utils";
import { reduceCompletedDays, updateProgressDay } from "../../utils/progress";

const mobileAdaptiveWidth = 950;

interface IProps {}

interface ITimeLeft {
  days?: number;
  hour?: number;
  minutes?: number;
  seconds?: number;
}

const Countdown = ({
  handledProgress,
  handledIsAvailableTrackers,
}: {
  handledProgress: IProgress | null;
  handledIsAvailableTrackers: boolean;
}) => {
  const [timeLeft, setTimeLeft] = useState<ITimeLeft>({});

  useEffect(() => {
    let interval: any = null;
    if (!handledIsAvailableTrackers) {
      interval = setInterval(() => {
        calculateTimeLeft(`${handledProgress?.startDate}`);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [handledIsAvailableTrackers, handledProgress]);

  const calculateTimeLeft = (startDate: string) => {
    const date = new Date(startDate);
    date.setHours(0, 0, 0, 0);

    let difference = +new Date(date) - +new Date();

    let timeLeft: ITimeLeft = { days: 0, hour: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    setTimeLeft(timeLeft);
  };

  return (
    <div className={styles.timerWrapper}>
      <CountDownTimer value={timeLeft.days || 0} maxValue={30} title={"DAYS"} />
      <CountDownTimer value={timeLeft.hour || 0} maxValue={24} title={"HOURS"} />
      <CountDownTimer value={timeLeft.minutes || 0} maxValue={60} title={"MINUTES"} />
      <CountDownTimer value={timeLeft.seconds || 0} maxValue={60} title={"SECONDS"} />
    </div>
  );
};

const ProgressBoostcamp: FC<IProps> = (): JSX.Element => {
  const { width, height } = useWindowDimensions();
  const [isOpenDaysMenu, setIsOpenDaysMenu] = useState<boolean>(false);
  const [isOpenReceips, setIsOpenReceips] = useState<boolean>(false);
  const [isOpenMealPlan, setIsOpenMealPlan] = useState<boolean>(false);
  const [isOpenCompleteTrackers, setIsOpenCompleteTrackers] = useState(false);
  const [isOpenCompleteTrackersTask, setIsOpenCompleteTrackersTask] = useState(false);
  const [isOpenCompleteDay, setIsOpenCompleteDay] = useState<boolean>(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState<boolean>(false);
  const [isLoadingCompleteBoost, setIsLoadingCompleteBoost] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const [currentProgress, setCurrentProgress] = useState<IProgress | null>(null);
  const [isCurrentProgressLoading, setIsCurrentProgressLoading] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userReducer);
  const { boostcamp } = useAppSelector((state) => state.boostcampReducer);
  const { progress, isLoading, currentDay, isAvailableTrackers } = useAppSelector(
    (state) => state.processReducer
  );

  const { isOpenTrackerList, isOpenDayList } = useAppSelector(
    (state) => state.modalReducer
  );
  const {
    showBoostcapmOnboarding,
    boostcampOnboardingStep,
    boostcampOnboardingSteps,
    boostcampOnboardingStepIndex,
  } = useAppSelector((state) => state.onboardingReducer);

  const handledProgress = useMemo(
    () => (progress?.id === id ? progress : currentProgress),
    [currentProgress, progress, id]
  );

  const currentIsAvailableTracker = useMemo(
    () => checkIsTodayOrBefore(new Date(), currentProgress?.startDate),
    [currentProgress]
  );

  const handledIsAvailableTrackers = useMemo(
    () => (progress?.id === id ? isAvailableTrackers : currentIsAvailableTracker),
    [progress, id, isAvailableTrackers, currentIsAvailableTracker]
  );

  const isClosed =
    handledProgress?.days[currentDay]?.isClose || !handledIsAvailableTrackers;

  const isCompleted = handledProgress?.days[currentDay]?.isCompleted;

  const daysProgress = useMemo(
    () =>
      user?.testMode
        ? reduceCompletedDays(handledProgress?.days)
        : handledProgress?.passedDays
        ? handledProgress.passedDays + 1
        : 1,
    [user?.testMode, handledProgress]
  );

  const openMenuHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsOpenDaysMenu(true);
  };

  const closeMenuHandler = () => {
    setIsOpenDaysMenu(false);
  };

  const calculatePoints = (progress: IProgress) => {
    let currentValue = 0;
    let maxValue = 0;
    if (progress?.days[currentDay]) {
      progress?.days[currentDay].tasks.forEach((item, index) => {
        maxValue += item?.points;
        if (item?.isChecked) {
          currentValue += item?.points;
        }
      });
      dispatch(setMaxPoints(maxValue));
      dispatch(setCurrentPoints(currentValue));
    }
  };

  const getCompletedProgress = async () => {
    try {
      setIsCurrentProgressLoading(true);
      dispatch(setProgressIsLoading(false));
      const { error, result } = await getMyAllProgress();

      if (!error && result?.data) {
        let allProgress: IProgress[] = result?.data?.data?.rows;

        let index = allProgress.findIndex((el: any) => el?.id === id);
        if (index !== -1) {
          setCurrentProgress(allProgress[index]);
          // dispatch(setProgress(allProgress[index]));
        }
      }
      dispatch(setCurrentDay(0));
    } catch (e) {
      console.log(e);
    } finally {
      setIsCurrentProgressLoading(false);
    }
  };

  useEffect(() => {
    if (handledProgress) {
      calculatePoints(handledProgress);
      //If has current day return
      if (currentDay) return;
      //If not than
      if (
        !location?.state?.isCompleted &&
        !location?.state?.isTutorial &&
        !user?.testMode
      ) {
        //set for passed test for real user and no tutorial
        dispatch(setCurrentDay(handledProgress?.passedDays || 0));
      } else {
        //set 0 for test user or tutorial
        dispatch(setCurrentDay(0));
      }
    }
  }, [handledProgress, user, currentDay]);

  console.log("handledProgress", handledProgress);

  useEffect(() => {
    if (isOpenDaysMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isOpenDaysMenu]);

  useEffect(() => {
    if (progress?.id !== id && !currentProgress) {
      getCompletedProgress();
    }
  }, [currentProgress, progress, id]);

  useEffect(() => {
    if (id) {
      dispatch(fetchBoostcamp(id));
    }
    if (location?.state?.isCompleted || location?.state?.isTutorial) {
      getCompletedProgress();
    } else {
      dispatch(fetchProgress());
    }

    //add if need to ckeck tutorial
    // dispatch(finishOnboarding({ boostCamp: false }));
    dispatch(fetchOnboarding());

    return () => {
      dispatch(boostcampClear());
    };
  }, []);

  const goToTrackers = () => {
    if (width <= mobileAdaptiveWidth) {
      onOpenTrackerList();
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setIsOpenCompleteTrackers(false);
  };

  const onOpenTrackerList = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
    dispatch(setIsOpenTrackerList(true));
  };

  const onCompleteDay = async (type: "task" | "info") => {
    try {
      const trackers = handledProgress?.days[currentDay]?.trackers;
      if (
        trackers?.eat === 0 &&
        trackers?.sleep === 0 &&
        trackers?.water?.l === 0 &&
        trackers?.water?.flOz === 0 &&
        trackers?.weight?.kg === 0 &&
        trackers?.weight?.ibs === 0
      ) {
        if (type === "task") {
          setIsOpenCompleteTrackersTask(true);
        } else {
          setIsOpenCompleteTrackers(true);
        }
        return;
      } else {
        setIsOpenCompleteTrackersTask(false);
        setIsOpenCompleteTrackers(false);
      }

      setIsLoadingComplete(true);

      const { error, result } = await completeDay(
        handledProgress?.days[currentDay].id || ""
      );

      if (!error && result?.status === 202) {
        setIsOpenCompleteDay(true);
      }

      setIsLoadingComplete(false);
    } catch (error) {
      setIsOpenCompleteTrackers(false);
      setIsOpenCompleteTrackersTask(false);
      setIsLoadingComplete(false);
    }
  };

  const onCompleteBoostcamp = async () => {
    try {
      setIsLoadingCompleteBoost(true);

      const { error, result } = await completeBoostcamp(
        handledProgress?.days[currentDay].id || ""
      );
      if (!error && result?.status === 202) {
        if (progress) {
          dispatch(setProgressDay(result?.data?.data));
          dispatch(setProgressCompleted(true));
        } else {
          setCurrentProgress((prev) => updateProgressDay(prev, result, true));
        }
      }

      setIsLoadingCompleteBoost(false);
    } catch (error) {}
  };

  const onSetTask = async (taskId: string) => {
    try {
      const { error, result } = await setTask(
        handledProgress?.days[currentDay].id || "",
        taskId
      );
      if (!error && result?.status === 202) {
        if (progress) {
          dispatch(setProgressDay(result?.data?.data));
        } else {
          setCurrentProgress((prev) => updateProgressDay(prev, result));
        }
      }
    } catch (error) {}
  };

  const checkDayStatus = useCallback(
    (
      item: any,
      index: number,
      currentDay: number
    ):
      | "opened"
      | "complete"
      | "closed"
      | "opened-completed"
      | "current"
      | "not-completed"
      | "opened-not-completed" => {
      const sameDay = index.toString() === currentDay?.toString();
      console.log({
        isCompleted: item?.isCompleted,
        isClose: item?.isClose,
        index,
        currentDay,
        passedDays: handledProgress?.passedDays,
        sameDay,
      });

      // //For test users and beta testers
      if (user?.testMode || user?.betaTest) {
        if (user?.betaTest && index > 3) return "closed";
        if (sameDay && item?.isCompleted) return "opened-completed";
        if (sameDay && !item?.isCompleted) return "opened";
        if (!sameDay && item?.isCompleted) return "complete";
        else return "current";
      }

      //For non-test user
      if (!handledIsAvailableTrackers) return "closed";
      if (sameDay) {
        if (item?.isCompleted) {
          return "opened-completed";
        } else if (item?.isClose && !item?.isCompleted) {
          return "opened-not-completed";
        } else {
          return "opened";
        }
      } else {
        if (item?.isCompleted) {
          return "complete";
        } else if (item?.isClose && !item?.isCompleted) {
          return "not-completed";
        } else if (index === handledProgress?.passedDays) {
          return "current";
        } else {
          return "closed";
        }
      }
      // if (sameDay && (item?.isCompleted || item?.isClose)) {
      //   return "opened-completed";
      // }
      // if (sameDay && !(item?.isCompleted || item?.isClose)) {
      //   return "opened";
      // } else if (!sameDay && (item?.isCompleted || item?.isClose)) {
      //   return "complete";
      // } else if (!sameDay && index === handledProgress?.passedDays) {
      //   return "current";
      // } else if (!sameDay && !(item?.isCompleted || item?.isClose)) {
      //   return "closed";
      // } else {
      //   return "closed";
      // }
    },
    [handledProgress, handledIsAvailableTrackers, user]
  );

  const onOpenDayList = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    dispatch(setIsOpenDayList(true));
  };

  const isPlaySound =
    currentDay === 0 ||
    currentDay === 6 ||
    currentDay === 13 ||
    currentDay === 20 ||
    currentDay + 1 === handledProgress?.days?.length;

  //TUTORIAL START
  const isActiveClosedDay = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.CLOSED_DAY,
    [boostcampOnboardingStep]
  );
  const isActiveOpenDay = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.OPEN_DAY,
    [boostcampOnboardingStep]
  );
  const isActiveTasks = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.TASKS,
    [boostcampOnboardingStep]
  );

  const isActiveTasksCompl = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.TASKS_COMPL,
    [boostcampOnboardingStep]
  );
  const isActiveDaily = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.DAILY,
    [boostcampOnboardingStep]
  );
  const isActiveBtnMeal = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.BTN_MEAl,
    [boostcampOnboardingStep]
  );
  const isActiveBtnVideo = useMemo(
    () => boostcampOnboardingStep?.step === BoostOnboardingStepsEnum.BTN_VIDEO,
    [boostcampOnboardingStep]
  );

  const isActiveOnboarding = useMemo(
    () =>
      showBoostcapmOnboarding &&
      (isActiveClosedDay ||
        isActiveOpenDay ||
        isActiveTasks ||
        isActiveTasksCompl ||
        isActiveDaily ||
        isActiveBtnMeal ||
        isActiveBtnVideo),
    [
      showBoostcapmOnboarding,
      isActiveClosedDay,
      isActiveOpenDay,
      isActiveTasks,
      isActiveTasksCompl,
      isActiveDaily,
      isActiveBtnMeal,
      isActiveBtnVideo,
    ]
  );

  const goToNextOnboardingStep = () => {
    if (isActiveOnboarding)
      dispatch(setBoostcampOnboardingStep(boostcampOnboardingStepIndex + 1));
    if (boostcampOnboardingStepIndex + 1 >= boostcampOnboardingSteps?.length)
      dispatch(finishOnboarding({ boostCamp: true }));
  };

  const skipOnboarding = () => {
    dispatch(finishOnboarding({ boostCamp: true }));
  };

  useEffect(() => {
    if (showBoostcapmOnboarding && !isLoading && !isCurrentProgressLoading) {
      if (width <= 1440 && width > mobileAdaptiveWidth) {
        if (isActiveOpenDay || isActiveClosedDay) {
          setIsOpenDaysMenu(true);
          document.body.style.overflowY = "hidden";
        } else {
          setIsOpenDaysMenu(false);
          document.body.style.overflowY = "auto";
        }
      } else if (width <= mobileAdaptiveWidth) {
        if (isActiveOpenDay || isActiveClosedDay) {
          dispatch(setIsOpenDayList(true));
          document.body.style.overflowY = "hidden";
        } else {
          dispatch(setIsOpenDayList(false));
          document.body.style.overflowY = "auto";
        }
      }
      if (width > mobileAdaptiveWidth) {
        dispatch(setIsOpenDayList(false));
      }
      if (width > 1440) {
        setIsOpenDaysMenu(false);
        document.body.style.overflowY = "auto";
      }
      if (width <= mobileAdaptiveWidth) {
        setIsOpenDaysMenu(false);
      }
    }
  }, [
    isLoading,
    isCurrentProgressLoading,
    isActiveOpenDay,
    isActiveClosedDay,
    width,
    showBoostcapmOnboarding,
  ]);

  useEffect(() => {
    if (showBoostcapmOnboarding) {
      dispatch(fetchOnboardingSteps("BOOST"));
    }
  }, [showBoostcapmOnboarding]);
  //TUTORIAL END

  console.log({
    handledIsAvailableTrackers,
    isClosed,
    isOpenCompleteTrackers,
    isClose: handledProgress?.days[currentDay]?.isClose,
    isCmpleted: handledProgress?.days[currentDay]?.isCompleted,
  });

  if (isLoading || isCurrentProgressLoading) {
    return (
      <div className={styles.wrapperLoader}>
        <PulseLoader color={"#8c42e3"} />
      </div>
    );
  }

  return (
    <>
      <SleepTracker
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
      />
      <WaterTracker
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
      />
      <FastingTracker
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
      />
      <WeightTracker
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
      />
      <BodyTracker
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
      />
      {isPlaySound ? (
        <CompleteDayModal isOpen={isOpenCompleteDay} setIsOpen={setIsOpenCompleteDay} />
      ) : (
        <CompleteDayWhithoutSoundModal
          isOpen={isOpenCompleteDay}
          setIsOpen={setIsOpenCompleteDay}
        />
      )}
      {isOpenDaysMenu && (
        <motion.div
          initial={{ right: "-400px", opacity: 0.5 }}
          animate={{ right: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className={styles.daysMenu}
        >
          <div onClick={closeMenuHandler} className={styles.menuWrapper} />
          <div
            className={clsx(styles.menuModal, {
              [styles.menuModalTooltip]:
                showBoostcapmOnboarding && (isActiveClosedDay || isActiveOpenDay),
            })}
          >
            {showBoostcapmOnboarding && (isActiveClosedDay || isActiveOpenDay) && (
              <div className={styles.daysMenuOverlay} />
            )}
            <div className={styles.modalHeader}>
              <div className={styles.crossBtn} onClick={closeMenuHandler}>
                <CrossIcon />
              </div>
              <p>Boost Camp Calendar</p>
            </div>
            {handledProgress?.days?.map((item, index) => {
              if (!item?.picture) {
                return;
              }
              return (
                <div
                  className={clsx(
                    styles.daysListItem,
                    showBoostcapmOnboarding &&
                      ((isActiveClosedDay && index === 0) ||
                        (isActiveOpenDay && index === 1)) &&
                      styles.daysListItemActive
                  )}
                  key={`progressDay-mobile-${index}`}
                >
                  <DayItem
                    index={index}
                    idBoostcamp={id || ""}
                    status={
                      showBoostcapmOnboarding && isActiveClosedDay && index === 0
                        ? "complete"
                        : showBoostcapmOnboarding && isActiveOpenDay && index === 1
                        ? "opened"
                        : checkDayStatus(item, index, currentDay)
                    }
                    title={item?.title}
                    description={item?.shortDescription}
                    image={item?.picture}
                    key={`${index}-${id}`}
                  />
                  {showBoostcapmOnboarding &&
                    ((isActiveClosedDay && index === 0) ||
                      (isActiveOpenDay && index === 1)) && (
                      <OnboardingTooltip
                        step={boostcampOnboardingStepIndex + 1}
                        maxSteps={boostcampOnboardingSteps.length}
                        title={boostcampOnboardingStep?.title}
                        text={boostcampOnboardingStep?.description}
                        onClickBtn={goToNextOnboardingStep}
                        className={styles.daysListItemTooltip}
                        timeout={500}
                        onClickSkip={skipOnboarding}
                      />
                    )}
                </div>
              );
            })}
          </div>
        </motion.div>
      )}

      <div
        style={{ paddingRight: isOpenDaysMenu ? "0px" : "0px" }}
        className={styles.wrapper}
      >
        {width > 1440 && (
          <div className={styles.daysList}>
            {handledProgress?.days?.map((item, index) => {
              if (!item?.picture) {
                return;
              }
              return (
                <div
                  className={clsx(
                    styles.daysListItem,
                    showBoostcapmOnboarding &&
                      ((isActiveClosedDay && index === 0) ||
                        (isActiveOpenDay && index === 1)) &&
                      styles.daysListItemActive
                  )}
                  key={`progressDay-${index}`}
                >
                  <DayItem
                    index={index}
                    idBoostcamp={id || ""}
                    status={
                      showBoostcapmOnboarding && isActiveClosedDay && index === 0
                        ? "complete"
                        : showBoostcapmOnboarding && isActiveOpenDay && index === 1
                        ? "opened"
                        : checkDayStatus(item, index, currentDay)
                    }
                    title={item?.title}
                    description={item?.shortDescription}
                    image={item?.picture}
                    key={`${index}-${id}`}
                  />
                  {showBoostcapmOnboarding &&
                    ((isActiveClosedDay && index === 0) ||
                      (isActiveOpenDay && index === 1)) && (
                      <OnboardingTooltip
                        step={boostcampOnboardingStepIndex + 1}
                        maxSteps={boostcampOnboardingSteps.length}
                        title={boostcampOnboardingStep?.title}
                        text={boostcampOnboardingStep?.description}
                        onClickBtn={goToNextOnboardingStep}
                        className={styles.daysListItemTooltip}
                        onClickSkip={skipOnboarding}
                      />
                    )}
                </div>
              );
            })}
          </div>
        )}
        <div className={styles.boostcampContent}>
          <div className={styles.boostcampHeader}>
            {!handledIsAvailableTrackers && (
              <>
                <Countdown
                  handledProgress={handledProgress}
                  handledIsAvailableTrackers={handledIsAvailableTrackers}
                />
                <p className={styles.timerTitle}>
                  This is the countdown to start your Boostcamp. Enjoy!
                </p>
              </>
            )}
            <h2 className={styles.dayTitle}>
              {handledProgress?.days[currentDay]?.title}
            </h2>
            <div className={"html-wrapper"}>
              <div
                className={styles.daySubtitle}
                dangerouslySetInnerHTML={{
                  __html: handledProgress?.days[currentDay]?.shortDescription || "",
                }}
              />
            </div>
            {/*<p className={styles.daySubtitle}>*/}
            {/*  {`${handledProgress?.days[currentDay]?.shortDescription}`}*/}
            {/*</p>*/}
            <img
              src={handledProgress?.days[currentDay]?.picture}
              className={styles.dayPreview}
              alt={"preview"}
            />
          </div>
          <div className={clsx(styles.dayOverviewWrapper)}>
            {!handledIsAvailableTrackers && <CloseDayIcon className={styles.blockIcon} />}
            <div className={"html-wrapper"}>
              <div
                className={clsx(styles.dayOverview, {
                  [styles.blurWrapper]: !handledIsAvailableTrackers,
                })}
                dangerouslySetInnerHTML={{
                  __html: handledProgress?.days[currentDay]?.fullDescription || "",
                }}
              />
            </div>
            {/*<p className={styles.dayOverview}>*/}
            {/*  {handledProgress?.days[currentDay]?.fullDescription}*/}
            {/*</p>*/}
            <div className={styles.completeDayBtns}>
              {width > mobileAdaptiveWidth && (
                <div className={styles.completeDayBtnWrapper}>
                  <Button
                    title="Complete Day"
                    styleType="filled"
                    size="large"
                    onClick={() => onCompleteDay("task")}
                    loading={isLoadingComplete}
                    disabled={isCompleted || isOpenCompleteTrackers ? true : false}
                    backgroundColor={
                      isCompleted || isOpenCompleteTrackers ? "#7c8691" : "#8c42e3"
                    }
                  />
                  {isOpenCompleteTrackersTask && (
                    <CompleteTrackersPopUp goToTrackers={goToTrackers} />
                  )}
                </div>
              )}
              {currentDay + 1 === handledProgress?.daysNumber &&
                daysProgress >= handledProgress?.daysNumber && (
                  <div className={styles.completeDayBtnWrapper}>
                    <Button
                      title="Complete Boostcamp"
                      styleType="filled"
                      size="large"
                      onClick={() => onCompleteBoostcamp()}
                      loading={isLoadingCompleteBoost}
                      disabled={handledProgress?.isCompleted ? true : false}
                      backgroundColor={
                        handledProgress?.isCompleted ? "#7c8691" : "#8c42e3"
                      }
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className={styles.tasksList}>
          {width <= 1440 && width > mobileAdaptiveWidth && (
            <div onClick={openMenuHandler} className={styles.openDayBtn}>
              <img
                src={handledProgress?.days[currentDay]?.picture}
                className={styles.dayImg}
              />
              <p className={styles.dayTitle}>
                {handledProgress?.days[currentDay]?.title}
              </p>
              <p className={styles.changeDayBtn}>change day</p>
              <CalendarColorIcon />
            </div>
          )}
          {width > mobileAdaptiveWidth && (
            <div className={styles.tasksListContainer}>
              <h3 className={styles.tasksListTitle}>TRACKERS</h3>
              <DailyTaskSetItem
                icon={<ChecklistIcon />}
                title="Current weight"
                color={"#60C05533"}
                btnColor={"#60C055"}
                onClick={() => dispatch(setIsOpenWeightTracker(true))}
                value={handledProgress?.days[currentDay]?.trackers || null}
                type={"weight"}
                infoPosition={"bottom"}
                currentProgress={currentProgress}
                currentIsAvailableTracker={currentIsAvailableTracker}
                auto
              />
              <DailyTaskSetItem
                icon={<img src={Fasting} className={styles.fasting} />}
                title="Fasting window"
                color={"#F0512233"}
                btnColor={"#F05122"}
                onClick={() => dispatch(setIsOpenFastingTracker(true))}
                value={handledProgress?.days[currentDay]?.trackers || null}
                type={"fasting"}
                currentProgress={currentProgress}
                currentIsAvailableTracker={currentIsAvailableTracker}
                auto
              />
              <DailyTaskSetItem
                icon={<WaterIcon />}
                title="Water intake"
                color={"#79DCD533"}
                btnColor={"#79DCD5"}
                onClick={() => dispatch(setIsOpenWaterTracker(true))}
                value={handledProgress?.days[currentDay]?.trackers || null}
                type={"water"}
                currentProgress={currentProgress}
                currentIsAvailableTracker={currentIsAvailableTracker}
                auto
              />
              {/* <DailyTaskSetItem
            icon={<BodyIcon />}
            title="What’s your proportion today?"
            color={"#FCD93033"}
            btnColor={"#FCD930"}
            onClick={() => dispatch(setIsOpenBodyTracker(true))}
            value={handledProgress?.days[currentDay]?.trackers || null}
            type={"body"}
          /> */}
              <DailyTaskSetItem
                icon={<SleepIcon />}
                title="Sleep tracker"
                color={"#8C42E326"}
                btnColor={"#8C42E3"}
                onClick={() => dispatch(setIsOpenSleepTracker(true))}
                value={handledProgress?.days[currentDay]?.trackers || null}
                type={"sleep"}
                currentProgress={currentProgress}
                currentIsAvailableTracker={currentIsAvailableTracker}
                auto
              />
            </div>
          )}
          <div
            className={clsx(styles.checklistContainer, {
              [styles.checklistContainerActive]:
                showBoostcapmOnboarding && (isActiveTasks || isActiveTasksCompl),
            })}
          >
            <ChecklistTasks
              tasks={handledProgress?.days[currentDay]?.tasks}
              onSetActiveTask={onSetTask}
              onCompleteDay={() => onCompleteDay("info")}
              isLoading={isLoadingComplete}
              isOpenCompleteTrackers={isOpenCompleteTrackers}
              goToTrackers={goToTrackers}
              tutorial={showBoostcapmOnboarding && (isActiveTasks || isActiveTasksCompl)}
              currentIsAvailableTracker={currentIsAvailableTracker}
              isClosed={isClosed}
              isCompleted={isCompleted}
            />
            {showBoostcapmOnboarding && (isActiveTasks || isActiveTasksCompl) && (
              <OnboardingTooltip
                step={boostcampOnboardingStepIndex + 1}
                maxSteps={boostcampOnboardingSteps.length}
                title={boostcampOnboardingStep?.title}
                text={boostcampOnboardingStep?.description}
                onClickBtn={goToNextOnboardingStep}
                className={clsx(styles.checklistContainerTooltip, {
                  [styles.checklistContainerTooltipFirst]:
                    showBoostcapmOnboarding && isActiveTasks,
                  [styles.checklistContainerTooltipSecond]:
                    showBoostcapmOnboarding && isActiveTasksCompl,
                })}
                onClickSkip={skipOnboarding}
              />
            )}
          </div>
          {boostcamp?.days[currentDay]?.remainders && (
            <div
              className={clsx(
                styles.dailyRemindersContainer,
                showBoostcapmOnboarding &&
                  isActiveDaily &&
                  styles.dailyRemindersContainerActive
              )}
            >
              <DailyReminders
                remainders={boostcamp?.days[currentDay]?.remainders}
                tutorial={showBoostcapmOnboarding && isActiveDaily}
                handledIsAvailableTrackers={handledIsAvailableTrackers}
              />
              {showBoostcapmOnboarding && isActiveDaily && (
                <OnboardingTooltip
                  step={boostcampOnboardingStepIndex + 1}
                  maxSteps={boostcampOnboardingSteps.length}
                  title={boostcampOnboardingStep?.title}
                  text={boostcampOnboardingStep?.description}
                  onClickBtn={goToNextOnboardingStep}
                  className={styles.dailyRemindersContainerTooltip}
                  onClickSkip={skipOnboarding}
                />
              )}
            </div>
          )}
          {boostcamp?.mealPlan && (
            <div
              className={clsx(
                styles.btnMeal,
                showBoostcapmOnboarding && isActiveBtnMeal && styles.btnMealActive
              )}
            >
              <Button
                styleType="filled"
                size="medium"
                title="Meal Plan"
                backgroundColor="#60C055"
                leftIcon={<CalendarIcon style={{ width: "26px", height: "auto" }} />}
                onClick={() => {
                  navigate(`/meal-plan/${handledProgress?.id}`);
                  // setIsOpenMealPlan(true);
                  // setIsOpenReceips(true);
                }}
                style={{
                  marginBottom: width <= mobileAdaptiveWidth ? "10px" : "20px",
                  backgroundColor: "#60C055",
                }}
              />
              {showBoostcapmOnboarding && isActiveBtnMeal && (
                <OnboardingTooltip
                  step={boostcampOnboardingStepIndex + 1}
                  maxSteps={boostcampOnboardingSteps.length}
                  title={boostcampOnboardingStep?.title}
                  text={boostcampOnboardingStep?.description}
                  onClickBtn={goToNextOnboardingStep}
                  className={clsx(
                    styles.btnMealTooltip,
                    width <= mobileAdaptiveWidth && styles.btnMealTooltipMob
                  )}
                  onClickSkip={skipOnboarding}
                />
              )}
            </div>
          )}
          <div
            className={clsx(
              styles.btnVideo,
              showBoostcapmOnboarding && isActiveBtnVideo && styles.btnVideoActive
            )}
          >
            <Button
              styleType="filled"
              size="medium"
              title="Masterclass Videos"
              backgroundColor="#F05122"
              leftIcon={<SaladIcon />}
              onClick={() => {
                window.open(
                  "https://www.youtube.com/playlist?list=PLQXZMZXR3Phk_O5_MO6xT0B3cImAy3ki7",
                  "_blank",
                  "noreferrer"
                );
              }}
            />
            {showBoostcapmOnboarding && isActiveBtnVideo && (
              <OnboardingTooltip
                step={boostcampOnboardingStepIndex + 1}
                maxSteps={boostcampOnboardingSteps.length}
                title={boostcampOnboardingStep?.title}
                text={boostcampOnboardingStep?.description}
                onClickBtn={goToNextOnboardingStep}
                className={styles.btnVideoTooltip}
                onClickSkip={skipOnboarding}
              />
            )}
          </div>
          {boostcamp?.prices[0]?.link && (
            <>
              <div className={styles.divider} />
              <Button
                styleType="filled"
                size="medium"
                title="Our Community"
                backgroundColor="#4064AC"
                rightIcon={<FacebookIcon />}
                onClick={() =>
                  window.open(boostcamp?.prices[0]?.link, "_blank", "noopener,noreferrer")
                }
              />
            </>
          )}
        </div>
      </div>
      {width <= mobileAdaptiveWidth && !isOpenTrackerList && !isOpenDayList && (
        <div className={styles.stickyMenu}>
          <StickyTrackerButton onClick={onOpenTrackerList} />
          <StickyDayButton setIsOpen={() => onOpenDayList()} />
        </div>
      )}
      <DaysList
        currentProgress={currentProgress}
        currentIsAvailableTracker={currentIsAvailableTracker}
      />
      <TrackerListMenu
        currentProgress={currentProgress}
        setCurrentProgress={setCurrentProgress}
        currentIsAvailableTracker={currentIsAvailableTracker}
      />

      <ReceipsModal
        isOpen={isOpenReceips}
        setIsOpen={setIsOpenReceips}
        receipes={boostcamp?.recipes}
        startDate={handledProgress?.startDate || new Date()}
        isMealPlan={isOpenMealPlan}
      />
    </>
  );
};

export default withMainLayout(ProgressBoostcamp, true, true, true, "boostcamp");
