import React, {
  DetailedHTMLProps,
  FC,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
} from "react";
import styles from "./styles.module.scss";

interface IProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

const SignLayout = ({ children }: IProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>Welcome to Boost Camp</h1>
      </header>
      <main className={styles.main}>{children}</main>
    </div>
  );
};

const withSignLayout = <T extends Record<string, unknown>>(
  Component: FunctionComponent
) => {
  return function withMainLayoutComponent(props: T) {
    return (
      <SignLayout>
        <Component />
      </SignLayout>
    );
  };
};

export default withSignLayout;
