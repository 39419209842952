import { AppDispatch } from "../store";
import { getMyActiveProgress } from "../../services/progress.service";
import { progressSlice } from "../reducers/ProgressSlice";

export const fetchProgress = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(progressSlice.actions.progressFetching());
    const { error, result } = await getMyActiveProgress();
    if (error) {
      dispatch(
        progressSlice.actions.progressFetchingError(
          error.message || "Something went wrong"
        )
      );
    }
    if (!error && result?.status === 200 && result?.data?.data?.id) {
      dispatch(progressSlice.actions.progressFetchingSuccess(result.data.data || null));
    } else {
      dispatch(progressSlice.actions.progressFetchingError("Something went wrong"));
    }
  } catch (e) {
    dispatch(progressSlice.actions.progressFetchingError("Something went wrong"));
  }
};
