import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "../../localStorage";

const Private = () => {
  const token = getToken();

  return token ? <Outlet /> : <Navigate to={"/signup"} />;
};

export default Private;
