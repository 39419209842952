import React, { FC, memo, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import Button from "../Button";
import { Line } from "rc-progress";
import {
  BlockIcon,
  CalendarIcon,
  CheckBigIcon,
  HourglassIcon,
  LockIcon,
  StartBigIcon,
} from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { IBoostcamp } from "../../models/IBoostcamp";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useWindowDimensions } from "../../hooks";

interface IProps {
  progress: "finished" | "inprogress" | "blocked" | "forbuy";
  isFirst: boolean;
  isLast: boolean;
  data: IBoostcamp;
  isOnlyOne?: boolean;
}
interface ITimeLeft {
  days?: number;
  hour?: number;
  minutes?: number;
  seconds?: number;
}
const BoostcampPreview: FC<IProps> = ({
  progress = "finished",
  data,
  isFirst = false,
  isLast = false,
  isOnlyOne = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goToBoostcamp = () => {
    console.log("goToBoostcamp", progress);
    if (progress === "inprogress") {
      navigate(`/boostcamp/progress/${data.id}/0`);
    } else if (progress === "forbuy") {
      navigate(`/preview/${data?.id}`);
    } else if (progress === "finished") {
      navigate(`/boostcamp/progress/${data.id}/0`, {
        state: { isCompleted: true },
      });
    }
  };

  const [totalPoints, setTotalPoints] = useState(0);

  const { isAvailableTrackers } = useAppSelector((state) => state.processReducer);
  const { statistics } = useAppSelector((state) => state.statisticsReducer);
  const { width } = useWindowDimensions();

  useEffect(() => {
    let total = 0;
    if (data?.days && data?.days?.length > 0) {
      data?.days.forEach((day) => day?.tasks?.forEach((el) => (total += +el?.points)));
      setTotalPoints(total);
    }
  }, [progress]);

  const iconWidth = width <= 768 ? "14px" : "auto";

  const renderCurrentIcon = () => {
    if (progress === "finished") {
      return <CheckBigIcon style={{ width: iconWidth }} />;
    }
    if (progress === "inprogress") {
      return <HourglassIcon style={{ width: iconWidth }} />;
    }
    if (progress === "blocked") {
      return <LockIcon style={{ width: iconWidth }} />;
    }
    if (progress === "forbuy") {
      return <StartBigIcon style={{ width: iconWidth }} />;
    }
  };

  const currentColorIcon =
    progress === "finished"
      ? "#60C055"
      : progress === "inprogress"
      ? "#F05122"
      : progress === "blocked"
      ? "#AFAFAF"
      : "#ee3758";

  const currentColorOpacity =
    progress === "finished"
      ? "#60C05533"
      : progress === "inprogress"
      ? "#F0512233"
      : progress === "blocked"
      ? "#AFAFAF33"
      : "#ee375833";

  const forBuyContent = (): JSX.Element => {
    return (
      <div className={styles.contentWrapperFinished}>
        <div className={styles.header}></div>
        <div className={styles.descriptionWrapper}>
          <div className={styles.calendarWrapper}>
            <CalendarIcon />
            <p>{data.daysNumber} days</p>
          </div>
          <h4>{data?.title}</h4>
          <Button
            title="REVIEW & BUY BOOST CAMP"
            styleType="filled"
            size={width <= 768 ? "x-small" : "small"}
            backgroundColor="#EE3758"
          />
        </div>
      </div>
    );
  };

  const finishedContent = (): JSX.Element => {
    return (
      <div className={styles.contentWrapperFinished}>
        <div className={styles.header}>
          <Button
            title="FINISHED"
            styleType="filled"
            size={width <= 768 ? "mobile" : "x-small"}
            backgroundColor="#60C055"
          />
        </div>
        <div className={styles.descriptionWrapper}>
          <div className={styles.calendarWrapper}>
            <CalendarIcon />
            <p>{data.daysNumber} days</p>
          </div>
          <h4>{data?.title}</h4>
          <Button
            title="REVIEW BOOST CAMP"
            styleType="filled"
            size={width <= 768 ? "x-small" : "small"}
            backgroundColor="#60C055"
          />
        </div>
      </div>
    );
  };

  const inProgressContent = (): JSX.Element => {
    return (
      <div className={styles.contentWrapperInProgress}>
        <div className={styles.header}>
          <div className={styles.progressWrapper}>
            <Line
              percent={statistics ? (statistics?.points * 100) / totalPoints : 0}
              strokeWidth={width <= 768 ? 5 : 2}
              trailWidth={width <= 768 ? 5 : 2}
              trailColor="#E4E4E4"
              strokeColor="#F05122"
            />
          </div>
          <p>
            {statistics?.points || 0}/{totalPoints}
          </p>
        </div>
        <div className={styles.descriptionWrapper}>
          <div className={styles.calendarWrapper}>
            <CalendarIcon />
            <p>{data.daysNumber} days</p>
          </div>
          <h4>{data?.title}</h4>
          {isAvailableTrackers ? (
            <Button
              title="CONTINUE BOOST CAMP"
              styleType="filled"
              size={width <= 768 ? "x-small" : "small"}
              backgroundColor="#F05122"
            />
          ) : (
            <Timer />
          )}
        </div>
      </div>
    );
  };

  const blockedContent = (): JSX.Element => {
    return (
      <div className={styles.contentWrapperBlocked}>
        <div className={styles.blockIcon}>
          <BlockIcon style={{ width: width <= 768 ? "50px" : "auto" }} />
        </div>
        <div className={styles.header}>
          <Button
            title="CLOSED"
            styleType="filled"
            size={width <= 768 ? "mobile" : "x-small"}
            backgroundColor="#131313"
          />
        </div>
        <div className={styles.descriptionWrapper}>
          <div className={styles.calendarWrapper}>
            <CalendarIcon />
            <p>{data?.daysNumber} days</p>
          </div>
          <h4>{data?.title}</h4>
        </div>
      </div>
    );
  };

  return (
    <div className={clsx(styles.blockWrapper)}>
      <div className={styles.progressLine}>
        {!isOnlyOne && (
          <>
            <div
              style={{
                backgroundColor: isFirst
                  ? "transparent"
                  : progress === "blocked"
                  ? "#AFAFAF33"
                  : currentColorIcon,
              }}
              className={styles.firstLine}
            />

            <div
              style={{
                backgroundColor: isLast
                  ? "transparent"
                  : progress === "finished"
                  ? currentColorIcon
                  : "#AFAFAF33",
              }}
              className={styles.secondLine}
            />
          </>
        )}

        <div
          style={{ backgroundColor: currentColorOpacity }}
          className={styles.iconContainer}
        >
          <div
            style={{ backgroundColor: currentColorIcon }}
            className={styles.iconInnerContainer}
          >
            {renderCurrentIcon()}
          </div>
        </div>
      </div>
      <div onClick={goToBoostcamp} className={styles.blockContent}>
        <div className={styles.wrapper}>
          <img
            className={clsx(styles.preview, {
              [styles.blur]: progress === "blocked",
            })}
            src={data?.picture}
            alt={"preview"}
          />
          {progress === "blocked" && blockedContent()}
          {progress === "finished" && finishedContent()}
          {progress === "inprogress" && inProgressContent()}
          {progress === "forbuy" && forBuyContent()}
        </div>
        {progress === "inprogress" && (
          <p className={styles.subtitle}>
            Finish current boost camp to be able to move further
          </p>
        )}
      </div>
    </div>
  );
};

const Timer = memo(function TimerComponent() {
  const progressBoostcamp = useAppSelector((state) => state.processReducer.progress);
  const { isAvailableTrackers } = useAppSelector((state) => state.processReducer);

  const [timeLeft, setTimeLeft] = useState<ITimeLeft>();
  const calculateTimeLeft = (startDate: string) => {
    const date = new Date(startDate);
    date.setHours(0, 0, 0, 0);

    let difference = +new Date(date) - +new Date();

    let timeLeft: ITimeLeft = { days: 0, hour: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    setTimeLeft(timeLeft);
  };

  useEffect(() => {
    let interval: any = null;
    if (!isAvailableTrackers) {
      calculateTimeLeft(`${progressBoostcamp?.startDate}`);
      interval = setInterval(() => {
        calculateTimeLeft(`${progressBoostcamp?.startDate}`);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isAvailableTrackers, progressBoostcamp]);

  return (
    <div className={styles.timerWrapper}>
      <div className={styles.wrapperItem}>
        <div className={styles.blockItem}>
          <h4 className={styles.time}>{timeLeft?.days}</h4>
          <p className={styles.title}>DAYS</p>
        </div>
        <div className={styles.divider} />
        <div className={styles.blockItem}>
          <h4 className={styles.time}>{timeLeft?.hour}</h4>
          <p className={styles.title}>HOURS</p>
        </div>
        <div className={styles.divider} />

        <div className={styles.blockItem}>
          <h4 className={styles.time}>{timeLeft?.minutes}</h4>
          <p className={styles.title}>MINUTES</p>
        </div>
      </div>
    </div>
  );
});

export default BoostcampPreview;
