import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotification } from "../../models/INotification";

interface INotificationState {
  notifications: Array<INotification>;
  showIndicator: boolean;
}

const initialState: INotificationState = {
  notifications: [],
  showIndicator: false,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Array<INotification>>) => {
      state.notifications = action.payload;
    },
    setNewNotification: (state, action: PayloadAction<INotification>) => {
      state.notifications = [action.payload, ...state.notifications];
      state.showIndicator = true;
    },
    setIsShowIndication: (state, action: PayloadAction<boolean>) => {
      state.showIndicator = action.payload;
    },
    clearNotification: (state) => {
      state.notifications = [];
    },
  },
});

export const {
  setNotifications,
  setNewNotification,
  setIsShowIndication,
  clearNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
