import { GET, POST } from "../API/axios/index";
import { AxiosError } from "axios";

interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}

export const getMyBoostcamps = (page: number, limit: number = 30): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`boost-camps/my?page=${page}&limit=${limit}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getAllBoostcamps = (page: number, limit: number): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`boost-camps?page=${page}&limit=${limit}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getBoostcamp = (id: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`boost-camps/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getBoostcampMeal = (id: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`boost-camps/${id}/meal`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getMealPlan = (id: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`meal-plans/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
export const getMealPlanDay = (id: string, day: number): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`meal-plans/${id}/chunked/${day}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getShoppingList = (id: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`shopping-lists/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getRecipe = (id: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`recipes/${id}`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const buyBoostcamp = (
  boostcampId: string,
  startDate: any,
  currency: "usd" | "aud",
  printRecipe: boolean = false,
  stripeToken?: string
): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body: {
      boostCamp: string;
      startDate: any;
      currency: "usd" | "aud";
      printRecipe: boolean;
      stripeToken?: string;
    } = {
      boostCamp: boostcampId,
      startDate,
      currency,
      printRecipe,
    };
    if (stripeToken) {
      body.stripeToken = stripeToken;
    }

    POST("progress", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const buyBoostcampTest = (boostcampId: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      boostCamp: boostcampId,
    };

    POST("progress/test-mode", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const getBoughtBoostcamps = (): Promise<IResponse> => {
  console.log("getBoughtBoostcamps service");

  return new Promise((resolve, reject) => {
    GET("boost-camps/bought")
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
