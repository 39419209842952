import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { withSignLayout } from "../../layouts";
import { Input, Progress, Button, Checkbox } from "../../components";
import styles from "./styles.module.scss";
import { NavLink, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux";
import { useNavigate, useLocation } from "react-router-dom";
import { signUp } from "../../services/auth.service";
import { setToken } from "../../localStorage";
import { setUser, setToken as setTokenStore } from "../../store/reducers/UserSlice";

const SignUp: FC = (): JSX.Element => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [params] = useSearchParams();
  //errorsState
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");

  const [signUpError, setSignUpError] = useState<string>("");

  // const { selectedMonday } = useAppSelector((state) => state.paymentsReducer);

  const location = useLocation();

  const selectedMonday = location?.state?.selectedMonday;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onChangeFirstName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }, []);

  const onChangeLastName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }, []);

  const onChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const onChangeConfirmPassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  }, []);

  const onSignUp = async () => {
    try {
      if (firstName.length < 2) {
        setFirstNameError("First Name is required");
        return;
      } else {
        setFirstNameError("");
      }
      if (lastName.length < 2) {
        setLastNameError("Last Name is required");
        return;
      } else {
        setLastNameError("");
      }
      if (email.length < 2) {
        setEmailError("Email is required");
        return;
      } else {
        setEmailError("");
      }
      if (password.length < 2) {
        setPasswordError("Password is required");
        return;
      } else {
        setPasswordError("");
      }
      if (confirmPassword !== password) {
        setConfirmPasswordError("passwords must match");
        return;
      } else {
        setConfirmPasswordError("");
      }

      setIsLoading(true);
      const code = params?.get("code");
      const { error, result } = await signUp(
        email,
        password,
        firstName,
        lastName,
        code || ""
      );
      if (!error && result?.status === 201) {
        if (result?.data?.data?.isVerified) {
          setToken(result?.data.data?.token);
          dispatch(setTokenStore(result?.data.data.token));
          dispatch(setUser(result?.data?.data.user));
          if (selectedMonday) {
            navigate("/signup-buy", {
              state: { fromSignUp: true, selectedMonday: selectedMonday },
            });
          } else {
            navigate("/home");
          }

          setSignUpError("");
        } else {
          navigate("/email-verification", {
            state: {
              email: result?.data.data?.email,
              fromSignUp: true,
              selectedMonday: selectedMonday,
            },
          });
        }
      } else {
        // @ts-ignore  -- HOT FIX
        setSignUpError(error?.response?.data?.message || "Something went wrong");
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {selectedMonday && <Progress currentStep={1} maxStep={3} />}
      <div className={styles.navigationBtnWrapper}>
        <NavLink
          key={"signup"}
          to={"/signup"}
          state={selectedMonday ? { selectedMonday: selectedMonday } : {}}
        >
          New Member
        </NavLink>
        <NavLink
          key={"login"}
          to={"/login"}
          state={selectedMonday ? { selectedMonday: selectedMonday } : {}}
        >
          I have an account
        </NavLink>
      </div>
      <div className={styles.flexRow}>
        <Input
          label="First Name"
          value={firstName}
          onChange={onChangeFirstName}
          placeholder={"Enter First Name"}
          error={firstNameError}
        />
        <Input
          label="Last Name"
          value={lastName}
          onChange={onChangeLastName}
          placeholder={"Enter Last Name"}
          error={lastNameError}
        />
      </div>
      <Input
        label="Email Address"
        value={email}
        onChange={onChangeEmail}
        placeholder={"Enter Email Address"}
        type={"email"}
        error={emailError}
      />
      <Input
        label="Password"
        value={password}
        onChange={onChangePassword}
        placeholder={"Enter Password"}
        type={"password"}
        error={passwordError}
      />
      <Input
        label="Repeat Password"
        value={confirmPassword}
        onChange={onChangeConfirmPassword}
        placeholder={"Repeat Password"}
        type={"password"}
        error={confirmPasswordError}
      />
      <div className={styles.privacyWrapper}>
        <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} />
        <p>
          I agree with{" "}
          <a
            href="https://bridgets.shop/pages/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://bridgets.shop/pages/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
      {signUpError && <div className={styles.error}>{signUpError}</div>}
      <Button
        title="Proceed to Payment"
        styleType="filled"
        loading={isLoading}
        size={"medium"}
        disabled={!isChecked}
        onClick={onSignUp}
      />
    </div>
  );
};

export default withSignLayout(SignUp);
