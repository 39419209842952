import { IProgress, IProgressDay } from "../models/IProgress";

export const updateProgressDay = (
  prev: IProgress | null,
  result: any,
  isCompleted: boolean | null = null
): IProgress | null => {
  const newProgress = prev
    ? {
        ...prev,
        days: prev?.days
          ? prev.days.map((d) =>
              d.id === result?.data?.data?.id ? result?.data?.data : d
            )
          : [],
        isCompleted: isCompleted != null ? isCompleted : prev.isCompleted,
      }
    : null;

  return newProgress;
};

export const reduceCompletedDays = (days: IProgressDay[] | undefined) => {
  const sumWithInitial: number = days
    ? days.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.isCompleted ? 1 : 0),
        0
      )
    : 0;

  return sumWithInitial;
};
