import React, { FC, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import Modal from "react-modal";
import Button from "../Button";
import { Line } from "rc-progress";
import Lottie from "react-lottie-player";
import animation from "../../assets/animations/complete.json";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchProgress } from "../../store/actions/ProgressActions";

interface IProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

const CompleteDayModal: FC<IProps> = ({ isOpen, setIsOpen }): JSX.Element => {
  const { progress, currentDay, maxPoints, currentPoints } = useAppSelector(
    (state) => state.processReducer
  );

  let audio = useRef<any>(null);

  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(fetchProgress());
    setIsOpen(false);
  };

  const playSound = () => {
    audio.current = new Audio(require("../../assets/sounds/complete.mp3"));
    audio.current.volume = 0.1;
    audio.current.loop = true;
    audio.current?.play();
  };

  useEffect(() => {
    if (isOpen) {
      playSound();
    }

    return () => {
      audio.current?.pause();
    };
  }, [isOpen]);

  return (
    <Modal
      style={{
        overlay: {
          background: "rgba(255, 255, 255, 0.95)",
          backdropFilter: "blur(6.5px)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          outline: "none",
          backgroundColor: "transparent",
          overflow: "hidden",
          border: "none",
        },
      }}
      ariaHideApp={false}
      isOpen={isOpen}
    >
      <div className={styles.contentBox}>
        <div className={styles.contentContainer}>
          <Lottie
            loop
            animationData={animation}
            play
            style={{ width: 200, height: 250 }}
          />
          <h3 className={styles.completeTitle}>Congratulations!</h3>
          <h2 className={styles.points}>+{currentPoints} points</h2>
          <div className={styles.progressWrapper}>
            <div className={styles.progressLineContainer}>
              <Line
                percent={(currentDay * 100) / (progress?.daysNumber || 1)}
                strokeWidth={3.2}
                trailWidth={3.2}
                trailColor="#E4E4E4"
                strokeColor="#8C42E3"
              />
            </div>
            <p>
              {currentDay + 1}/{progress?.daysNumber || 0}
            </p>
          </div>
          <p className={styles.description}>
            Complete everyday tasks, collect points and{" "}
            <span>
              get 20% <br /> discount
            </span>{" "}
            on the next Boost Camp.
          </p>
          <div className={styles.buttonWrapper}>
            <Button
              title="Continue"
              size="medium"
              styleType="filled"
              onClick={onCloseModal}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteDayModal;
