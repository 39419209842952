import React, { FC, useState, useCallback, ChangeEvent, useEffect } from "react";
import { ArrowLeftIcon, PenIcon } from "../../assets/icons";
import { withMainLayout } from "../../layouts";
import { Button, Input, SuccessModal } from "../../components";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { editUser } from "../../services/user.service";
import { setUser } from "../../store/reducers/UserSlice";
import avatar from "../../assets/images/avatar.png";
import { uploadFile } from "../../services/file.service";
import { MoonLoader } from "react-spinners";
import { useWindowDimensions } from "../../hooks";

interface IProps {}

const EditProfile: FC<IProps> = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  // const [location, setLocation] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUploadPhoto, setIsLoadingUploadPhoto] = useState<boolean>(false);
  const [preview, setPreview] = useState<string>(user?.picture || "");
  const [isShowSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  const onChangeFirstName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  }, []);

  const onChangeLastName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  }, []);
  const onChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);
  // const onChangeLocation = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   setLocation(e.target.value);
  // }, []);

  const onEditUser = async () => {
    try {
      setIsLoading(true);
      console.log(user);
      if (!user?.id) {
        alert("Something went wrong");
        return;
      }

      const { error, result } = await editUser(
        {
          email,
          firstName,
          lastName,
          // location,
          picture: preview,
        },
        user.id
      );
      if (error) {
        alert(error?.message || "Something went wrong");
      } else if (result?.status === 202) {
        setShowSuccessModal(true);
        dispatch(setUser(result?.data?.data));
        setTimeout(() => setShowSuccessModal(false), 2000);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }
      setIsLoadingUploadPhoto(true);
      const { error, result } = await uploadFile(e.target.files[0]);
      if (!error && result?.status === 201) {
        setPreview(result?.data?.data?.url || "");
      }
      setIsLoadingUploadPhoto(false);
    } catch (error) {
      setIsLoadingUploadPhoto(false);

      console.log(error);
    }
  };

  useEffect(() => {
    setFirstName(user?.firstName || "");
    setLastName(user?.lastName || "");
    setEmail(user?.email || "");
    // setLocation(user?.location || "");
  }, [user]);

  return (
    <>
      {isShowSuccessModal && <SuccessModal title="Successfully updated!" />}
      <div className={styles.wrapper}>
        <div className={styles.blockMenu}>
          <div onClick={() => navigate("/profile")} className={styles.blockHeaderBtn}>
            <ArrowLeftIcon />
            <img src={user?.picture || avatar} className={styles.imgPreview} />
            <p className={styles.backtitle}>Back to profile</p>
          </div>
          {/* <Button
            title="Account"
            styleType={"filled"}
            size={width <= 768 ? "small" : "medium"}
            textPosition={"flex-start"}
          />
          <Button
            title="Password"
            styleType={"filled"}
            backgroundColor={"#fff"}
            color={"#3A3A3A"}
            size={width <= 768 ? "small" : "medium"}
            textPosition={"flex-start"}
            isShowBorder={false}
          />
          <Button
            title="Notification Settings"
            styleType={"filled"}
            backgroundColor={"#fff"}
            color={"#3A3A3A"}
            size={width <= 768 ? "small" : "medium"}
            isShowBorder={false}
            textPosition={"flex-start"}
          />
          <Button
            title="Privacy Settings"
            styleType={"filled"}
            backgroundColor={"#fff"}
            color={"#3A3A3A"}
            isShowBorder={false}
            size={width <= 768 ? "small" : "medium"}
            textPosition={"flex-start"}
          /> */}
        </div>
        <div className={styles.settingsBlock}>
          <h4 className={styles.title}>Account</h4>
          <div className={styles.contentContainer}>
            <label htmlFor={"photoInput"} className={styles.previewBig}>
              <input
                id={"photoInput"}
                type={"file"}
                accept="image/*"
                onChange={onSelectFile}
                onClick={(e: any) => (e.target.value = "")}
                className={styles.inputPhoto}
              />
              {isLoadingUploadPhoto ? (
                <div className={styles.previewBig}>
                  <MoonLoader size={40} color={"#8C42E3"} />
                </div>
              ) : (
                <img src={preview || avatar} className={styles.previewBig} />
              )}
              <div className={styles.penIcon}>
                <PenIcon />
              </div>
            </label>
            <div className={styles.inputContainer}>
              <Input
                label="First Name"
                value={firstName}
                onChange={onChangeFirstName}
                placeholder={"Marie"}
              />
              <Input
                label="Last Name"
                value={lastName}
                onChange={onChangeLastName}
                placeholder={"Patterson"}
              />
              <Input
                label="Email"
                value={email}
                onChange={onChangeEmail}
                placeholder={"example@gmail.com"}
              />
              {/* <Input
                label="Location"
                value={location}
                onChange={onChangeLocation}
                placeholder={"Los-Angeles, CA"}
              /> */}
              <div className={styles.savebtnWrapper}>
                <Button
                  title={"Save changes"}
                  size={"medium"}
                  styleType={"filled"}
                  onClick={onEditUser}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withMainLayout(EditProfile, false, true);
