import React, { FC, useState, useEffect, useRef, useMemo } from "react";
import {
  JcbIcon,
  AmericanexpressIcon,
  LockIcon,
  MastecardIcon,
  StripeIcon,
  VisaIcon,
  DinnerIcon,
  UnionIcon,
  DiscoverIcon,
  UnitedStatesIcon,
  AustlariaIcon,
} from "../../assets/icons";
import { Progress, Checkbox } from "../index";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { format } from "date-fns";
import { useOutsideClick, useWindowDimensions } from "../../hooks";
import { buyBoostcamp, getBoughtBoostcamps } from "../../services/boostcamps.service";
import ClipLoader from "react-spinners/ClipLoader";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  clearSelectedMonday,
  clearSelectedPrice,
} from "../../store/reducers/PaymentsSlice";
import { setStartDate } from "../../store/reducers/ProgressSlice";
import { setCurrency } from "../../store/reducers/UserSlice";
import { getLocation } from "../../utils/location";
import { AUD_COUNTRIES } from "../../pages/Preview";

dayjs.extend(dayOfYear);

interface IProps {
  setIsPaySuccess: (val: boolean) => void;
  fromSignUp: boolean;
  selectedMonday: string;
}

const BuyBoostcamp: FC<IProps> = ({
  fromSignUp,
  setIsPaySuccess,
  selectedMonday,
}): JSX.Element => {
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { selectedBoostcamp, selectedPrice } = useAppSelector(
    (state) => state.paymentsReducer
  );

  const [countryItems, setCountryItems] = useState([
    { icon: <UnitedStatesIcon />, title: "USD" },
    { icon: <AustlariaIcon />, title: "AUD" },
  ]);
  const [isCountryListOpen, setIsCountryListOpen] = useState<boolean>(false);
  const { currency } = useAppSelector((state) => state.userReducer);
  const [activeCountry, setActiveCountry] = useState<number>(currency === "USD" ? 0 : 1);
  const [country, setCountry] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const currencyRef = useRef<any>(null);

  useEffect(() => {
    dispatch(setCurrency(activeCountry === 0 ? "USD" : "AUD"));
  }, [activeCountry]);
  const { width } = useWindowDimensions();
  useOutsideClick(currencyRef, () => {
    setIsCountryListOpen(false);
  });
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useAppDispatch();

  const checkIsUserBuyBoostcamp = async (): Promise<{
    success: boolean;
    error: string;
  }> => {
    return new Promise((resolve, reject) => {
      getBoughtBoostcamps()
        .then(({ error, result }) => {
          console.log("results", result);
          if (error) {
            reject(error);
          } else if (!error && result?.data) {
            const isBuyed = result?.data?.data?.bought?.includes(selectedBoostcamp?.id);
            const isActive = result?.data?.data?.active;

            if (isBuyed) {
              reject({
                success: false,
                error: "You have already purchased this boostcamp",
              });
            } else if (isActive) {
              reject({ success: false, error: "You already have active boostcamp" });
            }
            if (!isBuyed && !isActive) {
              resolve({ success: true, error: "" });
            }
          }
        })
        .catch((e) => reject(e));
    });
  };

  const onBuyBoostcamp = async () => {
    try {
      setIsLoadingPayment(true);

      // @ts-ignore  -- this is needed due to a bug in the type defs for createToken?
      const { token } = await stripe?.createToken(
        // @ts-ignore  -- this is needed due to a bug in the type defs for createToken?
        elements?.getElement(CardElement)
      );

      const selectedDates = selectedMonday
        ? JSON.parse(JSON.parse(selectedMonday))
        : selectedMonday;

      const date = {
        year: new Date(selectedDates).getFullYear(),
        date: new Date(selectedDates).getDate(),
        month: new Date(selectedDates).getMonth(),
      };

      if (token?.id && selectedMonday && selectedBoostcamp?.id) {
        const res = await checkIsUserBuyBoostcamp();

        if (res?.success) {
          const { error, result } = await buyBoostcamp(
            selectedBoostcamp.id,
            date,
            activeCountry === 0 ? "usd" : "aud",
            isChecked,
            token.id
          );
          if (!error && result?.status === 201) {
            if (result?.data?.data?.boostCamp) {
              dispatch(setStartDate(JSON.parse(selectedMonday)));
              dispatch(clearSelectedMonday());
              dispatch(clearSelectedPrice());
              setIsPaySuccess(true);
              setError("");
            } else {
              setError(
                result?.data?.data.decline_code ||
                  "Something went wrong. Please check your data and try again"
              );
            }
          }
        }
      }

      setIsLoadingPayment(false);
    } catch (error: any) {
      setIsLoadingPayment(false);
      //@ts-ignore
      setError(
        error?.error || "Something went wrong. Please check your data and try again"
      );
      console.log("error", error);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#3a3a3a",
        fontFamily: '"Gilroy", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#7c8691",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true,
  };

  const getCountry = () => {
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        const res = await getLocation(
          `${position.coords.latitude},${position.coords.longitude}`
        );

        const filterdResult = res?.results.map((address: any) => {
          // const filtered = address?.address_components?.filter((item: any) =>
          //   item?.types.includes("country")
          // );
          const country = address?.address_components?.filter((item: any) =>
            item?.types.includes("country")
          );
          if (country?.length > 0) return country[0];
          return;
        });
        console.log("filterdResult", filterdResult);

        const addressCountryCode = filterdResult?.find(
          (address: any) => !!address?.short_name
        )?.short_name;
        console.log("addressCountryCode", addressCountryCode);

        setCountry(addressCountryCode);
      },
      (error) => {
        console.log("geolocation error", error);
      }
    );
  };

  useEffect(() => {
    if (!country) {
      getCountry();
    } else {
      console.log("country", country);
      if (AUD_COUNTRIES?.includes(country)) {
        setActiveCountry(1);
      } else {
        setActiveCountry(0);
      }
    }
  }, [country]);

  const calcSelectedPrice = useMemo(
    () =>
      selectedBoostcamp?.printRecipe?.price && isChecked
        ? selectedPrice + selectedBoostcamp?.printRecipe?.price
        : selectedPrice,
    [selectedPrice, selectedBoostcamp, isChecked]
  );

  return (
    <div className={styles.wrapper}>
      <Progress currentStep={fromSignUp ? 3 : 2} maxStep={fromSignUp ? 3 : 2} />
      <div className={styles.header}>
        <h4>Your Payment Details</h4>
        <div
          ref={currencyRef}
          // onClick={() => setIsCountryListOpen((prev) => !prev)}
          className={styles.selectCountry}
        >
          {countryItems[activeCountry].icon}
          <span>{countryItems[activeCountry].title}</span>
          {/* <ArrowDownIcon
            style={{
              transform: isCountryListOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.2s linear",
            }}
          />
          {isCountryListOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              transition={{ duration: 0.2 }}
              className={styles.countryList}
            >
              {countryItems.map((item, index) => {
                return (
                  <div
                    onClick={() => setActiveCountry(index)}
                    className={styles.selectCountry}
                  >
                    {countryItems[index].icon}
                    <span>{countryItems[index].title}</span>
                  </div>
                );
              })}
            </motion.div>
          )} */}
        </div>
      </div>

      <CardElement options={CARD_ELEMENT_OPTIONS} />

      <div className={styles.divider} />
      <div className={styles.boostcampItem}>
        <img src={selectedBoostcamp?.picture} className={styles.boostcampImg} />
        <div className={styles.boostcampContent}>
          <h5 className={styles.dateTitle}>
            {selectedMonday &&
              format(
                new Date(JSON.parse(JSON.parse(selectedMonday))),
                "EEEE, MMMM, d, yyyy"
              )}
          </h5>

          <h5>{selectedBoostcamp?.title}</h5>
          <p>
            {selectedMonday &&
              format(new Date(JSON.parse(JSON.parse(selectedMonday))), "MMM yyyy")}{" "}
            Boost Camp + eBook
          </p>
          {width <= 768 && (
            <h4>
              $
              {
                // currency === "USD" ? (selectedPrice * 0.627818).toFixed(1) :
                calcSelectedPrice
              }
            </h4>
          )}
        </div>
        {width > 768 && (
          <h4>
            $
            {
              // currency === "USD" ? (selectedPrice * 0.627818).toFixed(1) :
              calcSelectedPrice
            }
          </h4>
        )}
      </div>

      {selectedBoostcamp?.printRecipe && (
        <div className={styles.checkBoxWrapper}>
          <div className={styles.checkbox_header}>
            <Checkbox isChecked={isChecked} setIsChecked={setIsChecked} />
            <div className={styles.checkbox_headerContent}>
              {selectedBoostcamp?.printRecipe?.title || "Include Print Recipe feature"}
              {/* <p className={styles.checkbox_headerContentTitle}>
              </p> */}
            </div>
          </div>
          <div className={styles.checkbox_footer}>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedBoostcamp?.printRecipe?.text || "",
              }}
            />
          </div>
        </div>
      )}

      {error && <p className={styles.error}>{error}</p>}
      <div onClick={onBuyBoostcamp} className={styles.startBtn}>
        {isLoadingPayment ? <ClipLoader color={"#fff"} /> : <p>I’M IN. LET’S START!</p>}
      </div>
      <div className={styles.footer}>
        <div className={styles.stripeBlock}>
          <LockIcon />
          <p>
            Guaranteed <span>safe and secure</span> checkout
          </p>
          <StripeIcon />
        </div>
        <div className={styles.paymentMehods}>
          <VisaIcon />
          <MastecardIcon />
          <AmericanexpressIcon />
          <JcbIcon />
          <DinnerIcon />
          <UnionIcon />
          <DiscoverIcon />
        </div>
        <p>
          <span>We hate SPAM</span> and promise to keep your email address safe.
        </p>
      </div>
    </div>
  );
};

export default BuyBoostcamp;
