import { useEffect } from "react";

const useOutsideClick = (ref: any, callback: () => void) => {
  const handleOutsideClick = (event: any) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (!path.includes(ref.current)) {
      callback();
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);
    return () => document.body.removeEventListener("click", handleOutsideClick);
    // eslint-disable-next-line
  }, []);
};

export default useOutsideClick;
