import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { withMainLayout } from "../../layouts";
import {
  Counter,
  FaqItem,
  ReviewItem,
  Button,
  BuyBoostcampModal,
} from "../../components";
import styles from "./styles.module.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  AmericanexpressIcon,
  ArrowDownIcon,
  AustlariaIcon,
  CalendarColorIcon,
  CheckBigIcon,
  ClockIcon,
  DinnerIcon,
  DiscoverIcon,
  InfoIcon,
  JcbIcon,
  LockIcon,
  MastecardIcon,
  MessageIcon,
  SmileyIcon,
  StripeIcon,
  UnionIcon,
  UnitedStatesIcon,
  UserIcon,
  VisaIcon,
} from "../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { BeatLoader, ClipLoader } from "react-spinners";
import { fetchBoostcampForPayments } from "../../store/actions/PaymentsAction";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import clsx from "clsx";
import { useOutsideClick, useWindowDimensions } from "../../hooks";
import { format, formatISO } from "date-fns";
import {
  clearSelectedMonday,
  clearSelectedPrice,
  setSelectedBoostcamp,
  setSelectedMonday,
  setSelectedPrice,
} from "../../store/reducers/PaymentsSlice";
import {
  buyBoostcamp,
  buyBoostcampTest,
  getBoostcamp,
  getBoughtBoostcamps,
} from "../../services/boostcamps.service";
import { getToken } from "../../localStorage";
import { getMyAllProgress } from "../../services/progress.service";
import { motion } from "framer-motion";
import { setCurrency } from "../../store/reducers/UserSlice";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { getLocation } from "../../utils/location";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import { setStartDate } from "../../store/reducers/ProgressSlice";
dayjs.extend(dayOfYear);

export const AUD_COUNTRIES = ["NZ", "AU"];

const Preview: FC = (): JSX.Element => {
  const [price, setPrice] = useState<number>(0);
  const [fullPrice, setFullPrice] = useState<number>(0);
  const [isShowFullOverview, setIsShowFullOverview] = useState<boolean>(false);
  const [mondaysList, setMondaysList] = useState<any>([]);
  const [isOpenMondayList, setIsOpenModaysList] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenBuyModal, setIsOpenBuyModal] = useState<boolean>(false);
  const [myAllProgress, setMyAllProgress] = useState<any>("");
  const [isPointsDiscountActive, setIsPointsDiscountActive] = useState<boolean>(false);
  const [isFriendsDiscountActive, setIsFriendsDiscountActive] = useState<boolean>(false);
  const [currentInfoText, setCurrentInfoText] = useState<string>("");
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  const [countryItems, setCountryItems] = useState([
    { icon: <UnitedStatesIcon />, title: "USD" },
    { icon: <AustlariaIcon />, title: "AUD" },
  ]);
  const [activeCountry, setActiveCountry] = useState<number>(0);
  const [isCountryListOpen, setIsCountryListOpen] = useState<boolean>(false);
  const [myAllProgresLoading, setMyAllProgressLoading] = useState<boolean>(false);
  const [isBought, setIsBought] = useState<boolean>(false);
  const [boughtStartDate, setBoughtStartDate] = useState<Date>(new Date());
  const [isActiveNow, setIsActiveNow] = useState<boolean>(false);
  const [isMysteryBoxAvailable, setIsMysteryBoxAvailable] = useState<boolean>(false);
  const [country, setCountry] = useState<string | null>(null);

  const { selectedMonday, selectedBoostcamp } = useAppSelector(
    (state) => state.paymentsReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const { progress } = useAppSelector((state) => state.processReducer);

  const { currency } = useAppSelector((state) => state.userReducer);
  const { statistics } = useAppSelector((state) => state.statisticsReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoadingPaymentTest, setIsLoadingPaymentTest] = useState<boolean>(false);
  const [errorTest, setErrorTest] = useState<string>("");

  const token = getToken();

  useEffect(() => {
    if (statistics?.mysteryBox?.startDate && statistics?.mysteryBox?.endDate) {
      const today = new Date();
      const startDateDiscount = new Date(statistics?.mysteryBox?.startDate);
      const endDateDiscount = new Date(statistics?.mysteryBox?.endDate);

      //Disable mistery box
      // if (
      //   today > startDateDiscount &&
      //   today < endDateDiscount &&
      //   searchParams.get("useMysterybox")
      // ) {
      //   setIsMysteryBoxAvailable(true);
      // }
    } else {
      setIsMysteryBoxAvailable(false);
    }
  }, [statistics, searchParams]);

  const currencyRef = useRef<any>(null);
  const priceBlockRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   setActiveCountry(currency === "USD" ? 0 : 1);
  // }, [currency]);

  useEffect(() => {
    dispatch(setCurrency(activeCountry === 0 ? "USD" : "AUD"));
  }, [activeCountry]);

  useEffect(() => {
    if (!isPointsDiscountActive && !isFriendsDiscountActive) {
      setCurrentInfoText("");
    } else if (isPointsDiscountActive && !isFriendsDiscountActive) {
      setCurrentInfoText(
        "The price is shown with a discount for points from the previous Boostcamp."
      );
    } else if (!isPointsDiscountActive && isFriendsDiscountActive) {
      setCurrentInfoText("The price is shown with a discount for invited friends.");
    } else if (isPointsDiscountActive && isFriendsDiscountActive) {
      setCurrentInfoText(
        "The price is shown with a discount for points from the previous Boostcamp and invited friends."
      );
    }
  }, [isPointsDiscountActive, isFriendsDiscountActive]);

  const { width, height } = useWindowDimensions();

  const mondaysRef = useRef(null);
  const params = useParams();

  // console.log("searchParams", searchParams.get("useMysterybox"));

  const checkIsBoostcampBought = async () => {
    try {
      const { error, result } = await getBoughtBoostcamps();
      if (!error && result?.data) {
        if (result?.data?.data?.active === params?.id) {
          setIsActiveNow(true);
        }
        const isBuyed = result?.data?.data?.bought?.includes(params?.id);

        if (isBuyed) {
          setIsBought(true);
        } else {
          setIsBought(false);
        }
      } else {
        setIsBought(false);
      }
    } catch (error) {
      setIsBought(false);
      console.log(error);
    }
  };
  function getNextDayOfTheWeek(
    dayName: any,
    excludeToday = true,
    refDate = new Date()
  ): Date {
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
      dayName.slice(0, 3).toLowerCase()
    );
    if (dayOfWeek < 0) return refDate;
    refDate.setHours(0, 0, 0, 0);
    refDate.setDate(
      refDate.getDate() +
        +!!excludeToday +
        ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
    );
    return refDate;
  }

  const getNext8Monday = (isFirst: boolean, initialDate = new Date()) => {
    let arr = [];
    let refDate: any = new Date(initialDate);
    for (let i = 0; i < 4; i++) {
      refDate = getNextDayOfTheWeek("Monday", i > 0 ? true : false, refDate);
      arr.push(refDate && new Date(refDate));
    }
    console.log("arr", arr);
    const copy = arr.map((item, index) => ({
      time: item,
      price: calculatePriceHandler(item, initialDate),
    }));
    const copyFiltered = copy.filter((item) => !!item.price);

    if ((isFirst || !selectedMonday) && copyFiltered?.length > 0) {
      dispatch(setSelectedMonday(JSON.stringify(copyFiltered[0].time)));
    }
    setMondaysList(copyFiltered);
  };

  const getMyAllProg = async () => {
    try {
      setMyAllProgressLoading(true);
      const { error, result } = await getMyAllProgress();
      if (!error && result?.data) {
        const sortedProgress = result?.data?.data?.rows?.sort(
          (a: any, b: any) =>
            +new Date(b?.updatedAt || new Date()) - +new Date(a?.updatedAt || new Date())
        );

        const index = result?.data?.data?.rows?.findIndex(
          (el: any) => el?.id === params?.id
        );
        if (index !== -1) {
          setBoughtStartDate(result?.data?.data?.rows[index].startDate);
        }
        setMyAllProgress(sortedProgress);
      } else {
        setMyAllProgress([]);
      }
      setMyAllProgressLoading(false);
    } catch (error) {
      setMyAllProgress([]);
      setMyAllProgressLoading(false);
      console.log(error);
    }
  };

  const calculatePriceHandler = (startDate: Date, todayDate = new Date()) => {
    const firstDate = new Date(startDate);
    const today = new Date(todayDate);

    function dateDiffInDays(a: Date, b: Date) {
      const MS_PER_DAY = 1000 * 60 * 60 * 24;
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.ceil((utc2 - utc1) / MS_PER_DAY / 7);
    }

    let weeksBetweenDates = 0;
    if (dayjs(firstDate).dayOfYear() !== dayjs(today).dayOfYear()) {
      weeksBetweenDates = dateDiffInDays(today, firstDate);

      if (weeksBetweenDates === 0 && [1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates = 1;
      }
      if (weeksBetweenDates > 0 && ![1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates -= 1;
      }
    }

    const currentPrice = selectedBoostcamp?.prices[weeksBetweenDates];

    console.log({ currentPrice });

    if (!currentPrice?.discount?.discountPrice && !currentPrice?.price) return;

    let price = currentPrice?.discount?.discountPrice || currentPrice?.price || 0;

    if (myAllProgress[0]?.points >= 300) {
      price = Math.ceil(price - price * 0.2);
      setIsPointsDiscountActive(true);
    }

    if (user && user?.referralPoints > 0) {
      setIsFriendsDiscountActive(true);
    }

    if (user && user?.referralPoints > price) {
      price = 5;
    } else if (user) {
      price = price - user?.referralPoints;
    }

    let fullPrice = currentPrice?.price || 0;

    // if (currency === "USD") {
    //   price = +(price * 0.627818).toFixed(1);
    //   fullPrice = +(fullPrice * 0.627818).toFixed(1);
    // }

    if (isMysteryBoxAvailable) {
      price = price / 2;
    }

    return price;
  };

  const calculatePrice = (startDate: Date, todayDate = new Date()) => {
    if (userVipAndFreeBoost) {
      dispatch(setSelectedPrice(0));
      setPrice(0);
      setFullPrice(0);
      return;
    }
    const firstDate = new Date(startDate);
    const today = new Date(todayDate);

    function dateDiffInDays(a: Date, b: Date) {
      const MS_PER_DAY = 1000 * 60 * 60 * 24;
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.ceil((utc2 - utc1) / MS_PER_DAY / 7);
    }

    let weeksBetweenDates = 0;
    if (dayjs(firstDate).dayOfYear() !== dayjs(today).dayOfYear()) {
      weeksBetweenDates = dateDiffInDays(today, firstDate);

      if (weeksBetweenDates === 0 && [1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates = 1;
      }
      if (weeksBetweenDates > 0 && ![1, 2, 3].includes(dayjs(today).day())) {
        weeksBetweenDates -= 1;
      }
    }

    const currentPrice = selectedBoostcamp?.prices[weeksBetweenDates];
    let price = currentPrice?.discount?.discountPrice || currentPrice?.price || 0;

    // console.log({ selectedBoostcamp });
    // console.log({ currentPrice });

    if (myAllProgress[0]?.points >= 300) {
      price = Math.ceil(price - price * 0.2);
      setIsPointsDiscountActive(true);
    }

    if (user && user?.referralPoints > 0) {
      setIsFriendsDiscountActive(true);
    }

    if (user && user?.referralPoints > price) {
      price = 5;
    } else if (user) {
      price = price - user?.referralPoints;
    }

    let fullPrice = currentPrice?.price || 0;

    dispatch(setSelectedPrice(+price));

    // if (currency === "USD") {
    //   price = +(price * 0.627818).toFixed(1);
    //   fullPrice = +(fullPrice * 0.627818).toFixed(1);
    // }
    if (isMysteryBoxAvailable) {
      price = price / 2;
    }
    setPrice(+price);
    setFullPrice(+fullPrice);
    return price;
  };

  useOutsideClick(mondaysRef, () => {
    setIsOpenModaysList(false);
  });

  useOutsideClick(currencyRef, () => {
    setIsCountryListOpen(false);
  });

  const onGetBoostcamp = async () => {
    try {
      setIsLoading(true);
      const { error, result } = await getBoostcamp(params?.id || "");
      if (!error && result?.data) {
        dispatch(setSelectedBoostcamp(result?.data?.data));
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const checkIsUserBuyBoostcamp = async (): Promise<{
    success: boolean;
    error: string;
  }> => {
    return new Promise((resolve, reject) => {
      getBoughtBoostcamps()
        .then(({ error, result }) => {
          if (error) {
            reject(error);
          } else if (!error && result?.data) {
            const isBuyed = result?.data?.data?.bought?.includes(selectedBoostcamp?.id);
            const isActive = result?.data?.data?.active;
            if (isBuyed) {
              reject({
                success: false,
                error: "You have already purchased this boostcamp",
              });
            } else if (isActive) {
              reject({ success: false, error: "You already have active boostcamp" });
            }
            if (!isBuyed && !isActive) {
              resolve({ success: true, error: "" });
            }
          }
        })
        .catch((e) => reject(e));
    });
  };

  const onBuyBoostcampTest = async () => {
    try {
      setIsLoadingPaymentTest(true);
      const res = await checkIsUserBuyBoostcamp();
      if (res?.success && selectedBoostcamp?.id) {
        const { error, result } = await buyBoostcampTest(selectedBoostcamp.id);
        if (!error && result?.status === 201) {
          if (result?.data?.data?.boostCamp) {
            dispatch(setStartDate(result?.data?.data?.startDate || new Date()));
            dispatch(clearSelectedMonday());
            dispatch(clearSelectedPrice());
            navigate("/intro");
            setErrorTest("");
          } else {
            setErrorTest(
              result?.data?.data.decline_code ||
                "Something went wrong. Please check your data and try again"
            );
          }
        }
      }

      setIsLoadingPaymentTest(false);
    } catch (error: any) {
      setIsLoadingPaymentTest(false);
      //@ts-ignore
      setErrorTest(
        error?.error || "Something went wrong. Please check your data and try again"
      );
      console.log("error", error);
    }
  };
  const onBuyBoostcampVip = async () => {
    try {
      setIsLoadingPaymentTest(true);
      const res = await checkIsUserBuyBoostcamp();
      if (selectedMonday && res?.success && selectedBoostcamp?.id) {
        const selectedDates = selectedMonday
          ? JSON.parse(selectedMonday)
          : selectedMonday;

        const date = {
          year: new Date(selectedDates).getFullYear(),
          date: new Date(selectedDates).getDate(),
          month: new Date(selectedDates).getMonth(),
        };

        const { error, result } = await buyBoostcamp(
          selectedBoostcamp.id,
          date,
          activeCountry === 0 ? "usd" : "aud",
          true
        );
        if (!error && result?.status === 201) {
          if (result?.data?.data?.boostCamp) {
            dispatch(setStartDate(selectedMonday));
            dispatch(clearSelectedMonday());
            dispatch(clearSelectedPrice());

            navigate("/intro");
            setErrorTest("");
          } else {
            setErrorTest(
              result?.data?.data.decline_code ||
                "Something went wrong. Please check your data and try again"
            );
          }
        }
      }

      setIsLoadingPaymentTest(false);
    } catch (error: any) {
      setIsLoadingPaymentTest(false);
      //@ts-ignore
      setErrorTest(
        error?.error || "Something went wrong. Please check your data and try again"
      );
      console.log("error", error);
    }
  };

  const findedBoostcamp = useMemo(
    () =>
      user?.vip?.boostCamps &&
      user?.vip?.boostCamps?.find((b) => b.boostCamp === params?.id),
    [user, params]
  );

  const userVipAndFreeBoost = useMemo(
    () => user?.vip?.status && findedBoostcamp?.free,
    [user, findedBoostcamp]
  );

  const goToSign = () => {
    const token = getToken();

    if (token) {
      if (user?.testMode) {
        onBuyBoostcampTest();
      } else if (userVipAndFreeBoost) {
        onBuyBoostcampVip();
      } else {
        setIsOpenBuyModal(true);
      }
    } else {
      navigate("/signup", { state: { selectedMonday: JSON.stringify(selectedMonday) } });
    }
  };

  useEffect(() => {
    getMyAllProg();
  }, []);

  useEffect(() => {
    checkIsBoostcampBought();
  }, []);

  console.log({ selectedMonday });

  useEffect(() => {
    console.log(selectedBoostcamp && selectedMonday && myAllProgress);
    console.log({ selectedBoostcamp, selectedMonday, myAllProgress, progress });

    if (selectedBoostcamp && selectedMonday && myAllProgress) {
      if (progress) {
        let startDate = new Date(progress?.startDate);

        startDate.setDate(startDate.getDate() + progress?.daysNumber);

        calculatePrice(JSON.parse(selectedMonday), startDate);
      } else {
        calculatePrice(JSON.parse(selectedMonday));
      }
    }
  }, [selectedBoostcamp, selectedMonday, myAllProgress, currency, progress]);

  useEffect(() => {
    if (myAllProgress) {
      if (progress) {
        console.log("progress?.startDate", progress?.startDate);

        let startDate = new Date(progress?.startDate);

        startDate.setDate(startDate.getDate() + progress?.daysNumber);
        getNext8Monday(true, startDate);
      } else {
        getNext8Monday(true);
      }
    }
  }, [selectedBoostcamp, currency, myAllProgress, progress]);

  // useEffect(() => {
  //    myAllProgress && getNext8Monday(false);
  // }, [currency, myAllProgress, progress]);

  useEffect(() => {
    // dispatch(fetchBoostcampForPayments());
    onGetBoostcamp();
  }, []);

  useEffect(() => {
    if (isOpenMondayList) {
      priceBlockRef.current?.scrollTo(0, 10000);
    }
  }, [isOpenMondayList]);

  const getCountry = () => {
    navigator.geolocation.getCurrentPosition(
      async function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        const res = await getLocation(
          `${position.coords.latitude},${position.coords.longitude}`
        );

        const filterdResult = res?.results.map((address: any) => {
          // const filtered = address?.address_components?.filter((item: any) =>
          //   item?.types.includes("country")
          // );
          const country = address?.address_components?.filter((item: any) =>
            item?.types.includes("country")
          );
          if (country?.length > 0) return country[0];
          return;
        });
        console.log("filterdResult", filterdResult);

        const addressCountryCode = filterdResult?.find(
          (address: any) => !!address?.short_name
        )?.short_name;
        console.log("addressCountryCode", addressCountryCode);

        setCountry(addressCountryCode);
      },
      (error) => {
        console.log("geolocation error", error);
      }
    );
  };

  useEffect(() => {
    if (!country) {
      getCountry();
    } else {
      console.log("country", country);
      if (AUD_COUNTRIES?.includes(country)) {
        setActiveCountry(1);
      } else {
        setActiveCountry(0);
      }
    }
  }, [country]);

  if (isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <BeatLoader color="#8c42e3" />
      </div>
    );
  }

  return (
    <>
      <div
        className={clsx(styles.wrapper, {
          [styles.wrapperNotToken]: !token,
        })}
      >
        <div className={styles.contentContainer}>
          <div
            className={clsx(styles.leftContainer, {
              [styles.leftContainerBought]: isBought,
            })}
          >
            {width > 768 && (
              <div className={styles.blockItem}>
                <h3 className={styles.boostcampTitle}>{selectedBoostcamp?.title}</h3>
                <div className={"html-wrapper"}>
                  <div
                    className={styles.shortOverview}
                    dangerouslySetInnerHTML={{
                      __html: selectedBoostcamp?.description || "",
                    }}
                  />
                </div>
                <div className={styles.flexRow}>
                  <div className={styles.iconBlock}>
                    <div className={styles.iconWrapper}>
                      <ClockIcon />
                    </div>
                    <div className={styles.iconBlockContent}>
                      <p className={styles.iconBlockTitle}>
                        {selectedBoostcamp?.daysNumber} Days
                      </p>
                      <p className={styles.iconBlockSubtitle}>Transformation</p>
                    </div>
                  </div>
                  <div className={styles.iconBlock}>
                    <div className={styles.iconWrapper}>
                      <SmileyIcon />
                    </div>
                    <div className={styles.iconBlockContent}>
                      <p className={styles.iconBlockTitle}>Restaurant Quality Meals</p>
                      <p className={styles.iconBlockSubtitle}>
                        designed for deliciousness and health
                      </p>
                    </div>
                  </div>
                  <div className={styles.iconBlock}>
                    <div className={styles.iconWrapper}>
                      <MessageIcon />
                    </div>
                    <div className={styles.iconBlockContent}>
                      <p className={styles.iconBlockTitle}>Daily Support</p>
                      <p className={styles.iconBlockSubtitle}>
                        Tips, Tricks, How To Videos
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={clsx(styles.blockItem, styles.gradientBlock)}>
              <h3 className={styles.blockTitle}>Full Overview</h3>
              <div className={"html-wrapper"}>
                <div
                  className={styles.shortOverview}
                  dangerouslySetInnerHTML={{
                    __html: selectedBoostcamp?.overview || "",
                  }}
                />
              </div>

              {/*<p className={styles.shortOverview}>*/}
              {/*  {isShowFullOverview*/}
              {/*    ? selectedBoostcamp?.overview*/}
              {/*    : selectedBoostcamp?.overview?.slice(0, 350)}*/}
              {/*</p>*/}
              {/*{!isShowFullOverview && <div className={styles.opacity} />}*/}

              {/*<div className={styles.readMoreBtnWrapper}>*/}
              {/*  <Button*/}
              {/*    title={isShowFullOverview ? "Show Less" : "Read More"}*/}
              {/*    styleType={"outlined"}*/}
              {/*    size={"small"}*/}
              {/*    rightIcon={<ArrowDownIcon />}*/}
              {/*    color={"#8c42e3"}*/}
              {/*    onClick={() => setIsShowFullOverview((prev) => !prev)}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <div className={styles.blockItem}>
              <h3 style={{ marginBottom: "20px" }} className={styles.blockTitle}>
                Frequently Asked Questions
              </h3>
              {!!selectedBoostcamp?.faq?.length &&
                selectedBoostcamp?.faq?.map((item: any, index: number) => (
                  <FaqItem key={index} question={item?.question} answer={item?.answer} />
                ))}
            </div>
            {!!selectedBoostcamp?.reviews?.length &&
              (width <= 768 ? (
                <div className={styles.reviewMobileBlock}>
                  <h3 style={{ marginBottom: "20px" }} className={styles.blockTitle}>
                    Reviews
                  </h3>
                  <Swiper
                    slidesPerView={1.1}
                    centeredSlides={false}
                    spaceBetween={15}
                    navigation={true}
                    pagination={{
                      clickable: true,
                      type: "bullets",
                    }}
                    uniqueNavElements={true}
                    modules={[Pagination]}
                    className="mySwiper"
                    initialSlide={1}
                    breakpoints={{
                      320: {
                        slidesPerView: 1.1,
                      },
                      560: {
                        slidesPerView: 2.2,
                      },
                    }}
                  >
                    {selectedBoostcamp?.reviews.map((item, index) => (
                      <SwiperSlide
                        key={index}
                        style={{
                          height: "auto",
                          minHeight: 300,
                        }}
                      >
                        {({ isActive }) => {
                          return (
                            <ReviewItem
                              avatar={item?.avatar}
                              city={item?.city}
                              name={item?.name}
                              rating={item?.rating}
                              text={item?.text}
                              isMobile={width <= 768}
                              key={index}
                            />
                          );
                        }}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              ) : (
                <div className={styles.blockItem}>
                  <h3 style={{ marginBottom: "20px" }} className={styles.blockTitle}>
                    Reviews
                  </h3>

                  {selectedBoostcamp?.reviews?.map((item: any, index: number) => (
                    <ReviewItem
                      avatar={item?.avatar}
                      city={item?.city}
                      name={item?.name}
                      rating={item?.rating}
                      text={item?.text}
                      key={index}
                    />
                  ))}
                </div>
              ))}
          </div>

          <div
            ref={priceBlockRef}
            className={clsx(styles.rightContainer, {
              [styles.rightContainerToken]: token,
              [styles.rightContainerBought]: isBought,
            })}
          >
            <div className={styles.priceBlockWrapper}>
              {width <= 768 && (
                <h3 className={styles.mobileTitle}>{selectedBoostcamp?.title}</h3>
              )}
              <img src={selectedBoostcamp?.picture} className={styles.img} />
              {isBought ? (
                <>
                  {boughtStartDate && (
                    <h4 className={styles.boughtStartDate}>
                      Start Date:{" "}
                      <span>
                        {format(
                          new Date(
                            formatISO(new Date(`${boughtStartDate}`.slice(0, -1)), {
                              representation: "date",
                            })
                          ),
                          "d/MM/yyyy"
                        )}
                      </span>
                    </h4>
                  )}
                  <div
                    onClick={() => {
                      if (isActiveNow) {
                        navigate(`/boostcamp/progress/${params?.id}/0`, {
                          state: { isBought: true },
                        });
                      } else {
                        navigate(`/boostcamp/progress/${params?.id}/0`, {
                          state: { isCompleted: true },
                        });
                      }
                    }}
                    className={styles.checkDaysBtn}
                  >
                    <img src={selectedBoostcamp?.picture} className={styles.smallImg} />
                    <span>Check completed days</span>
                    <CalendarColorIcon />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.priceContainer}>
                    <span className={styles.currentPrice}>
                      ${price}
                      {currentInfoText && (
                        <div
                          onMouseOver={() => setIsInfoOpen(true)}
                          onMouseLeave={() => setIsInfoOpen(false)}
                          className={styles.infoBtn}
                        >
                          <InfoIcon />
                          {isInfoOpen && (
                            <motion.div
                              initial={{ scale: 0.5 }}
                              animate={{ scale: 1 }}
                              transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                              }}
                              className={styles.infoBlock}
                            >
                              {currentInfoText}
                            </motion.div>
                          )}
                        </div>
                      )}
                    </span>
                    {price != fullPrice && (
                      <span className={styles.discoutPrice}>${fullPrice}</span>
                    )}
                    <div
                      ref={currencyRef}
                      // onClick={() => setIsCountryListOpen((prev) => !prev)}
                      className={styles.selectCountry}
                    >
                      {countryItems[activeCountry].icon}
                      <span>{countryItems[activeCountry].title}</span>
                      {/* <ArrowDownIcon
                        style={{
                          transform: isCountryListOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "all 0.2s linear",
                        }}
                      />
                      {isCountryListOpen && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: "auto", opacity: 1 }}
                          transition={{ duration: 0.2 }}
                          className={styles.countryList}
                        >
                          {countryItems.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => setActiveCountry(index)}
                                className={styles.selectCountry}
                              >
                                {countryItems[index].icon}
                                <span>{countryItems[index].title}</span>
                              </div>
                            );
                          })}
                        </motion.div>
                      )} */}
                    </div>
                  </div>

                  {price != fullPrice && (
                    <div className={styles.timerWrapper}>
                      <p>Save ${(fullPrice - price).toFixed(1)}!!! Limited offer only:</p>
                      <div className={styles.counterWrapper}>
                        <Counter />
                      </div>
                    </div>
                  )}
                  {!user?.testMode && (
                    <>
                      <p className={styles.selectedLabel}>Choose a Start Date</p>
                      <div
                        ref={mondaysRef}
                        className={clsx(styles.selectMondayBtn, {
                          [styles.selectMondayBtnOpen]: isOpenMondayList,
                          [styles.selectMondayBtnDisabled]: user?.testMode,
                        })}
                        onClick={() => setIsOpenModaysList((prev) => !prev)}
                      >
                        <p className={styles.mondayTitle}>
                          {selectedMonday &&
                            format(
                              new Date(JSON.parse(selectedMonday)),
                              "EEEE, MMMM, d, yyyy"
                            )}
                        </p>
                        <span className={styles.mondayPrice}>${price}</span>

                        {!user?.testMode && (
                          <ArrowDownIcon style={{ marginLeft: "10px" }} />
                        )}
                        {isOpenMondayList && (
                          <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            transition={{ duration: 0.2 }}
                            className={styles.modayListWrapper}
                          >
                            {mondaysList?.map((item: any, index: number) => {
                              return (
                                <div
                                  onClick={() => {
                                    dispatch(
                                      setSelectedMonday(JSON.stringify(item.time))
                                    );
                                    // setIsOpenModaysList(false);
                                  }}
                                  className={clsx(styles.selectMondayBtn, {
                                    [styles.selectMondayBtnActive]:
                                      item.time === selectedMonday,
                                  })}
                                  key={index}
                                >
                                  <p className={clsx(styles.mondayTitle)}>
                                    {format(new Date(item.time), "EEEE, MMMM, d, yyyy")}
                                  </p>
                                  <span className={styles.mondayPrice}>
                                    ${userVipAndFreeBoost ? "0" : item?.price}
                                  </span>
                                  {item.time === selectedMonday && <CheckBigIcon />}
                                </div>
                              );
                            })}
                          </motion.div>
                        )}
                      </div>
                    </>
                  )}
                  <div onClick={goToSign} className={styles.signBtn}>
                    {isLoadingPaymentTest ? (
                      <ClipLoader color={"#fff"} />
                    ) : userVipAndFreeBoost ? (
                      "Free"
                    ) : (
                      "Buy"
                    )}
                  </div>
                  {errorTest && <p className={styles.error}>{errorTest}</p>}
                  <div className={styles.stripeBlock}>
                    <LockIcon />
                    <p>
                      Guaranteed safe and secure
                      <br /> checkout
                    </p>
                    <StripeIcon />
                  </div>
                  <div className={styles.paymentMehods}>
                    <VisaIcon />
                    <MastecardIcon />
                    <AmericanexpressIcon />
                    <JcbIcon />
                    <DinnerIcon />
                    <UnionIcon />
                    <DiscoverIcon />
                  </div>
                </>
              )}

              {width <= 768 && (
                <div className={styles.mobileShortDescription}>
                  <div className={"html-wrapper"}>
                    <div
                      className={styles.shortOverview}
                      dangerouslySetInnerHTML={{
                        __html: selectedBoostcamp?.description || "",
                      }}
                    />
                  </div>
                  <div className={styles.flexCollumn}>
                    <div className={styles.iconBlock}>
                      <div className={styles.iconWrapper}>
                        <ClockIcon />
                      </div>
                      <div className={styles.iconBlockContent}>
                        <p className={styles.iconBlockTitle}>
                          {selectedBoostcamp?.daysNumber} Days
                        </p>
                        <p className={styles.iconBlockSubtitle}>1-2 hours a day</p>
                      </div>
                    </div>
                    <div className={styles.iconBlock}>
                      <div className={styles.iconWrapper}>
                        <SmileyIcon />
                      </div>
                      <div className={styles.iconBlockContent}>
                        <p className={styles.iconBlockTitle}>Gluten Free Meals</p>
                        <p className={styles.iconBlockSubtitle}>
                          you will actually enjoy
                        </p>
                      </div>
                    </div>
                    <div className={styles.iconBlock}>
                      <div className={styles.iconWrapper}>
                        <MessageIcon />
                      </div>
                      <div className={styles.iconBlockContent}>
                        <p className={styles.iconBlockTitle}>Daily Support</p>
                        <p className={styles.iconBlockSubtitle}>
                          tips, tricks, how to videos
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BuyBoostcampModal
        activeCountry={activeCountry}
        setActiveCountry={setActiveCountry}
        setIsPaySuccess={() => {}}
        isOpen={isOpenBuyModal}
        setIsOpen={setIsOpenBuyModal}
      />
    </>
  );
};

export default withMainLayout(Preview, true);
