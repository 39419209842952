import { differenceInDays } from "date-fns";

export const convertOztoLitr = (value: number): number => {
  return +(value * 0.02957).toFixed(1);
};

export const convertLitrToOz = (value: number): number => {
  return +(value * 33.81402).toFixed(1);
};

export const convertIbsToKg = (value: number): number => {
  return +(value * 0.45359237).toFixed(1);
};

export const convertKgToIbs = (value: number): number => {
  return +(value * 2.2046226218).toFixed(1);
};

export const convertInToCm = (value: number): number => {
  return +(value * 2.54).toFixed(1);
};

export const convertCmtoIn = (value: number): number => {
  return +(value * 0.3937007874).toFixed(1);
};

export const checkIsTodayOrBefore = (
  firstDate: Date | undefined,
  secondDate: Date | undefined
) => {
  if (firstDate !== undefined && secondDate !== undefined) {
    const different = differenceInDays(new Date(firstDate), new Date(secondDate));
    if (different >= 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const isIOS = () => {
  let platform = navigator?.userAgent || navigator?.platform || "unknown";

  return /iPhone|iPod|iPad/.test(platform);
};

export * from "./zoho";
