import React, { FC, useState, useRef, useEffect, useMemo } from "react";

import styles from "./styles.module.scss";
import {
  CalendarIcon,
  LockIcon,
  NotificationIcon,
  ShopingListIcon,
  UserIcon,
} from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../localStorage";
import { Line } from "rc-progress";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useOutsideClick, useWindowDimensions } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchUser } from "../../store/actions/UserAction";
import avatar from "../../assets/images/avatar.png";
import preBoostImg from "../../assets/images/pre_boost.png";
import NotificationItem from "../NotificationItem";
import {
  clearNotification,
  setIsShowIndication,
} from "../../store/reducers/NotificationSlice";
import {
  clearAllBoostcamps,
  clearBuyedBoostcamps,
} from "../../store/reducers/AllBoostcampsSlice";
import { boostcampClear } from "../../store/reducers/BoostcampSlice";
import {
  actualBoostcampClear,
  clearSelectedMonday,
} from "../../store/reducers/PaymentsSlice";
import { progressClear } from "../../store/reducers/ProgressSlice";
import { statisticClear } from "../../store/reducers/StatisticSlice";
import { userClear, setToken } from "../../store/reducers/UserSlice";
import ShoppingListMenu from "../ShoppingListMenu";
import { fetchProgress } from "../../store/actions/ProgressActions";
import OnboardingTooltip from "../OnboardingTooltip";
import { setGeneralOnboardingStep } from "../../store/reducers/OnboardingSlice";
import { finishOnboarding } from "../../store/actions/OnboardingAction";
import { GeneralOnboardingStepsEnum } from "../../models/ITooltip";
import { reduceCompletedDays } from "../../utils/progress";
interface IProps {
  showHeaderProgress: boolean;
}
export let socket: any = null;

const HeaderMenu: FC<IProps> = ({ showHeaderProgress }): JSX.Element => {
  const navigate = useNavigate();
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState<boolean>(false);
  const [isOpenNotificationMenu, setIsOpenNotificationMenu] = useState<boolean>(false);
  const [isOpenContactModal, setIsOpenContactModal] = useState<boolean>(false);
  const [isOpenShopingList, setIsOpenShopingList] = useState<boolean>(false);

  const profileMenuRef = useRef(null);
  const notificationMenuRef = useRef(null);
  const shopingListMenuRef = useRef(null);
  useOutsideClick(shopingListMenuRef, () => setIsOpenShopingList(false));
  useOutsideClick(profileMenuRef, () => setIsOpenProfileMenu(false));
  useOutsideClick(notificationMenuRef, () => {
    setIsOpenNotificationMenu((prev) => {
      if (prev) {
        dispatch(setIsShowIndication(false));
        return false;
      } else {
        return false;
      }
    });
  });

  const { user } = useAppSelector((state) => state.userReducer);
  const { progress } = useAppSelector((state) => state.processReducer);
  const { notifications, showIndicator } = useAppSelector(
    (state) => state.notificationsReducer
  );
  const { buyedBoostcamps } = useAppSelector((state) => state.allBoostcampsReducer);

  const {
    showGeneralOnboarding,
    generalOnboardingStep,
    generalOnboardingSteps,
    generalOnboardingStepIndex,
  } = useAppSelector((state) => state.onboardingReducer);

  const { width } = useWindowDimensions();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  const goToHome = () => {
    navigate("/home");
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  const goToInfo = () => {
    navigate("/intro");
  };

  const onLogout = () => {
    dispatch(clearBuyedBoostcamps());
    dispatch(clearAllBoostcamps());
    dispatch(boostcampClear());
    dispatch(clearSelectedMonday());
    dispatch(actualBoostcampClear());
    dispatch(progressClear());
    dispatch(statisticClear());
    dispatch(userClear());
    dispatch(clearNotification());
    dispatch(setToken(""));
    removeToken();
    navigate("/login");
  };

  const hasBuyedBoostacamp = useMemo(
    () => !!buyedBoostcamps && buyedBoostcamps?.length > 0,
    [buyedBoostcamps]
  );

  const openZoho = () => {
    const element = document.getElementById("zohohc-asap-web-launcherbox");
    if (!!element) {
      element?.click();
    }
  };

  useEffect(() => {
    if (!progress) dispatch(fetchProgress());
  }, [progress]);

  const showGeneralOnbAndHasBuyed = useMemo(
    () => showGeneralOnboarding && hasBuyedBoostacamp,
    [showGeneralOnboarding, hasBuyedBoostacamp]
  );

  const isActiveProfileOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.PROFILE,
    [generalOnboardingStep]
  );
  const isActiveNotifOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.NOTIF,
    [generalOnboardingStep]
  );
  const isActiveShopOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.SHOPPING,
    [generalOnboardingStep]
  );
  const isActiveMealOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.MEAL,
    [generalOnboardingStep]
  );

  const isActiveOnboarding = useMemo(
    () =>
      showGeneralOnbAndHasBuyed &&
      (isActiveProfileOnboarding ||
        isActiveNotifOnboarding ||
        isActiveMealOnboarding ||
        isActiveShopOnboarding),
    [
      showGeneralOnbAndHasBuyed,
      isActiveProfileOnboarding,
      isActiveNotifOnboarding,
      isActiveMealOnboarding,
      isActiveShopOnboarding,
    ]
  );

  const goToNextOnboardingStep = () => {
    if (isActiveOnboarding)
      dispatch(setGeneralOnboardingStep(generalOnboardingStepIndex + 1));
    if (generalOnboardingStepIndex + 1 >= generalOnboardingSteps?.length)
      dispatch(finishOnboarding({ general: true }));
  };

  const skipOnboarding = () => {
    dispatch(finishOnboarding({ general: true }));
  };

  const daysProgress = useMemo(
    () =>
      user?.testMode
        ? reduceCompletedDays(progress?.days)
        : progress?.passedDays
        ? progress.passedDays + 1
        : 1,
    [user?.testMode, progress]
  );

  return (
    <>
      <div
        className={clsx(
          styles.container,
          showGeneralOnbAndHasBuyed && isActiveOnboarding && styles.containerActive
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.logoWrapper}>
            <h4 onClick={goToHome} className={styles.logo}>
              Boost Camp
            </h4>
            <div
              className={clsx(styles.infoBtn, {
                [styles.infoBtnActive]: hasBuyedBoostacamp,
              })}
            >
              {hasBuyedBoostacamp ? (
                // <InfoIcon onClick={goToInfo} width={22} height={22} />
                <img
                  src={preBoostImg}
                  width={22}
                  height={18}
                  style={{ width: 22, height: "auto" }}
                  onClick={goToInfo}
                />
              ) : (
                <LockIcon width={22} height={22} />
              )}
            </div>
          </div>
          {progress && showHeaderProgress && width > 768 && (
            <div className={styles.progressWrapper}>
              <div className={styles.progressLineContainer}>
                <Line
                  percent={(daysProgress * 100) / (progress?.daysNumber || 1)}
                  strokeWidth={width < 1124 ? 6 : width < 1440 ? 3 : width < 1720 ? 2 : 1}
                  trailWidth={width < 1124 ? 6 : width < 1440 ? 3 : width < 1720 ? 2 : 1}
                  trailColor="#E4E4E4"
                  strokeColor="#8C42E3"
                />
              </div>
              <span>
                {daysProgress}/{progress?.daysNumber || 0}
              </span>
            </div>
          )}

          <div className={styles.navigationMenu}>
            {(progress?.mealPlan?.length > 0 || showGeneralOnbAndHasBuyed) && (
              <div
                onClick={() => {
                  if (!isActiveOnboarding)
                    // setIsOpenShopingList((prev) => !prev);
                    navigate(`/meal-plan/${progress?.id}`);
                }}
                className={clsx(styles.mealPlanBtn, {
                  [styles.mealPlanBtnDisable]: isActiveOnboarding,
                })}
                ref={shopingListMenuRef}
              >
                {showGeneralOnbAndHasBuyed && isActiveMealOnboarding && (
                  <OnboardingTooltip
                    step={generalOnboardingStepIndex + 1}
                    maxSteps={generalOnboardingSteps.length}
                    title={generalOnboardingStep?.title}
                    text={generalOnboardingStep?.description}
                    onClickBtn={goToNextOnboardingStep}
                    className={clsx(styles.tooltip, styles.tooltipMeal)}
                    onClickSkip={skipOnboarding}
                  />
                )}
                <CalendarIcon />
                <span>Meal Plan</span>
              </div>
            )}
            {(progress?.mealPlan?.length > 0 || showGeneralOnbAndHasBuyed) && (
              <div
                onClick={() => {
                  if (!isActiveOnboarding) setIsOpenShopingList(true);
                }}
                className={clsx(styles.shopingListbtn, {
                  [styles.shopingListbtnActive]: isOpenShopingList,
                  [styles.shopingListbtnDisable]: isActiveOnboarding,
                })}
                ref={shopingListMenuRef}
              >
                {showGeneralOnbAndHasBuyed && isActiveShopOnboarding && (
                  <OnboardingTooltip
                    step={generalOnboardingStepIndex + 1}
                    maxSteps={generalOnboardingSteps.length}
                    title={generalOnboardingStep?.title}
                    text={generalOnboardingStep?.description}
                    onClickBtn={goToNextOnboardingStep}
                    className={clsx(styles.tooltip, styles.tooltipShoping)}
                    onClickSkip={skipOnboarding}
                  />
                )}
                <ShopingListIcon />
                <span>Shopping List</span>
                {isOpenShopingList && (
                  <ShoppingListMenu
                    setIsOpen={setIsOpenShopingList}
                    mealPlan={progress?.mealPlan}
                  />
                )}
              </div>
            )}
            {width <= 768 && <div className={styles.mobileHeaderDivider} />}
            <div
              onClick={() => {
                if (!isActiveOnboarding) {
                  setIsOpenNotificationMenu((prev) => {
                    if (prev) {
                      dispatch(setIsShowIndication(false));
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
              }}
              className={clsx(styles.notificationBtn, {
                [styles.notificationBtnActive]: isOpenNotificationMenu,
                [styles.notificationBtnDisable]: isActiveOnboarding,
              })}
              ref={notificationMenuRef}
            >
              {showGeneralOnbAndHasBuyed && isActiveNotifOnboarding && (
                <OnboardingTooltip
                  step={generalOnboardingStepIndex + 1}
                  maxSteps={generalOnboardingSteps.length}
                  title={generalOnboardingStep?.title}
                  text={generalOnboardingStep?.description}
                  onClickBtn={goToNextOnboardingStep}
                  className={clsx(styles.tooltip, styles.tooltipNotif)}
                  onClickSkip={skipOnboarding}
                />
              )}
              <NotificationIcon />
              {showIndicator && (
                <div className={styles.notificationIndicatorWrapper}>
                  <div className={styles.notificationIndicator} />
                </div>
              )}
              {isOpenNotificationMenu && (
                <motion.div
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                  className={styles.notificationMenu}
                >
                  <div className={styles.notificationList}>
                    {notifications.map((item) => (
                      <NotificationItem notification={item} key={item?.id} />
                    ))}
                    {notifications?.length === 0 && (
                      <div className={styles.emptyTitle}>There are no notifications</div>
                    )}
                  </div>
                  {/* <div className={styles.footer}>
                    <p className={styles.title}>Website maintenance</p>
                    <p className={styles.subtitle}>
                      Complete everyday tasks, collect points and get
                    </p>
                  </div> */}
                </motion.div>
              )}
            </div>

            <div
              onClick={() => {
                if (!isActiveOnboarding) {
                  setIsOpenProfileMenu((prev) => {
                    if (prev) {
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
              }}
              className={clsx(styles.myProfileBtn, {
                [styles.myProfileBtnActive]: isOpenProfileMenu,
                [styles.myProfileBtnDisable]: isActiveOnboarding,
              })}
              ref={profileMenuRef}
            >
              {showGeneralOnbAndHasBuyed && isActiveProfileOnboarding && (
                <OnboardingTooltip
                  step={generalOnboardingStepIndex + 1}
                  maxSteps={generalOnboardingSteps.length}
                  title={generalOnboardingStep?.title}
                  text={generalOnboardingStep?.description}
                  onClickBtn={goToNextOnboardingStep}
                  className={clsx(styles.tooltip, styles.tooltipProfile)}
                  onClickSkip={skipOnboarding}
                />
              )}
              <UserIcon />
              <span>My Profile</span>
              {isOpenProfileMenu && (
                <motion.div
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                  className={styles.profileMenuWrapper}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className={styles.header}>
                    <img src={user?.picture || avatar} className={styles.avatar} />
                    <div className={styles.description}>
                      <h5 className={styles.title}>
                        {user?.firstName} {user?.lastName}
                      </h5>
                      <p onClick={goToProfile} className={styles.viewProfileBtn}>
                        View Profile
                      </p>
                    </div>
                  </div>
                  <div className={styles.btnWrapper}>
                    <div onClick={openZoho} className={styles.navBtn}>
                      Contact Us
                    </div>
                    <div onClick={onLogout} className={styles.navBtn}>
                      Log Out
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>

        {progress && showHeaderProgress && width <= 768 && (
          <div className={styles.progressWrapperMobile}>
            <div className={styles.progressLineContainer}>
              <Line
                percent={
                  ((progress?.passedDays ? progress.passedDays + 1 : 1) * 100) /
                  (progress?.daysNumber || 1)
                }
                strokeWidth={2}
                trailWidth={2}
                strokeLinecap="square"
                trailColor="#E4E4E4"
                strokeColor="#8C42E3"
              />
            </div>
          </div>
        )}
      </div>
      {/* <ContactUsModal isShow={isOpenContactModal} setIsShow={setIsOpenContactModal} /> */}
    </>
  );
};

export default HeaderMenu;
