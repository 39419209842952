import React, {
  DetailedHTMLProps,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useMemo,
} from "react";
import { Header, HeaderMenu, Footer } from "../../components";
import { getToken } from "../../localStorage";

import styles from "./styles.module.scss";
import { useAppSelector } from "../../hooks/redux";

export interface IPropsMainLayout
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  headerTimer: boolean;
  footer: boolean;
  showHeaderProgress: boolean;
  children: ReactNode;
  tutorial?: "main" | "boostcamp";
}

const MainLayout = ({
  headerTimer = false,
  footer = true,
  showHeaderProgress = false,
  children,
  tutorial,
}: IPropsMainLayout): JSX.Element => {
  const token = getToken();

  const { showGeneralOnboarding, showBoostcapmOnboarding } = useAppSelector(
    (state) => state.onboardingReducer
  );
  const { buyedBoostcamps } = useAppSelector((state) => state.allBoostcampsReducer);

  const hasBuyedBoostacamp = useMemo(
    () => !!buyedBoostcamps && buyedBoostcamps?.length > 0,
    [buyedBoostcamps]
  );
  const showGeneralOnbAndHasBuyed = useMemo(
    () => showGeneralOnboarding && hasBuyedBoostacamp,
    [showGeneralOnboarding, hasBuyedBoostacamp]
  );

  return (
    <div className={styles.container}>
      {((showGeneralOnbAndHasBuyed && tutorial === "main") ||
        (showBoostcapmOnboarding && tutorial === "boostcamp")) && (
        <div className={styles.overlay} />
      )}
      {!token && headerTimer && <Header />}
      {token && <HeaderMenu showHeaderProgress={showHeaderProgress} />}
      <main className={styles.main}>{children}</main>
      {footer && <Footer />}
    </div>
  );
};

const withMainLayout = <T extends Record<string, unknown>>(
  Component: FunctionComponent,
  headerTimer = false,
  footer = true,
  showHeaderProgress = false,
  tutorial: "main" | "boostcamp" | undefined = undefined
) => {
  return function withMainLayoutComponent(props: T) {
    return (
      <MainLayout
        footer={footer}
        headerTimer={headerTimer}
        showHeaderProgress={showHeaderProgress}
        tutorial={tutorial}
      >
        <Component />
      </MainLayout>
    );
  };
};

export default withMainLayout;
