import React, { FC } from "react";
import { SuccessBigIcon } from "../../assets/icons";
import styles from "./styles.module.scss";
import { Button } from "../index";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface IProps {}

const SuccessPayments: FC<IProps> = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <motion.div className={styles.wrapper}>
      <SuccessBigIcon />
      <h4>Congratulations!</h4>
      <p>Your purchase was successful.</p>
      <Button
        title={"Click here for Boost Camp"}
        styleType={"filled"}
        size={"medium"}
        style={{ marginTop: "30px" }}
        onClick={() => navigate("/intro")}
      />
    </motion.div>
  );
};

export default SuccessPayments;
