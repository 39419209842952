import React, { FC, useState, useEffect, useMemo } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

import Fasting from "../../assets/images/fasting.png";

import { ITrackers } from "../../models/ITrackers";
import { IProgress } from "../../models/IProgress";

import { useAppDispatch, useAppSelector } from "../../hooks/redux";

import Button from "../Button";
import TrackersInput from "../TrackersInput";
import TeckerMobile from "../TeckerMobile";

import { updateProgressDay } from "../../utils/progress";

import { setTrackers } from "../../services/progress.service";
import { setProgressDay } from "../../store/reducers/ProgressSlice";

interface IProps {
  value: ITrackers | null;
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
  currentIsAvailableTracker?: boolean;
}

const FastingTrackerMobile: FC<IProps> = ({
  value,
  currentProgress,
  setCurrentProgress,
  currentIsAvailableTracker,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { progress, currentDay } = useAppSelector((state) => state.processReducer);

  const [isOpenInfo, setIsOpenInfo] = useState<boolean>(false);
  const [isOpenTracker, setIsOpenTracker] = useState<boolean>(false);
  const [formattedValue, setFormattedValue] = useState<string>("");

  const [activeType, setActiveType] = useState<number>(0);
  const [count, setCount] = useState<string>("0");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const isClosed =
    handledProgress?.days[currentDay]?.isClose ||
    handledProgress?.days[currentDay]?.isCompleted;

  const formatValue = (value: ITrackers | null) => {
    if (value === null) {
      return;
    }
    setFormattedValue(value?.eat > 0 ? `${value?.eat} h` : "");
  };

  const onClick = () => {
    // if (!isClosed) {
    setIsOpenTracker(true);
    // }
  };

  const onChangeFasting = async () => {
    try {
      setIsLoadingSubmit(true);

      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          eat: +count,
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          setIsOpenTracker(false);
        }
      }

      setIsLoadingSubmit(false);
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  useEffect(() => {
    formatValue(value);
  }, [value]);

  useEffect(() => {
    if (!isOpenTracker) return;
    const eatValue = handledProgress?.days[currentDay]?.trackers?.eat;
    setCount(`${eatValue || 0}`);
  }, [handledProgress, currentDay, isOpenTracker]);

  const color = "#F0512233";
  const btnColor = "#F05122";

  return (
    <div className={styles.wrapper}>
      <TeckerMobile
        title="Fasting window"
        isClosed={isClosed}
        formattedValue={formattedValue}
        currentIsAvailableTracker={currentIsAvailableTracker}
        color={color}
        btnColor={btnColor}
        isOpenTracker={isOpenTracker}
        onClick={onClick}
        isOpenInfo={isOpenInfo}
        setIsOpenInfo={setIsOpenInfo}
        infoTitle="How long has it been since your last meal or caloric beverage?"
        icon={<img src={Fasting} style={{ width: "30px", height: "30px" }} />}
        children={
          <div className={styles.btnContainer}>
            <div
              onClick={() => setActiveType(0)}
              className={clsx(styles.typeBtn, {
                [styles.typeBtnActive]: activeType === 0,
              })}
            >
              hrs
            </div>
          </div>
        }
      />
      {isOpenTracker && (
        <div className={styles.contentBox}>
          <div className={styles.sliderWrapper}>
            <div className={styles.sliderValueWrapper}>
              {/* <span>{options[activeIndex]}</span> */}
              <div className={styles.inputWrapper}>
                <TrackersInput value={count} setValue={setCount} />
              </div>
            </div>
          </div>

          <Button
            title={"SUBMIT"}
            styleType={"filled"}
            size={"small"}
            backgroundColor={"#F05122"}
            loading={isLoadingSubmit}
            onClick={onChangeFasting}
          />
        </div>
      )}
    </div>
  );
};
export default FastingTrackerMobile;
