import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/UserSlice";
import allBoostcampsReducer from "./reducers/AllBoostcampsSlice";
import boostcampReducer from "./reducers/BoostcampSlice";
import paymentsReducer from "./reducers/PaymentsSlice";
import processReducer from "./reducers/ProgressSlice";
import statisticsReducer from "./reducers/StatisticSlice";
import notificationsReducer from "./reducers/NotificationSlice";
import modalReducer from "./reducers/ModalSlice";
import onboardingReducer from "./reducers/OnboardingSlice";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  userReducer,
  allBoostcampsReducer,
  boostcampReducer,
  paymentsReducer,
  processReducer,
  statisticsReducer,
  notificationsReducer,
  modalReducer,
  onboardingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
