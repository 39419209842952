import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSocket } from "../hooks/useSocket";

import {
  Preview,
  SignUp,
  LogIn,
  Private,
  Home,
  Profile,
  BuyAfterSignUp,
  ProgressBoostcamp,
  Intro,
  EditProfile,
  EmailVerification,
  MealPlan,
  RecipePreviewPage,
} from "../pages";
import { getToken } from "../localStorage";

const Navigation = () => {
  useSocket();

  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/signup"} element={<SignUp />} />
        <Route path={"/login"} element={<LogIn />} />
        <Route path={"/preview/:id"} element={<Preview />} />
        <Route path={"/email-verification"} element={<EmailVerification />} />
        <Route path={"/"} element={<Private />}>
          <Route index element={<Navigate to={"/home"} />} />
          <Route path="/home" element={<Home />} />
          <Route path={"/signup-buy"} element={<BuyAfterSignUp />} />
          <Route path={"/intro"} element={<Intro />} />
          <Route path={"/meal-plan/:id"} element={<MealPlan />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/edit-profile"} element={<EditProfile />} />
          <Route path={"recipe/:id"} element={<RecipePreviewPage />} />
          {/* <Route path={"/boostcamp/:id"} element={<Boostcamp />} /> */}
          <Route path={"/boostcamp/progress/:id/:day"} element={<ProgressBoostcamp />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
