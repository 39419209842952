import clsx from "clsx";
import React, { FC } from "react";
import { CheckIcon } from "../../assets/icons";
import styles from "./styles.module.scss";

interface IProps {
  currentStep: number;
  maxStep: number;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
}

const Progress: FC<IProps> = ({ currentStep, maxStep, setCurrentStep }): JSX.Element => {
  const changeStep = (step: number) => {
    setCurrentStep && setCurrentStep(step);
  };

  return maxStep === 3 ? (
    <div className={styles.container}>
      {currentStep === 1 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>1</div>
        </div>
      ) : (
        <div className={styles.complete}>
          <CheckIcon />
        </div>
      )}
      <div className={currentStep === 1 ? styles.divider : styles.activeDivider} />
      {currentStep === 2 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>2</div>
        </div>
      ) : currentStep === 3 ? (
        <div className={styles.complete}>
          <CheckIcon />
        </div>
      ) : (
        <div className={styles.step}>2</div>
      )}
      <div
        className={
          currentStep === 2 || currentStep === 1 ? styles.divider : styles.activeDivider
        }
      />
      {currentStep === 3 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>3</div>
        </div>
      ) : (
        <div className={styles.step}>3</div>
      )}
    </div>
  ) : maxStep === 4 ? (
    <div className={styles.container}>
      {currentStep === 1 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>1</div>
        </div>
      ) : (
        <div className={styles.complete}>
          <CheckIcon />
        </div>
      )}
      <div className={currentStep === 1 ? styles.divider : styles.activeDivider} />
      {currentStep === 2 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>2</div>
        </div>
      ) : currentStep === 3 || currentStep === 4 ? (
        <div className={styles.complete}>
          <CheckIcon />
        </div>
      ) : (
        <div className={styles.step}>2</div>
      )}
      <div
        className={
          currentStep === 2 || currentStep === 1 ? styles.divider : styles.activeDivider
        }
      />
      {currentStep === 3 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>3</div>
        </div>
      ) : currentStep === 4 ? (
        <div className={styles.complete}>
          <CheckIcon />
        </div>
      ) : (
        <div className={styles.step}>3</div>
      )}
      <div
        className={
          currentStep === 3 || currentStep === 2 || currentStep === 1
            ? styles.divider
            : styles.activeDivider
        }
      />
      {currentStep === 4 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>4</div>
        </div>
      ) : (
        <div className={styles.step}>4</div>
      )}
    </div>
  ) : maxStep >= 7 ? (
    <div className={styles.container}>
      {Array.from(Array(maxStep)).map((el, id) => (
        <React.Fragment key={`progress-step-${id}`}>
          {currentStep === id + 1 ? (
            <div className={styles.activeStepWrapper} onClick={() => changeStep(id + 1)}>
              <div className={styles.activeStep}>{id + 1}</div>
            </div>
          ) : currentStep > id + 1 ? (
            <div className={styles.complete} onClick={() => changeStep(id + 1)}>
              <CheckIcon />
            </div>
          ) : (
            <div className={styles.step} onClick={() => changeStep(id + 1)}>
              {id + 1}
            </div>
          )}
          {id + 1 < Array.from(Array(maxStep)).length && (
            <div
              className={currentStep <= id + 1 ? styles.divider : styles.activeDivider}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  ) : (
    <div className={styles.container}>
      {currentStep === 1 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>1</div>
        </div>
      ) : (
        <div className={styles.complete}>
          <CheckIcon />
        </div>
      )}
      <div className={currentStep === 1 ? styles.divider : styles.activeDivider} />
      {currentStep === 2 ? (
        <div className={styles.activeStepWrapper}>
          <div className={styles.activeStep}>2</div>
        </div>
      ) : (
        <div className={styles.step}>2</div>
      )}
    </div>
  );
};

export default Progress;
