import { MotionConfig } from "framer-motion";
import React, { FC, useState, useEffect, ChangeEvent } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import { motion } from "framer-motion";
import {
  CloseDayIcon,
  CrossIcon,
  EmailVerificationIcon,
  MessageColorBigIcon,
} from "../../assets/icons";
import Input from "../Input";
import Button from "../Button";
import TextArea from "../TextArea";
import { sendRequest } from "../../services/request.service";
import { useWindowDimensions } from "../../hooks";
import {
  changePassword,
  sendResetPasswordCode,
  verifyResetPassword,
} from "../../services/reset_password.service";

interface IProps {
  isShow: boolean;
  setIsShow: (val: boolean) => void;
  onSuccess: () => void;
}

const ForgotPasswordModal: FC<IProps> = ({
  isShow = false,
  setIsShow,
  onSuccess = () => {},
}): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [codeError, setCodeError] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [codeToken, setCodeToken] = useState<string>("");

  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<string>("");
  const [isSuccessChanged, setIsSuccessChanged] = useState<boolean>(false);

  const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false);

  const { width, height } = useWindowDimensions();

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };
  const onChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const onSendRequest = async () => {
    try {
      if (email.length < 2) {
        setEmailError("Email is required");
        return;
      } else {
        setEmailError("");
      }
      setIsLoadingSend(true);

      const { error, result } = await sendResetPasswordCode(email);
      if (!error && result?.status === 204) {
        // setIsShow(false);
        setIsSuccess(true);
      } else {
        console.log("error", { error, result });

        setEmailError(
          //@ts-ignore
          error?.response?.data?.message || error?.message || "Something went wrong"
        );
      }

      setIsLoadingSend(false);
    } catch (e: any) {
      console.log(e);
      setIsLoadingSend(false);
    }
  };

  const confirmCode = async () => {
    try {
      setCodeError("");
      setIsLoadingSend(true);

      const { error, result } = await verifyResetPassword(email, code);
      if (!error && result?.status === 200) {
        // setIsShow(false);
        setCodeToken(result.data?.data);
      } else {
        console.log("error", { error, result });

        setCodeError(
          //@ts-ignore
          error?.response?.data?.message || error?.message || "Something went wrong"
        );
      }

      setIsLoadingSend(false);
    } catch (e: any) {
      console.log(e);
      setIsLoadingSend(false);
    }
  };

  const changeToNewPassword = async () => {
    try {
      setNewPasswordError("");
      setIsLoadingSend(true);

      const { error, result } = await changePassword(codeToken, newPassword);
      if (!error && result?.status === 204) {
        // setIsShow(false);
        setIsSuccessChanged(true);
        onSuccess();
      } else {
        console.log("error", { error, result });

        setNewPasswordError(
          //@ts-ignore
          error?.response?.data?.message || error?.message || "Something went wrong"
        );
      }

      setIsLoadingSend(false);
    } catch (e: any) {
      console.log(e);
      setIsLoadingSend(false);
    }
  };

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          padding: width <= 768 ? "16px" : "0",
          backgroundColor: "transparent",
          outline: "none",
          border: "none",
        },
      }}
      ariaHideApp={false}
      onRequestClose={() => setIsShow(false)}
      isOpen={isShow}
    >
      {isSuccessChanged ? (
        <div className={styles.contentBox}>
          <div className={styles.header}>
            <h4>Forgot password?</h4>
            <div onClick={() => setIsShow(false)} className={styles.closeBtn}>
              <CrossIcon />
            </div>
          </div>
          <div className={styles.inputsWrapper}>
            <p className={styles.success}>Your passwrod successfully changed</p>
            <div className={styles.sendBtnWrapper}>
              <Button
                title="Okay"
                styleType="filled"
                size={"medium"}
                onClick={() => setIsShow(false)}
              />
            </div>
            <div className={styles.supportWrapper}>
              <span>
                {/* If you have any logging troubles, you can contact our{" "} */}
                <a href="mailto:boost@bridgetshealthykitchen.com">
                  Contact us for support
                </a>
              </span>
            </div>
          </div>
        </div>
      ) : !!codeToken ? (
        <div className={styles.contentBox}>
          <div className={styles.header}>
            <h4>Forgot password?</h4>
            <div onClick={() => setIsShow(false)} className={styles.closeBtn}>
              <CrossIcon />
            </div>
          </div>
          <div className={styles.inputsWrapper}>
            <Input
              label="New password"
              value={newPassword}
              onChange={onChangeNewPassword}
              placeholder={"Enter New Password"}
              type={"password"}
              // error={newPasswordError}
            />
            {newPasswordError && <p className={styles.error}>{newPasswordError}</p>}
            <div className={styles.sendBtnWrapper}>
              <Button
                title="Change"
                styleType="filled"
                size={"medium"}
                loading={isLoadingSend}
                onClick={changeToNewPassword}
              />
            </div>
            <div className={styles.supportWrapper}>
              <span>
                {/* If you have any logging troubles, you can contact our{" "} */}
                <a href="mailto:boost@bridgetshealthykitchen.com">
                  Contact us for support
                </a>
              </span>
            </div>
          </div>
        </div>
      ) : isSuccess ? (
        <>
          <div className={styles.contentBox}>
            <div className={styles.header}>
              <h4>Verify your email</h4>
              <div onClick={() => setIsShow(false)} className={styles.closeBtn}>
                <CrossIcon />
              </div>
            </div>
            <div className={styles.inputsWrapper}>
              <div className={styles.inputWrapper}>
                <input
                  className={styles.codeInput}
                  maxLength={6}
                  placeholder={"# # # # #"}
                  value={code}
                  onChange={onChangeCode}
                />
              </div>
              {codeError && <p className={styles.error}>{codeError}</p>}
              <div className={styles.sendBtnWrapper}>
                <Button
                  title="Verify"
                  styleType="filled"
                  size={"medium"}
                  loading={isLoadingSend}
                  onClick={confirmCode}
                />
              </div>
              <div className={styles.supportWrapper}>
                <span>
                  {/* If you have any logging troubles, you can contact our{" "} */}
                  <a href="mailto:boost@bridgetshealthykitchen.com">
                    Contact us for support
                  </a>
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.contentBox}>
          <div className={styles.header}>
            <h4>Forgot password?</h4>
            <div onClick={() => setIsShow(false)} className={styles.closeBtn}>
              <CrossIcon />
            </div>
          </div>
          <div className={styles.inputsWrapper}>
            <Input
              label="Email Address"
              value={email}
              onChange={onChangeEmail}
              placeholder={"Enter Email Address"}
              type={"email"}
              // error={emailError}
            />
            {emailError && <p className={styles.error}>{emailError}</p>}
            <div className={styles.sendBtnWrapper}>
              <Button
                title="Send"
                styleType="filled"
                size={"medium"}
                loading={isLoadingSend}
                onClick={onSendRequest}
              />
            </div>
            <div className={styles.supportWrapper}>
              <span>
                {/* If you have any logging troubles, you can contact our{" "} */}
                <a href="mailto:boost@bridgetshealthykitchen.com">
                  Contact us for support
                </a>
              </span>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ForgotPasswordModal;
