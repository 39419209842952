import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import { ShareIcon } from "../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  setActiveTrackingBtn,
  setTrackingOption,
} from "../../store/reducers/StatisticSlice";
import StatisticCharts from "../StatisticCharts";
import styles from "./styles.module.scss";
import { FacebookShareButton } from "react-share";
import TrackingBtn from "../TrackingBtn";

interface IProps {
  icon: ReactNode;
  title: string;
  value: number | string;
  valueType: string;
  isActive: boolean;
  iconColor: string;
  borderColor: string;
  onClick: () => void;
}

// const facebookShareUrl = 'https://octopus-app-zfcnv.ondigitalocean.app';
const facebookShareUrl = "https://www.manageinsulin.com";

const TrackingItem: FC<IProps> = ({
  icon,
  title,
  value,
  valueType,
  isActive,
  iconColor,
  borderColor,
  onClick,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { trackingOption, activeTrackingType } = useAppSelector(
    (state) => state.statisticsReducer
  );

  return (
    <>
      <div
        onClick={onClick}
        style={{ borderColor: isActive ? borderColor : "transparent" }}
        className={styles.wrapper}
      >
        <div style={{ backgroundColor: borderColor }} className={styles.iconWrapper}>
          {icon}
        </div>
        <div className={styles.descriptionWrapper}>
          <h4 className={styles.value}>{`${value} ${valueType}`}</h4>
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.shareWrapper}>
          <FacebookShareButton url={facebookShareUrl} title={"test"}>
            <ShareIcon style={{ color: "#afafaf" }} />
            <p className={styles.shareTitle}>Share result</p>
          </FacebookShareButton>
        </div>
      </div>
      {isActive && <TrackingBtn />}
      {trackingOption !== "day" && activeTrackingType !== "body" && isActive && (
        <div className={styles.graphContainer}>
          <div className={styles.graphContainerInner}>
            <StatisticCharts />
          </div>
        </div>
      )}
    </>
  );
};

export default TrackingItem;
