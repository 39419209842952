import React, { FC, useRef, useState } from "react";

import styles from "./styles.module.scss";
import { MinusIcon, PlusIcon } from "../../assets/icons";
import clsx from "clsx";

interface IProps {
  question: string;
  answer: string;
}

const FaqItem: FC<IProps> = ({ answer, question }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeHeight, setActiveHeight] = useState("0px");
  const answerRef = useRef<any>(null);

  const toogleIsOpen = () => {
    setIsOpen((prev) => !prev);
    setActiveHeight(answerRef?.current?.scrollHeight);
  };

  return (
    <>
      <div
        onClick={toogleIsOpen}
        className={clsx(styles.wrapper, { [styles.wrapperActive]: isOpen })}
      >
        <p>{question}</p>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      <div
        className={clsx(styles.answerContainer)}
        style={{ maxHeight: isOpen ? activeHeight : 0 }}
        ref={answerRef}
      >
        <p className={styles.answer}>{answer}</p>
      </div>
    </>
  );
};

export default FaqItem;
