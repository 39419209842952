import React, { FC, useMemo, useState } from "react";
import { ITask } from "../../models/ITask";
import Button from "../Button";
import TaskItem from "../TaskItem";
import styles from "./styles.module.scss";
import { useAppSelector } from "../../hooks/redux";
import clsx from "clsx";
import { CloseDayIcon } from "../../assets/icons";
import { CompleteTrackersPopUp } from "../index";

interface IProps {
  tasks: ITask[] | undefined;
  onSetActiveTask: (val: string) => void;
  isLoading: boolean;
  onCompleteDay: () => void;
  goToTrackers: () => void;
  isOpenCompleteTrackers: boolean;
  tutorial?: boolean;
  currentIsAvailableTracker?: boolean;
  isClosed?: boolean;
  isCompleted?: boolean;
}

const ChecklistTasks: FC<IProps> = ({
  tasks,
  onSetActiveTask,
  isLoading,
  onCompleteDay,
  goToTrackers,
  isOpenCompleteTrackers,
  tutorial = false,
  currentIsAvailableTracker,
  isClosed,
  isCompleted,
}): JSX.Element => {
  const { isAvailableTrackers, isUpdatingTasks } = useAppSelector(
    (state) => state.processReducer
  );

  const handledIsAvailableTrackers = useMemo(
    () => currentIsAvailableTracker || isAvailableTrackers,
    [isAvailableTrackers, currentIsAvailableTracker]
  );

  return (
    <div style={{ position: "relative" }}>
      <div
        className={clsx(styles.wrapper, {
          [styles.blurWrapper]: !handledIsAvailableTrackers && !tutorial,
        })}
      >
        <h3 className={styles.title}>TO DO LIST</h3>
        <div
          style={{
            overflowY: !handledIsAvailableTrackers && !tutorial ? "hidden" : "scroll",
          }}
          className={styles.checklist}
        >
          {tasks?.map((item, index) => (
            <TaskItem
              isChecked={item?.isChecked}
              title={item.task}
              points={item.points}
              taskId={item?.id}
              key={item?.id}
              onPress={() => {
                // onSetActiveTask(item?.id);
              }}
              isClosed={isClosed}
            />
          ))}
        </div>
        <div style={{ position: "relative" }}>
          {isOpenCompleteTrackers && (
            <CompleteTrackersPopUp goToTrackers={goToTrackers} />
          )}

          <Button
            title="Complete Day"
            styleType="filled"
            size="large"
            onClick={onCompleteDay}
            loading={isLoading}
            disabled={
              isUpdatingTasks || isCompleted || isOpenCompleteTrackers ? true : false
            }
            backgroundColor={
              isCompleted || isOpenCompleteTrackers ? "#7c8691" : "#8c42e3"
            }
          />
        </div>
      </div>

      {!handledIsAvailableTrackers && !tutorial && (
        <CloseDayIcon className={styles.blockIcon} />
      )}
    </div>
  );
};

export default ChecklistTasks;
