import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { withSignLayout } from "../../layouts";
import { BuyBoostcamp, SuccessPayments } from "../../components";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchProgress } from "../../store/actions/ProgressActions";

const BuyAfterSignUp: FC = (): JSX.Element => {
  const [isPaySuccess, setIsPaySuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { progress } = useAppSelector((state) => state.processReducer);

  // @ts-ignore
  const fromSignUp = location.state?.fromSignUp;
  const selectedMonday = location?.state?.selectedMonday;

  useEffect(() => {
    dispatch(fetchProgress());
  }, []);

  // if (!selectedMonday || !!progress) {
  //   navigate("/home");
  // }
  return isPaySuccess ? (
    <SuccessPayments />
  ) : (
    <BuyBoostcamp
      selectedMonday={selectedMonday}
      setIsPaySuccess={setIsPaySuccess}
      fromSignUp={fromSignUp}
    />
  );
};

export default withSignLayout(BuyAfterSignUp);
