import clsx from "clsx";
import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { setTask } from "../../services/progress.service";
import Checkbox from "../Checkbox";
import { setIsUpdatingTasks, setProgressDay } from "../../store/reducers/ProgressSlice";
import styles from "./styles.module.scss";
import { MoonLoader } from "react-spinners";
interface IProps {
  isChecked: boolean;
  title: string;
  points: number;
  onPress: () => void;
  taskId: string;
  isClosed?: boolean;
}

const TaskItem: FC<IProps> = ({
  isChecked,
  title,
  points,
  taskId,
  onPress,
  isClosed = false,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [checked, setIsChecked] = useState<boolean>(isChecked);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { progress, currentDay, isUpdatingTasks } = useAppSelector(
    (state) => state.processReducer
  );

  const onSetTask = async (taskId: string, isChecked: boolean) => {
    try {
      setIsLoading(true);
      dispatch(setIsUpdatingTasks(true));
      const { error, result } = await setTask(
        progress?.days[currentDay].id || "",
        taskId
      );
      if (!error && result?.status === 202) {
        dispatch(setProgressDay(result?.data?.data));
        setIsChecked(!isChecked);
      }
      setIsLoading(false);
      dispatch(setIsUpdatingTasks(false));
    } catch (error) {
      setIsLoading(false);
      dispatch(setIsUpdatingTasks(false));
      console.log(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <MoonLoader size={10} color={"#8c42e3"} />
        </div>
      ) : (
        <Checkbox
          disabled={isUpdatingTasks}
          isChecked={checked}
          setIsChecked={
            isLoading
              ? () => {}
              : () => {
                  // if (!isClosed) {
                  onPress();
                  onSetTask(taskId, isChecked);
                  // }
                }
          }
        />
      )}
      <p className={clsx(styles.title, { [styles.checkedTitle]: checked })}>{title}</p>
      <p className={clsx(styles.points, { [styles.checkedPoints]: checked })}>
        +{points}
      </p>
    </div>
  );
};

export default TaskItem;
