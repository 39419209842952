import React, { FC } from "react";
import styles from "./styles.module.scss";
import { DoneIcon } from "../../assets/icons";

interface IProps {
  title: string;
}

const SuccessModal: FC<IProps> = ({ title = "" }): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      <div>
        <DoneIcon />
      </div>
    </div>
  );
};

export default SuccessModal;
