import clsx from "clsx";
import React, { FC } from "react";
import { CheckIcon } from "../../assets/icons";
import styles from "./styles.module.scss";

interface IProps {
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  disabled?: boolean;
}

const Checkbox: FC<IProps> = ({
  isChecked,
  setIsChecked,
  disabled = false,
}): JSX.Element => {
  return (
    <div
      onClick={() => !disabled && setIsChecked(!isChecked)}
      className={clsx({
        [styles.checked]: isChecked,
        [styles.unChecked]: !isChecked,
        [styles.disabled]: disabled,
      })}
    >
      {isChecked && <CheckIcon />}
    </div>
  );
};

export default Checkbox;
