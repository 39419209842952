const lOptions = [
  { label: "0", value: 0 },
  { label: "0.5", value: 0.5 },
  { label: "1", value: 1 },
  { label: "1.5", value: 1.5 },
  { label: "2", value: 2 },
  { label: "2.5", value: 2.5 },
  { label: "3", value: 3 },
  { label: ">3", value: 4 },
];
const ozOptions = [
  { label: "0", value: 0 },
  { label: "17", value: 16 },
  { label: "34", value: 32 },
  { label: "51", value: 48 },
  { label: "68", value: 64 },
  { label: "85", value: 80 },
  { label: "101", value: 96 },
  { label: ">101", value: 100 },
];

const sleepOptins = [
  { label: "≤2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "≥9", value: 9 },
];

const TRACKERS = {
  lOptions,
  ozOptions,
  sleepOptins,
};

export default TRACKERS;
