import React, { FC, Suspense, lazy, useEffect, useState } from "react";
import { withMainLayout } from "../../layouts";
import styles from "./styles.module.scss";
import {
  Button,
  DiscountPoint,
  StatisticCharts,
  StatisticWidget,
  TrackingBtn,
} from "../../components";
import { removeToken } from "../../localStorage";
import { useNavigate } from "react-router-dom";
import {
  ChecklistIcon,
  ClockIcon,
  FireIcon,
  LocationIcon,
  MailIcon,
  PenIcon,
  ShareIcon,
  SleepIcon,
  WaterIcon,
} from "../../assets/icons";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { fetchUser } from "../../store/actions/UserAction";
import avatar from "../../assets/images/avatar.png";
import { fetchStatistic } from "../../store/actions/StatisticActions";
import clsx from "clsx";
import {
  setActiveTrackingType,
  setTrackingOption,
} from "../../store/reducers/StatisticSlice";

import {
  IWaterTracker,
  IWeightTracker,
  IProportionalTracker,
} from "../../models/ITrackers";
import { useWindowDimensions } from "../../hooks";
import Fasting from "../../assets/images/fasting.png";
import { FacebookShareButton } from "react-share";
import { finishOnboarding } from "../../store/actions/OnboardingAction";
import { updateOnboarding } from "../../services/onboarding.service";
import { onboardingSlice } from "../../store/reducers/OnboardingSlice";

const StatisticChartsLazy = lazy(() => import("../../components/StatisticCharts"));

// const facebookShareUrl = "https://octopus-app-zfcnv.ondigitalocean.app";
const facebookShareUrl = "https://www.manageinsulin.com";

const Profile: FC = (): JSX.Element => {
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.userReducer);
  const {
    statistics,
    isLoading,
    error,
    activeTrackingType,
    trackingOption,
    activeTrackingBtn,
  } = useAppSelector((state) => state.statisticsReducer);
  const { buyedBoostcamps } = useAppSelector((state) => state.allBoostcampsReducer);

  const { width, height } = useWindowDimensions();

  const [averageSleep, setAverageSleep] = useState<number>(0);
  const [averageWater, setAverageWater] = useState<IWaterTracker>({ l: 0, flOz: 0 });
  const [averageWeight, setAverageWeight] = useState<IWeightTracker>({ kg: 0, ibs: 0 });
  const [averageFasting, setAverageFasting] = useState<number>(0);
  const [averageBody, setAverageBody] = useState<IProportionalTracker>({
    bust: {
      cm: 0,
      in: 0,
    },
    waist: {
      cm: 0,
      in: 0,
    },
    hips: {
      cm: 0,
      in: 0,
    },
    leftUpperThigh: {
      cm: 0,
      in: 0,
    },
    rightUpperThigh: {
      cm: 0,
      in: 0,
    },
    leftLowerThigh: {
      cm: 0,
      in: 0,
    },
    rightLowerThigh: {
      cm: 0,
      in: 0,
    },
    topLeftArm: {
      cm: 0,
      in: 0,
    },
    topRightArm: {
      cm: 0,
      in: 0,
    },
  });

  const [isHomeOnbLoading, setIsHomeOnbLoading] = useState<boolean>(false);
  const [isBoostOnbLoading, setIsBoostOnbLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (statistics) {
      setAverageSleep(statistics?.averageStats?.sleep);
      setAverageWater(statistics?.averageStats?.water);
      setAverageWeight({
        kg: statistics?.averageStats?.kg,
        ibs: statistics?.averageStats?.ibs,
      });
      setAverageFasting(statistics?.averageStats?.eat);
      setAverageBody(statistics?.averageStats?.proportions);
    }
  }, [statistics]);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  useEffect(() => {
    dispatch(fetchStatistic(trackingOption));
  }, [trackingOption]);

  useEffect(() => {
    if (user?.testMode && trackingOption !== "month")
      dispatch(setTrackingOption("month"));
  }, [user, trackingOption]);

  const onLogOut = () => {
    removeToken();
    navigate("/signup");
  };

  const showHomeOnb = async () => {
    try {
      setIsHomeOnbLoading(true);
      const { error, result } = await updateOnboarding({ general: false });
      if (!error && (result?.status === 200 || result?.status === 202)) {
        dispatch(
          onboardingSlice.actions.onboardingUpdatingSuccess(result.data.data || null)
        );
        navigate("/home");
      }
    } catch (e) {
    } finally {
      setIsHomeOnbLoading(false);
    }
  };
  const showBoostOnb = async () => {
    try {
      setIsBoostOnbLoading(true);
      const { error, result } = await updateOnboarding({ boostCamp: false });
      if (
        buyedBoostcamps?.length > 0 &&
        !error &&
        (result?.status === 200 || result?.status === 202)
      ) {
        dispatch(
          onboardingSlice.actions.onboardingUpdatingSuccess(result.data.data || null)
        );
        navigate(`/boostcamp/progress/${buyedBoostcamps[0]}/0`, {
          state: { isTutorial: true },
        });
      }
    } catch (e) {
    } finally {
      setIsBoostOnbLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.flexColumn}>
        <div className={styles.profileBlock}>
          <img src={user?.picture || avatar} className={styles.avatar} />
          <h4 className={styles.name}>
            {user?.firstName} {user?.lastName}
          </h4>
          {user?.location && (
            <div className={styles.locationContainer}>
              <LocationIcon />
              <p>{user?.location}</p>
            </div>
          )}
          <div className={styles.mailContainer}>
            <MailIcon />
            <p>{user?.email}</p>
          </div>
          <div className={styles.tutorialBtns}>
            <Button
              title={"Edit Profile"}
              styleType={"outlined"}
              size={"small"}
              rightIcon={<PenIcon />}
              style={{ borderColor: "#8c42e3", color: "#8c42e3" }}
              onClick={() => navigate("/edit-profile")}
            />
            {buyedBoostcamps?.length > 0 && (
              <>
                <Button
                  title={"PWA Tutorial"}
                  size={"small"}
                  styleType={"outlined"}
                  onClick={showHomeOnb}
                  loading={isHomeOnbLoading}
                  style={{ borderColor: "#8c42e3", color: "#8c42e3" }}
                />
                <Button
                  title={"Days Tutorial"}
                  size={"small"}
                  styleType={"outlined"}
                  onClick={showBoostOnb}
                  loading={isBoostOnbLoading}
                  style={{ borderColor: "#8c42e3", color: "#8c42e3" }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.flexColumn}>
        <div className={styles.statisticBlock}>
          <h4 className={styles.blockTitle}>Statistics</h4>
          <div className={styles.flexRow}>
            <div className={styles.statisticItem}>
              <div className={styles.iconWrapper}>
                <ClockIcon />
              </div>
              <div className={styles.descriptionWrapper}>
                <p className={styles.counter}>
                  {(statistics?.passedDays || 0) > 0
                    ? (statistics?.passedDays || 0) + 1
                    : statistics?.passedDays}
                </p>
                <p className={styles.subtitle}>Days in the camp</p>
              </div>
            </div>
            <div className={styles.statisticItem}>
              <div className={styles.iconWrapper}>
                <FireIcon />
              </div>
              <div className={styles.descriptionWrapper}>
                <p className={styles.counter}>{statistics?.points || 0}</p>
                <p className={styles.subtitle}>Total points</p>
              </div>
            </div>
            {/* <div className={styles.statisticItem}>
              <div className={styles.iconWrapper}>
                <MedalIcon />
              </div>
              <p className={styles.counter}>7</p>
              <p className={styles.subtitle}>Achievements</p>
            </div> */}
          </div>
          {width > 768 && (
            <div className={styles.trackingTimeSection}>
              <div className={styles.header}>
                <div className={styles.trackingTitleWrapper}>
                  <h4 className={styles.trackingTitle}>Tracking</h4>
                </div>
                <div className={styles.optionsBtnContainer}>
                  {!user?.testMode && (
                    <>
                      {/* <div className={styles.optionsBtnWrapper}> */}
                      <Button
                        onClick={() => dispatch(setTrackingOption("day"))}
                        title={"Day"}
                        styleType={trackingOption === "day" ? "filled" : "outlined"}
                        size={"x-small"}
                      />
                      {/* </div>
                  <div className={styles.optionsBtnWrapper}> */}
                      <Button
                        onClick={() => dispatch(setTrackingOption("week"))}
                        title={"Week"}
                        styleType={trackingOption === "week" ? "filled" : "outlined"}
                        size={"x-small"}
                      />
                      {/* </div> */}
                    </>
                  )}
                  {/* <div className={styles.optionsBtnWrapper}>  */}
                  <Button
                    onClick={() => dispatch(setTrackingOption("month"))}
                    title={"Month"}
                    styleType={trackingOption === "month" ? "filled" : "outlined"}
                    size={"x-small"}
                  />
                  {/* </div> */}
                </div>
              </div>

              <div className={styles.trackingWidgetsContainer}>
                <div
                  onClick={() => dispatch(setActiveTrackingType("sleep"))}
                  style={{
                    borderColor:
                      activeTrackingType === "sleep" ? "#8c42e326" : "transparent",
                  }}
                  className={clsx(styles.trackingWidgetWrapper, {
                    [styles.activeTrackingWidgetWrapper]: activeTrackingType === "sleep",
                  })}
                >
                  <div
                    style={{ backgroundColor: "#8c42e326" }}
                    className={styles.iconWrapper}
                  >
                    <SleepIcon />
                  </div>
                  <p className={styles.title}>
                    {(averageSleep || 0) > 8
                      ? ">8"
                      : (+averageSleep?.toFixed(1) || 0) < 3 &&
                        (averageSleep?.toFixed(1) || 0) !== 0
                      ? "<3"
                      : averageSleep?.toFixed(1) || 0}{" "}
                    h
                  </p>
                  <span className={styles.subtitle}>Average sleep time</span>
                  <div className={styles.shareWrapper}>
                    <FacebookShareButton url={facebookShareUrl}>
                      <ShareIcon />
                      <p className={styles.sharetitle}>Share result</p>
                    </FacebookShareButton>
                  </div>
                </div>
                <div
                  onClick={() => dispatch(setActiveTrackingType("water"))}
                  style={{
                    borderColor:
                      activeTrackingType === "water"
                        ? "rgba(121, 220, 213, 0.2)"
                        : "transparent",
                  }}
                  className={clsx(styles.trackingWidgetWrapper, {
                    [styles.activeTrackingWidgetWrapper]: activeTrackingType === "water",
                  })}
                >
                  <div
                    style={{ backgroundColor: "rgba(121, 220, 213, 0.2)" }}
                    className={styles.iconWrapper}
                  >
                    <WaterIcon />
                  </div>
                  <p className={styles.title}>
                    {activeTrackingBtn === 0
                      ? averageWater?.flOz?.toFixed(1)
                      : (+averageWater?.l?.toFixed(1) || 0) > 3
                      ? ">3"
                      : (activeTrackingBtn === 0
                          ? averageWater?.flOz?.toFixed(1)
                          : averageWater?.l?.toFixed(1)) || 0}{" "}
                    {activeTrackingBtn === 0 ? "Fl Oz" : "l"}
                  </p>
                  <span className={styles.subtitle}>Water consumption</span>
                  <div className={styles.shareWrapper}>
                    <FacebookShareButton url={facebookShareUrl} title={"test"}>
                      <ShareIcon />
                      <p className={styles.sharetitle}>Share result</p>
                    </FacebookShareButton>
                  </div>
                </div>
                <div
                  onClick={() => dispatch(setActiveTrackingType("eat"))}
                  style={{
                    borderColor:
                      activeTrackingType === "eat"
                        ? "rgba(240, 81, 34, 0.2)"
                        : "transparent",
                  }}
                  className={clsx(styles.trackingWidgetWrapper, {
                    [styles.activeTrackingWidgetWrapper]: activeTrackingType === "eat",
                  })}
                >
                  <div
                    style={{ backgroundColor: "rgba(240, 81, 34, 0.2)" }}
                    className={styles.iconWrapper}
                  >
                    <img className={styles.fastingImg} src={Fasting} />
                    {/* <FastingIcon /> */}
                  </div>
                  <p className={styles.title}> {averageFasting?.toFixed(1) || 0} h</p>
                  <span className={styles.subtitle}>Fasting</span>
                  <div className={styles.shareWrapper}>
                    <FacebookShareButton url={facebookShareUrl} title={"test"}>
                      <ShareIcon />
                      <p className={styles.sharetitle}>Share result</p>
                    </FacebookShareButton>
                  </div>
                </div>
                <div
                  onClick={() => dispatch(setActiveTrackingType("weight"))}
                  style={{
                    borderColor:
                      activeTrackingType === "weight"
                        ? "rgba(96, 192, 85, 0.2)"
                        : "transparent",
                  }}
                  className={clsx(styles.trackingWidgetWrapper, {
                    [styles.activeTrackingWidgetWrapper]: activeTrackingType === "weight",
                  })}
                >
                  <div
                    style={{ backgroundColor: "rgba(96, 192, 85, 0.2)" }}
                    className={styles.iconWrapper}
                  >
                    <ChecklistIcon />
                  </div>
                  <p className={styles.title}>
                    {(activeTrackingBtn === 0
                      ? averageWeight?.ibs?.toFixed(1)
                      : averageWeight?.kg?.toFixed(1)) || 0}{" "}
                    {activeTrackingBtn === 0 ? "lbs" : "kg"}
                  </p>
                  <span className={styles.subtitle}>Weight loss since start</span>
                  <div className={styles.shareWrapper}>
                    <FacebookShareButton url={facebookShareUrl} title={"test"}>
                      <ShareIcon />
                      <p className={styles.sharetitle}>Share result</p>
                    </FacebookShareButton>
                  </div>
                </div>
                {/* <div
                onClick={() => dispatch(setActiveTrackingType("body"))}
                style={{
                  borderColor:
                    activeTrackingType === "body"
                      ? "rgba(252, 217, 48, 0.2)"
                      : "transparent",
                }}
                className={clsx(styles.trackingWidgetWrapper, {
                  [styles.activeTrackingWidgetWrapper]: activeTrackingType === "body",
                })}
              >
                <div
                  style={{ backgroundColor: "rgba(252, 217, 48, 0.2)" }}
                  className={styles.iconWrapper}
                >
                  <BodyIcon />
                </div>
                <p className={styles.title}>
                  {(activeTrackingBtn === 0
                    ? averageBody?.bust?.in?.toFixed(1)
                    : averageBody?.bust?.cm?.toFixed(1)) || 0}
                  /
                  {(activeTrackingBtn === 0
                    ? averageBody?.waist?.in?.toFixed(1)
                    : averageBody?.waist?.cm?.toFixed(1)) || 0}
                  /
                  {(activeTrackingBtn === 0
                    ? averageBody?.hips?.in?.toFixed(1)
                    : averageBody?.hips?.cm?.toFixed(1)) || 0}{" "}
                  {activeTrackingBtn === 0 ? "in" : "cm"}
                </p>
                <span className={styles.subtitle}>Lost since start</span>
                <div className={styles.shareWrapper}>
                  <ShareIcon />
                  <p className={styles.sharetitle}>Share result</p>
                </div>
              </div> */}
              </div>
              <TrackingBtn />
              {/* {renderTrackingBtn()} */}
              {trackingOption !== "day" && activeTrackingType !== "body" && (
                <div className={styles.trackingGraphContainer}>
                  <Suspense>
                    <StatisticChartsLazy />
                  </Suspense>
                </div>
              )}
              {/*{activeTrackingType === "body" && <BarChart />}*/}
            </div>
          )}
          {width <= 768 && <StatisticWidget />}
        </div>
        <DiscountPoint type="big" />
        {/* <FriendDiscount type="big" /> */}
      </div>
      {/* <Button
        title={"logout"}
        styleType={"outlined"}
        size={"medium"}
        onClick={() => onLogOut()}
      /> */}
    </div>
  );
};

export default withMainLayout(Profile, false, true);
