import React, { FC } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import styles from "./styles.module.scss";
// import "react-circular-progressbar/dist/styles.css";
import { useWindowDimensions } from "../../hooks";

interface IProps {
  value: number;
  maxValue: number;
  title: string;
}

const CountDownTimer: FC<IProps> = ({ value, maxValue, title }): JSX.Element => {
  const { width } = useWindowDimensions();

  const isMobile = width <= 768;

  const css = `/*
* react-circular-progressbar styles
* All of the styles in this file are configurable!
*/

.CircularProgressbar {
 /*
  * This fixes an issue where the CircularProgressbar svg has
  * 0 width inside a "display: flex" container, and thus not visible.
  */
 width: 100%;
 /*
  * This fixes a centering issue with CircularProgressbarWithChildren:
  * https://github.com/kevinsqi/react-circular-progressbar/issues/94
  */
 vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
 stroke: #3e98c7;
 stroke-linecap: round;
 -webkit-transition: stroke-dashoffset 0.5s ease 0s;
 transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
 stroke: #d6d6d6;
 /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
 stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
 fill: #3e98c7;
 font-size: 20px;
 dominant-baseline: middle;
 text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
 fill: #d6d6d6;
}

/*
* Sample background styles. Use these with e.g.:
*
*   <CircularProgressbar
*     className="CircularProgressbar-inverted"
*     background
*     percentage={50}
*   />
*/
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
 fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
 fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
 stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
 stroke: transparent;
}
`;

  return (
    <div className={styles.timerBlock}>
      <style>{css}</style>
      <CircularProgressbar
        value={value}
        maxValue={maxValue}
        text={`${value}`}
        styles={{
          root: {},
          path: {
            stroke: `#8C42E3`,
          },
          trail: {
            stroke: "#8C42E326",
            strokeWidth: 5,
          },
          text: {
            fontSize: isMobile ? "26px" : "36px",

            lineHeight: "1.17",
            fontWeight: 800,
            fontFamily: "Gilroy",
            fill: "#000",
          },
          background: {},
        }}
      />
      <span className={styles.timerTitle}>{title}</span>
    </div>
  );
};

export default CountDownTimer;
