import React, { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

import IntroImg from "../../assets/images/intro.png";
import Intro2Img from "../../assets/images/intro2.png";
import Intro3Img from "../../assets/images/intro3.jpg";
import Intro4Img from "../../assets/images/intro4.png";
import Intro5Img from "../../assets/images/intro5.png";
import Intro6Img from "../../assets/images/intro6.png";
import Intro6_1Img from "../../assets/images/intro6_1new.png";
import Intro6_2Img from "../../assets/images/intro6_2new.png";
import Intro7Img from "../../assets/images/intro7.png";
import Intro7_1Img from "../../assets/images/intro7_1new.png";
import Intro7_2Img from "../../assets/images/intro7_2new.png";

import { Progress, Button, CountDownTimer, ReceipsModal } from "../../components";
import { withMainLayout } from "../../layouts";
import { useWindowDimensions } from "../../hooks";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { CalendarIcon, CloseDayIcon, SaladIcon } from "../../assets/icons";
import { fetchProgress } from "../../store/actions/ProgressActions";
import { fetchBuyedBoostcamps } from "../../store/actions/AllBoostcampsActions";
import { getInfoBlocks } from "../../services/info_blocks.service";
import { ClipLoader } from "react-spinners";

interface IProps {}

interface ITimeLeft {
  days?: number;
  hour?: number;
  minutes?: number;
  seconds?: number;
}

const parseDate = (date: any) => {
  try {
    const res = JSON.parse(date);
    return res;
  } catch (e) {
    console.error("parseDate error", e);
    return date;
  }
};

const CountDownTimerStep = () => {
  const { startDate, progress } = useAppSelector((state) => state.processReducer);
  const progressBoostcamp = useAppSelector((state) => state.processReducer.progress);
  const [timeLeft, setTimeLeft] = useState<ITimeLeft>({});
  const location = useLocation();

  const calculateTimeLeft = (startDate: string) => {
    const date = new Date(startDate);
    date.setHours(0, 0, 0, 0);

    let difference = +new Date(date) - +new Date();

    let timeLeft: ITimeLeft = { days: 0, hour: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hour: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    setTimeLeft(timeLeft);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (location.state?.fromProgress) {
        calculateTimeLeft(`${progress?.startDate}`);
      } else if (startDate) {
        calculateTimeLeft(parseDate(startDate));
      } else {
        calculateTimeLeft(`${progressBoostcamp?.startDate}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [location, progress, startDate]);

  return (
    <div className={styles.timerWrapper}>
      <CountDownTimer value={timeLeft.days || 0} maxValue={30} title={"DAYS"} />
      <CountDownTimer value={timeLeft.hour || 0} maxValue={24} title={"HOURS"} />
      <CountDownTimer value={timeLeft.minutes || 0} maxValue={60} title={"MINUTES"} />
      <CountDownTimer value={timeLeft.seconds || 0} maxValue={60} title={"SECONDS"} />
    </div>
  );
};

const TimerStep = ({ data }: { data: any }): JSX.Element => {
  const { buyedBoostcamps } = useAppSelector((state) => state.allBoostcampsReducer);

  const hasBuyedBoostacamp = useMemo(
    () => !!buyedBoostcamps && buyedBoostcamps?.length > 0,
    [buyedBoostcamps]
  );

  return (
    <>
      {data?.image && (
        <img className={styles.preview} src={data?.image} alt={"Preview"} />
      )}
      <div className={styles.stepContent}>
        {!hasBuyedBoostacamp && <CloseDayIcon className={styles.blockIcon} />}
        <div
          className={clsx({
            [styles.blurWrapper]: !hasBuyedBoostacamp,
          })}
        >
          {data?.title && <h3>{data?.title}</h3>}
          <div className={clsx("html-wrapper", styles.html_wrapper)}>
            {data?.body && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: data?.body || "",
                }}
              />
            )}
          </div>
          {/* {data?.text?.length > 0 &&
            data?.text.map((text: string, index: number) => (
              <p className={styles.description} key={`step-${index}`}>
                {text}
              </p>
            ))} */}
        </div>
      </div>
    </>
  );
};

const Intro: FC<IProps> = (): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { width } = useWindowDimensions();
  const { selectedBoostcamp } = useAppSelector((state) => state.paymentsReducer);
  const { startDate, progress } = useAppSelector((state) => state.processReducer);
  const progressBoostcamp = useAppSelector((state) => state.processReducer.progress);
  const { user } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProgress());
  }, []);
  useEffect(() => {
    dispatch(fetchBuyedBoostcamps());
  }, []);

  const [isOpenReceips, setIsOpenReceips] = useState<boolean>(false);
  const [isOpenMealPlan, setIsOpenMealPlan] = useState<boolean>(false);

  const stepsData = [
    //1 step
    {
      img: IntroImg,
      title: `Welcome to our 28-day EXPRESS Boost Camp ${user?.firstName}!`,
      text: [
        "Congratulations on taking a significant step towards regaining control of your diet, weight, and overall health.",
        "Our goals for you on this program are to enhance your overall well-being, increase energy levels, improve sleep quality, boost the immune system, accelerate metabolism, kickstart health goals, and reduce inflammation.",
        "This 28 Day EXPRESS Boost Camp is where you'll learn about proper nutrition for weight loss, habit development, effective shopping, cooking delicious meals, managing sleep and stress, optimal drinking habits, hunger and cravings management and integrating these learnings into all aspects of life for sustainable weight loss and improved health.",
        "We are thrilled to be a part of your health and wellness journey and have meticulously designed our Boost Camp programs by integrating the most advanced scientific research into nutrition and wellness to maximise your potential for long-term health and achieve optimal results.",
      ],
    },
    //2 step
    {
      img: Intro2Img,
      title: `Get organised now for Boost Camp success!`,
      text: [
        "Start by following these suggestions to stay ahead and increase your chances of achieving your goals.",
        `In your pre-Boost Camp pack, we've included two complementary eBooks: "Bridget's Pickle Pantry" and "Fall in Love with Vegetables." Download them using the provided links.`,
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
        >
          <a
            href="https://bridgetshealthykitchen.lt.acemlnb.com/Prod/link-tracker?notrack=1&notrack=1&redirectUrl=aHR0cHMlM0ElMkYlMkZkcml2ZS5nb29nbGUuY29tJTJGZmlsZSUyRmQlMkYxdW1jU2tkZlg1UVV2Y2xtODRzYzVZTFZiWm5WWnNXa3olMkZ2aWV3JTNGdXNwJTNEc2hhcmluZw==&sig=4fDbqPuEneBqDMbcuQEyuaasVESxJDiY3gCYtMp4qFV6&iat=1685436130&a=%7C%7C89608414%7C%7C&account=bridgetshealthykitchen%2Eactivehosted%2Ecom&email=H3jq0TN2ZdS3s9Y1gR67MLYyBwh3nkoIPpXEmJ0kesxFnuxQ%2Bq0%3D%3Ag5oEQ5eE3UvlchxAWp1ffVb2R72Pnwzd&s=0c5fb26007d7893867dbbba3d13361ff&i=2265A2475A89A14308"
            target="_blank"
            rel="noreferrer"
          >
            Click here to download your complimentary eBook Bridget’s Pickle pantry
          </a>
          <a
            href="https://bridgetshealthykitchen.lt.acemlnb.com/Prod/link-tracker?notrack=1&notrack=1&redirectUrl=aHR0cHMlM0ElMkYlMkZkcml2ZS5nb29nbGUuY29tJTJGZmlsZSUyRmQlMkYxM3p0ZXZBamx5SnFwRjhjUWNLREdYZUY5ZjB5cGJ5MkslMkZ2aWV3JTNGdXNwJTNEc2hhcmluZw==&sig=BoFnPpUUe5VKdtgjbZuzehAgjYah5dbzk4uc9h5wwT3X&iat=1685436130&a=%7C%7C89608414%7C%7C&account=bridgetshealthykitchen%2Eactivehosted%2Ecom&email=H3jq0TN2ZdS3s9Y1gR67MLYyBwh3nkoIPpXEmJ0kesxFnuxQ%2Bq0%3D%3Ag5oEQ5eE3UvlchxAWp1ffVb2R72Pnwzd&s=0c5fb26007d7893867dbbba3d13361ff&i=2265A2475A89A14306"
            target="_blank"
            rel="noreferrer"
          >
            Click here to download your complimentary eBook Fall in love with vegetables
          </a>
        </div>,
        `Begin by selecting a few pickle recipes from "Bridget's Pickle Pantry" and start pickling.`,
        `These items are part of our meal plans. Use the "Fall in Love with Vegetables" eBook to familiarise yourself with diverse vegetable options for a well-rounded diet.`,
        `Preparation is crucial. Remember to order any necessary camp items promptly to avoid shipping delays caused by global postal issues. We'll emphasise this throughout the camp.`,
        `By getting organised and following these steps, you'll be well-prepared to make positive changes during Boost Camp.`,
        `Let's set the stage for an incredible journey ahead!`,
        <>
          Below is a list of the foods I suggest you start to source now as well as a link
          to our{" "}
          <a
            href="https://www.youtube.com/playlist?list=PLQXZMZXR3Phk_O5_MO6xT0B3cImAy3ki7"
            target="_blank"
            rel="noreferrer"
          >
            Exclusive Boost Camp Masterclass
          </a>{" "}
          videos to help you to understand the different ingredients, where to buy them
          and why they are so good for our body
        </>,
        <>
          Tamari sauce (gluten free, ideally low sodium) Grass fed gelatine Kombu / Large
          sheets of dried kelp or seaweed Wakame / Small dried seaweed pieces Green banana
          flour 1X Pure as inulin (available from{" "}
          <a
            href="https://bridgets.shop/products/inulin-500g-100-pure-as-chicory-inulin-prebiotic-tested-certified"
            target="_blank"
            rel="noreferrer"
          >
            bridget's.shop
          </a>{" "}
          NZ and Aus) 1X Sweet as Fibre (available from{" "}
          <a
            href="https://bridgets.shop/products/fibre-syrup-sweet-as-fibre-syrup-100-chicory-prebiotic-tested-certified"
            target="_blank"
            rel="noreferrer"
          >
            bridget's.shop
          </a>{" "}
          NZ and Aus) 1X Zero as sugar pure erythritol (available from{" "}
          <a
            href="https://bridgets.shop/products/pure-erythritol-pure-erythritol-500g"
            target="_blank"
            rel="noreferrer"
          >
            bridget's.shop
          </a>{" "}
          for NZ and Aus)
        </>,
        `*For everyone outside of Australia and New Zealand, click on the HELP button and send us a message for a list of stockists. Thank you!`,
      ],
    },
    //3 step
    {
      img: Intro3Img,
      title: `This is an Express Boost Camp, but what does that Mean?`,
      text: [
        "During this camp, the meals I have created are designed to be prepared quickly and easily.",
        "There is still prep to be done that includes preparing sauces, stocks, pastes, complementary pickles and foundation recipes, but once you have finished prepping these recipes, the day to day meals on the plan can be quickly prepared without having to spend hours in the kitchen every day.",
        "These meals are designed with the busy person in mind, and the more you practise cooking and prepping, the faster you will become!",
        "So enjoy your kitchen workout and enjoy supporting your body and mind in the best possible way ever.",
      ],
    },
    //4 step
    {
      img: Intro4Img,
      title: `Shopping and Prepping`,
      text: [
        "I suggest you complete your shopping 2-3 days before you start Day 1 of the plan (see weekly shopping lists).",
        "This will give you enough time to allocate for preparation.",
        "I also suggest you break the preparation up over a couple of days, allowing 3-4 hours a day as you learn the ropes.",
        "Keep in mind, for the following week you will be saving a significant amount of time as your meals will already be prepped.",
        "As we say on Boost Camp, it is faster than fast food 🙂",
        "No more worrying about what to eat.",
        "No more worrying about what to go shop for.",
        "All the heavy lifting has been done for you.",
        "All you have to do is invest the time to read, prep and enjoy your restaurant quality meals.",
        "Know this.",
        "Preparing your food before each week starts is one of the most important experiences you will accomplish throughout your Boost Camp experience.",
        "Not only will it save you a lot of time, it is one of the most effective ways for you to sustainably take back control of your health.",
        "You will now be enjoying meals that are designed with science and created with love for you to look and feel amazing like the thousands of people who have completed Boost Camp over the years.",
        "Like anything in life, it will get easier and quicker the more you practise, so be gentle with yourself, allow enough time and start practising your healthy cooking now!",
      ],
    },
    //5 step
    {
      img: Intro5Img,
      title: `Are you a Fresh or Fast Camper?`,
      text: [
        "Before you start to get organised with shopping and meal prepping, you first need to decide what level of camp you want to do. This camp offers two options and depending on where you are in your health journey will determine which level of camp you choose to do.",
        ,
        <>
          <b>Fresh Camping</b> is designed if you are a first time camper or haven't done
          a camp for a while and need a refresh. It focuses on gut health, optimum weight
          loss, habit creation, and getting you into the swing of cooking and eating the
          right foods that will support your journey. It does not involve intermittent
          fasting so is ideal for anyone who is not sure about fasting or can’t fast due
          to existing medication.
        </>,
        <>
          <b>Fast Camping</b> is designed for experienced campers or experienced fasters.
          It involves gut health as well as time restricted eating which is ideal for
          weight loss, improving insulin sensitivity, for anyone whose weight has
          plateaued or for anyone wanting to learn a sustainable lifestyle to maintain
          their weight and wellbeing.
        </>,
      ],
    },
    //6 step
    {
      img: Intro6Img,
      title: `Here is a quick and easy guide on how to locate :`,
      text: [
        <>
          <ul className={styles.preview_list}>
            <li>Your meal plan</li>
            <li>Shopping lists and other material</li>
            <li>How to find a recipe</li>
          </ul>
        </>,
        <>
          <h3>For mobile : </h3>
        </>,
        <>
          To locate and download your <b>MEAL PLAN,</b> click on the icon highlighted in
          green icon or a download option is available on slide 7.
        </>,
        <>
          <img src={Intro6_1Img} className={styles.preview_image} />
        </>,
        <>
          The Meal Plan icon is also where you will find the recipes that you will be
          using on Boost Camp, to get the recipe simply click on the recipe <b>IMAGE</b>
          and it will open for you.
        </>,
        <>
          <img src={Intro6_2Img} className={styles.preview_image} />
        </>,
        <>
          To download your <b>Shopping List</b>, click on the icon located in the below
          image, then simply click on <b>“DOWNLOAD PDF”</b>
        </>,
        <>
          <ul className={styles.preview_list}>
            <li>Meal prep list for weeks 1 - 4</li>
            <li>Meal add on list</li>
            <li>Approved Camp Beverages</li>
            <li>
              Go Food No Food guide, click{" "}
              <a
                href="https://www.youtube.com/watch?v=OmSzwO-ONx4&feature=youtu.be"
                target="_blank"
                rel="noreferrer"
              >
                HERE
              </a>{" "}
              to watch a video on how to use this. Please also note that this has not been
              emailed to you and is available by downloading in this APP.
            </li>
          </ul>
        </>,
      ],
    },
    //7 step
    {
      img: Intro7Img,
      title: `If you are using a PC or laptop, locating the Meal Plan and Shopping list is very simple, just click on the indicated words.`,
      text: [
        <>
          <img src={Intro7_1Img} className={styles.preview_image} />
        </>,
        <>
          Then click on download for your <b>Meal Plan PDF & Download PDF</b> for your
          shopping list and other Boost Camp guides.
        </>,
        <>
          <img src={Intro7_2Img} className={styles.preview_image} />
        </>,
        <>Included with your shopping list, you will also receive the below:</>,
        <>
          <ul className={styles.preview_list}>
            <li>Meal prep list for weeks 1 - 4</li>
            <li>Meal add on list</li>
            <li>Approved Camp Beverages</li>
            <li>
              Go Food No Food guide, click{" "}
              <a
                href="https://www.youtube.com/watch?v=OmSzwO-ONx4&feature=youtu.be"
                target="_blank"
                rel="noreferrer"
              >
                HERE
              </a>{" "}
              to watch a video on how to use this. Please also note that this has not been
              emailed to you and is available by downloading in this APP.
            </li>
          </ul>
        </>,
        <>
          <h3>*Supplements and medical clearance</h3>
        </>,
        <>
          **Please note that if you are currently taking medication, feeling sick or are
          under the medical care of a health professional, please be advised that we
          recommend that you seek the advice of your healthcare professional before
          undertaking any new dietary changes.
        </>,
        <>
          If you are currently taking supplements please feel free to continue with your
          current dosage or see your healthcare professional for further advice.
        </>,
        <>
          Remember that results may vary. Exercise and proper diet are necessary to
          achieve and maintain weight loss.
        </>,
      ],
    },
    // {
    //   title: `*Supplements and medical clearance`,
    //   text: [
    //     <>
    //       **Please note that if you are currently taking medication, feeling sick or are
    //       under the medical care of a health professional, please be advised that we
    //       recommend that you seek the advice of your healthcare professional before
    //       undertaking any new dietary changes.
    //     </>,
    //     <>
    //       If you are currently taking supplements please feel free to continue with your
    //       current dosage or see your healthcare professional for further advice.
    //     </>,
    //     <>
    //       Remember that results may vary. Exercise and proper diet are necessary to
    //       achieve and maintain weight loss.
    //     </>,
    //   ],
    // },
  ];

  const [infoBlocksState, setInfoBlocksState] = useState([]);
  const [infoBlocksLoading, setInfoBlocksLoading] = useState<boolean>(false);

  const maxSteps = useMemo(() => infoBlocksState?.length || 0, [infoBlocksState?.length]);

  const isMobile = width <= 768;
  const location = useLocation();
  const navigate = useNavigate();

  const datePased = useMemo(
    () =>
      +new Date(
        location.state?.fromProgress
          ? `${progress?.startDate}`
          : startDate
          ? parseDate(startDate)
          : progressBoostcamp?.startDate || null
      ) < +new Date(),
    [location, progress, startDate]
  );

  const nextStep = (): void => {
    if (currentStep === maxSteps) {
      navigate("/home");
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = (): void => {
    setCurrentStep((prev) => prev - 1);
  };

  useEffect(() => {
    if (location.state?.fromProgress) {
      setCurrentStep(4);
    }
  }, [location]);

  const getInfoStepsData = async () => {
    try {
      setInfoBlocksLoading(true);
      const { error, result } = await getInfoBlocks();
      console.log("result", result);

      if (!error && result?.data?.success && result?.data?.data) {
        setInfoBlocksState(result?.data?.data);
      }
    } catch (e) {
      console.log("getInfoStepsData error", e);
    } finally {
      setInfoBlocksLoading(false);
    }
  };

  useEffect(() => {
    getInfoStepsData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentStep]);

  return (
    <div className={styles.wrapper}>
      {!infoBlocksLoading ? (
        <div className={styles.contentContainer}>
          <Progress
            currentStep={currentStep}
            maxStep={maxSteps}
            setCurrentStep={setCurrentStep}
          />
          {infoBlocksState &&
            infoBlocksState?.length >= currentStep &&
            infoBlocksState[currentStep - 1] && (
              <>
                {!!startDate && !datePased && <CountDownTimerStep />}
                <TimerStep
                  data={infoBlocksState[currentStep - 1]}
                  key={"timerStep-" + currentStep}
                />
              </>
            )}
          {/* {currentStep === maxSteps && progress?.mealPlan?.length > 0 && (
            <div className={styles.receipesBtnWrapper}>
              <Button
                styleType="filled"
                size={width <= 768 ? "small" : "medium"}
                title="Meal Plan"
                backgroundColor="#60C055"
                leftIcon={<CalendarIcon style={{ width: "26px", height: "auto" }} />}
                onClick={() => {
                  if (progress?.id) {
                    navigate(`/meal-plan/${progress?.id}`);
                  }

                  // setIsOpenMealPlan(true);
                  // setIsOpenReceips(true);
                }}
                style={{
                  backgroundColor: "#60C055",
                  width: "100%",
                  maxWidth: "407px",
                }}
              />
              <Button
              styleType="filled"
              size={width <= 768 ? "small" : "medium"}
              title="Recipes"
              backgroundColor="#F05122"
              leftIcon={<SaladIcon />}
              style={{
                width: "100%",
                maxWidth: "407px",
                backgroundColor: "#F05122",
              }}
              onClick={() => {
                setIsOpenMealPlan(false);
                setIsOpenReceips(true);
              }}
            />
            </div>
          )} */}

          <div className={styles.btnWrapper}>
            {currentStep === 1 ? (
              <div />
            ) : (
              <Button
                title={"Previous"}
                styleType={"outlined"}
                size={width <= 768 ? "small" : "medium"}
                style={{
                  width: width <= 768 ? "146px" : "217px",
                  color: "#8C42E3",
                  borderColor: "#8C42E3",
                }}
                disabled={currentStep === 1}
                onClick={prevStep}
              />
            )}
            <Button
              // title={currentStep === maxSteps ? "Finish" : "Next"}
              title="Next"
              styleType={"filled"}
              size={width <= 768 ? "small" : "medium"}
              style={{ width: width <= 768 ? "146px" : "217px" }}
              onClick={nextStep}
            />
          </div>
        </div>
      ) : (
        <div className={styles.loader}>
          <ClipLoader color={"#8C42E3"} size={30} />
        </div>
      )}
      <ReceipsModal
        isOpen={isOpenReceips}
        setIsOpen={setIsOpenReceips}
        receipes={selectedBoostcamp?.recipes}
        startDate={progress?.startDate || new Date()}
        isMealPlan={isOpenMealPlan}
      />
    </div>
  );
};

export default withMainLayout(Intro, false, true);
