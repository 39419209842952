import React, { FC } from "react";
import styles from "./styles.module.scss";
import { CloseDayIcon, CompleteIcon, CrossBigIcon } from "../../assets/icons";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux";
import { setCurrentDay } from "../../store/reducers/ProgressSlice";
import { useWindowDimensions } from "../../hooks";

interface IProps {
  status:
    | "closed"
    | "opened"
    | "complete"
    | "opened-completed"
    | "current"
    | "not-completed"
    | "opened-not-completed";
  image: string;
  title: string;
  description: string;
  index: number;
  idBoostcamp: string;
}

const DayItem: FC<IProps> = ({
  status = "closed",
  image = "",
  title = "",
  description = "",
  index = 0,
  idBoostcamp,
}): JSX.Element => {
  console.log({ index, status });

  const navigate = useNavigate();
  const displatch = useAppDispatch();

  const { width } = useWindowDimensions();

  const onClick = () => {
    if (status !== "closed") {
      displatch(setCurrentDay(index));
    }
  };

  const iconWidth = width <= 768 ? "30px" : "auto";

  return (
    <div
      onClick={onClick}
      className={clsx(styles.wrapper, {
        [styles.openedWrapper]:
          status === "opened-completed" ||
          status === "opened-not-completed" ||
          status === "opened",
        [styles.blurWrapper]: status === "closed",
      })}
    >
      <div
        className={clsx(styles.imgWrapper, {
          [styles.blur]: status === "closed",
        })}
      >
        <img
          src={image}
          alt={"preview"}
          className={clsx(styles.preview, {
            [styles.blur]: status === "closed",
          })}
        />
      </div>

      {(status === "not-completed" || status === "opened-not-completed") && (
        <div className={clsx(styles.dayWrapper, styles.notcompleteDayWrapper)}>
          <CrossBigIcon style={{ width: iconWidth }} />
        </div>
      )}

      {(status === "complete" || status === "opened-completed") && (
        <div className={clsx(styles.dayWrapper, styles.completeDayWrapper)}>
          <CompleteIcon style={{ width: iconWidth }} />
        </div>
      )}

      {status === "closed" && (
        <div className={clsx(styles.dayWrapper, styles.closeDayWrapper)}>
          <CloseDayIcon style={{ width: iconWidth }} />
        </div>
      )}

      <div className={styles.contentContainer}>
        <h5
          style={{
            color:
              status === "complete" || status === "opened-completed"
                ? "#60c055"
                : status === "opened-not-completed"
                ? "#ee3758"
                : "#131313",
          }}
        >
          {title}
        </h5>
        <p className={status === "closed" ? styles.blur : ""}>{description}</p>
      </div>
    </div>
  );
};

export default DayItem;
