import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { SleepIcon } from "../../assets/icons";
import Button from "../Button";
import styles from "./styles.module.scss";
import Slider from "rc-slider";
import "./index.css";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { setTrackers } from "../../services/progress.service";
import {
  setIsOpenSleepTracker,
  setProgress,
  setProgressDay,
} from "../../store/reducers/ProgressSlice";
import { ITrackers } from "../../models/ITrackers";
import { IProgress } from "../../models/IProgress";
import { TRACKERS } from "../../constants";
import { updateProgressDay } from "../../utils/progress";

interface IProps {
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
}

const SleepTracker: FC<IProps> = ({
  currentProgress,
  setCurrentProgress,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { currentDay, isOpenSleepTracker, progress } = useAppSelector(
    (state) => state.processReducer
  );
  const [sleepOptions, setSleepOptions] = useState(TRACKERS.sleepOptins);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(2);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const onClose = () => {
    dispatch(setIsOpenSleepTracker(false));
  };

  const onChangeSleep = async () => {
    try {
      setIsLoadingSubmit(true);

      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          sleep: currentValue,
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          onClose();
        }
      }
      setIsLoadingSubmit(false);
    } catch (error) {
      setIsLoadingSubmit(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const sleepValue = handledProgress?.days[currentDay]?.trackers?.sleep;
    setCurrentValue(sleepValue || 2);
    const index = TRACKERS.sleepOptins.findIndex((item) => item.value === sleepValue);
    setActiveIndex(index > 0 ? index : 0);
  }, [handledProgress, currentDay, isOpenSleepTracker]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpenSleepTracker}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <SleepIcon />
          </div>
          <h5>
            How much time did <br /> you sleep today?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <h2>{sleepOptions[activeIndex]?.label}</h2>
          <p className={styles.recommended}>
            Recommended time sleep: <b>8 hours</b>
          </p>
          <Slider
            min={0}
            max={7}
            defaultValue={activeIndex}
            onChange={(value: any) => {
              setActiveIndex(value);
              setCurrentValue(sleepOptions[value]?.value);
            }}
            trackStyle={{ height: "15px", backgroundColor: "#8C42E3" }}
            railStyle={{ height: "15px" }}
            handleStyle={{
              height: "26px",
              width: "26px",
              backgroundColor: "#fff",
              borderColor: "#8C42E3",
              borderWidth: "8px",
              opacity: 1,
            }}
          />
          <div className={styles.sliderOptionsWrapper}>
            {sleepOptions?.map((item, index) => {
              return (
                <span className={styles.sliderOption} key={index}>
                  {item?.label}
                </span>
              );
            })}
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          onClick={onChangeSleep}
          loading={isLoadingSubmit}
        />
      </div>
    </Modal>
  );
};

export default SleepTracker;
