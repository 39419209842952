import { FC, useEffect, useMemo, useState } from "react";
import { withMainLayout } from "../../layouts";
import styles from "./styles.module.scss";
import clsx from "clsx";
import {
  ArrowLeftBigIcon,
  ArrowRightBigIcon,
  CalendarIcon,
  CrossIcon,
} from "../../assets/icons";
import { useParams } from "react-router-dom";
import {
  getBoostcamp,
  getBoostcampMeal,
  getMealPlan,
  getMealPlanDay,
} from "../../services/boostcamps.service";
import ClipLoader from "react-spinners/ClipLoader";
import { IBoostcamp } from "../../models/IBoostcamp";
import { Button, RecipeItem } from "../../components";
import { useWindowDimensions } from "../../hooks";
import Modal from "react-modal";
import { openFilePrint } from "../../services/file.service";
import axios from "axios";
import { getToken } from "../../localStorage";

const MealInfoModal = ({
  isOpen,
  setIsOpen,
  title,
  desc,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  desc: string;
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={styles.infoModal}
      onRequestClose={() => setIsOpen(false)}
      overlayClassName={styles.infoModalOverlay}
      shouldFocusAfterRender={false}
    >
      <div className={styles.infoModalInner}>
        <div onClick={() => setIsOpen(false)} className={styles.closeBtn}>
          <CrossIcon width={30} height={30} style={{ color: "#fff" }} />
        </div>
        <div className={styles.infoModalWrapper}>
          <span className={styles.infoModalTitle}>{title}</span>
          <div className={"html-wrapper"}>
            <div
              className={clsx(styles.infoModalDescription)}
              dangerouslySetInnerHTML={{
                __html: desc || "",
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const MealPlan: FC = (): JSX.Element => {
  const params = useParams();
  // const [isLoadingBoostcamp, setIsLoadingBoostcamp] = useState(false);
  // const [boostcamp, setBoostcamp] = useState<IBoostcamp | null>(null);
  const [isLoadingBoostcampMeal, setIsLoadingBoostcampMeal] = useState(false);
  const [boostcampMeal, setBoostcampMeal] = useState<any[] | null>(null);
  const [boostcampMealPdf, setBoostcampMealPdf] = useState<string | null>(null);
  const [currentWeek, setCurrentWeek] = useState<number>(0);
  const [mealLength, setMealLength] = useState<number>(2);
  const [activeDayOrder, setActiveDayOrder] = useState<number>(0);
  const [activeDay, setActiveDay] = useState<any>(null);
  const [isLoadingMealPlan, setIsLoadingMealPlan] = useState<boolean>(false);
  const [isLoadingDownloadMealPlan, setIsLoadingDownloadMealPlan] =
    useState<boolean>(false);
  const [isLoadingMealPlanDay, setIsLoadingMealPlanDay] = useState<boolean>(false);

  const [currentMealPlan, setCurrentMealPlan] = useState<any>(null);
  const [currentMealPlanDays, setCurrentMealPlanDays] = useState<any[]>([]);
  const [currentMealPlanDay, setCurrentMealPlanDay] = useState<any>(null);
  const { width } = useWindowDimensions();

  const [isOpenBeveragesModal, setIsOpenBeveragesModal] = useState(false);
  const [isOpenAddOnModal, setIsOpenAddOnModal] = useState(false);
  const [isOpenAddOnSecondModal, setIsOpenAddOnSecondModal] = useState(false);

  const [isOpenPrepListModal, setIsOpenPrepListModal] = useState(false);
  const [prepList, setPrepList] = useState<any>(null);
  // const [prepListSecond, setPrepListSecond] = useState<any>(null);

  // const onGetBoostcamp = async () => {
  //   try {
  //     setIsLoadingBoostcamp(true);
  //     const { result, error } = await getBoostcamp(params?.id || "");
  //     if (result?.data?.success && !error) {
  //       setBoostcamp(result?.data?.data);
  //       // setMealLength(result?.data?.data?.mealPlan?.length || 2);
  //     }

  //     setIsLoadingBoostcamp(false);
  //   } catch (e) {
  //     setIsLoadingBoostcamp(false);
  //   }
  // };

  const onGetBoostcampMeal = async () => {
    try {
      setIsLoadingBoostcampMeal(true);
      const { result, error } = await getBoostcampMeal(params?.id || "");
      if (result?.data?.success && !error) {
        console.log("onGetBoostcampMeal", result);
        setBoostcampMeal(result?.data?.data);
        setMealLength(result?.data?.data?.length || 2);
        const findedMealPlan = result?.data?.data?.find((m: any) => !!m?.mealPlanPdf);
        console.log("onGetBoostcampMeal findedMealPlan", findedMealPlan);
        setBoostcampMealPdf(findedMealPlan?.mealPlanPdf);
      }

      setIsLoadingBoostcampMeal(false);
    } catch (e) {
      setIsLoadingBoostcampMeal(false);
    }
  };

  const onGetMealPlan = async (id: string) => {
    try {
      setIsLoadingMealPlan(true);
      const { result, error } = await getMealPlan(id);
      if (result?.data?.success) {
        setCurrentMealPlan(result?.data?.data);
        const findedPrepList = result.data?.data?.prepLists?.find(
          (pList: any) => pList?.type === "first"
        );
        setPrepList(findedPrepList);
        // const findedPrepListSecond = result.data?.data?.prepLists?.find(
        //   (pList: any) => pList?.type === "second"
        // );
        // setPrepListSecond(findedPrepListSecond);
      }
      setIsLoadingMealPlan(false);
    } catch (e) {
      setIsLoadingMealPlan(false);
    }
  };

  const onGetMealPlanDay = async (id: string, day: number) => {
    try {
      setIsLoadingMealPlanDay(true);
      const { result, error } = await getMealPlanDay(id, day);
      if (result?.data?.success && result?.data?.data?.week?.length > 0) {
        setCurrentMealPlanDay(result?.data?.data?.week[0]);
        setCurrentMealPlanDays((prev) => [...prev, result?.data?.data?.week[0]]);
      }
      setIsLoadingMealPlanDay(false);
    } catch (e) {
      setIsLoadingMealPlanDay(false);
    }
  };

  useEffect(() => {
    if (boostcampMeal && boostcampMeal?.length > 0 && boostcampMeal[currentWeek]) {
      onGetMealPlan(boostcampMeal[currentWeek].id);
    }
  }, [boostcampMeal, currentWeek]);

  useEffect(() => {
    if (boostcampMeal && boostcampMeal?.length > 0 && boostcampMeal[currentWeek]) {
      const findedMealPlanDay = currentMealPlanDays?.find(
        (day) => day?._id === activeDay?._id
      );

      if (findedMealPlanDay) setCurrentMealPlanDay(findedMealPlanDay);
      else if (activeDay?.order != null) {
        onGetMealPlanDay(boostcampMeal[currentWeek].id, activeDay.order || 0);
      }
    }
  }, [boostcampMeal, currentWeek, activeDay]);

  const onDownloadMealPlan = async () => {
    try {
      if (boostcampMealPdf) {
        console.log(1);
        setIsLoadingDownloadMealPlan(true);
        // await fileDownloader(boostcamp?.mealPlanPdf, "Meal Plan");
        await openFilePrint(boostcampMealPdf, "Meal Plan");
        console.log(2);
        setIsLoadingDownloadMealPlan(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // onGetBoostcamp();
    onGetBoostcampMeal();
  }, []);

  const weekTitle = useMemo(
    () =>
      mealLength === 2
        ? currentWeek === 0
          ? "Week 1 + 3"
          : currentWeek === 1 && "Week 2 + 4"
        : mealLength === 4 && currentWeek === 0
        ? "Week 1"
        : currentWeek === 1
        ? "Week 2"
        : currentWeek === 2
        ? "Week 3"
        : currentWeek === 3 && "Week 4",
    [currentWeek, mealLength]
  );

  const prepListTitle = useMemo(
    () => prepList?.title || `${weekTitle} PREP LIST`,
    [currentWeek, mealLength, prepList]
  );
  const prepListDescription = useMemo(() => prepList?.description || "", [prepList]);

  useEffect(() => {
    const findedDay = currentMealPlan?.week?.find(
      (item: any, index: number) =>
        item?.order === activeDayOrder || index === activeDayOrder
    );
    setActiveDay(findedDay || null);
  }, [currentMealPlan, activeDayOrder]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          onClick={() =>
            setCurrentWeek((prev) => {
              if (prev > 0) {
                return prev - 1;
              } else {
                return prev;
              }
            })
          }
          className={clsx(styles.prevBtn, { [styles.prevBtnInactive]: false })}
        >
          <ArrowLeftBigIcon />
        </div>
        <span className={styles.title}>{weekTitle}</span>
        <div
          onClick={() =>
            setCurrentWeek((prev) => {
              if (mealLength === 2) {
                if (prev === 0) {
                  return prev + 1;
                } else {
                  return prev;
                }
              } else if (mealLength === 4) {
                if (prev < 3) {
                  return prev + 1;
                } else {
                  return prev;
                }
              }
              return prev;
            })
          }
          className={clsx(styles.prevBtn, { [styles.prevBtnInactive]: false })}
        >
          <ArrowRightBigIcon />
        </div>
      </div>
      {isLoadingMealPlan || isLoadingBoostcampMeal ? (
        <div className={styles.loader}>
          <ClipLoader color={"#8C42E3"} size={30} />
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div className={styles.menuContainer}>
            {!isLoadingMealPlan &&
              !isLoadingBoostcampMeal &&
              boostcampMealPdf &&
              width > 768 && (
                <Button
                  onClick={onDownloadMealPlan}
                  loading={isLoadingDownloadMealPlan}
                  leftIcon={<CalendarIcon style={{ width: "26px", height: "auto" }} />}
                  title={"View or Print Meal Plan"}
                  styleType={"filled"}
                  size={"x-small"}
                  style={{ background: "#60C055", borderRadius: 24, height: 48 }}
                />
              )}
            {!isLoadingMealPlan &&
              !isLoadingBoostcampMeal &&
              currentMealPlan?.prepLists?.length > 0 && (
                <>
                  <Button
                    onClick={() => setIsOpenPrepListModal(true)}
                    title={prepListTitle}
                    styleType={"filled"}
                    size={"x-small"}
                    style={{ background: "#60C055", borderRadius: 24, height: 48 }}
                  />
                  <MealInfoModal
                    isOpen={isOpenPrepListModal}
                    setIsOpen={setIsOpenPrepListModal}
                    title={prepListTitle}
                    desc={prepListDescription}
                  />
                </>
              )}
            <div className={styles.daysContainer}>
              {!isLoadingMealPlan &&
                !isLoadingBoostcampMeal &&
                currentMealPlan?.week?.map((item: any, index: number) => {
                  const itemOrder = item?.order || index;
                  return (
                    <div
                      key={itemOrder}
                      onClick={() => {
                        setActiveDayOrder(itemOrder);
                      }}
                      className={clsx(styles.dayItem, {
                        [styles.dayItemActive]: activeDayOrder === itemOrder,
                      })}
                    >
                      <span>{width <= 768 ? item?.title?.slice(0, 1) : item?.title}</span>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={styles.recipeContainer}>
            {isLoadingMealPlanDay && !isLoadingMealPlan && !isLoadingBoostcampMeal ? (
              <div className={styles.loader}>
                <ClipLoader color={"#8C42E3"} size={30} />
              </div>
            ) : (
              <>
                {currentMealPlanDay?.meal?.map((item: any, index: number) => {
                  if (item?.recipes?.length <= 0) return;
                  return (
                    <div key={item?.title} className={styles.recipeItemWrapper}>
                      <span className={styles.title}>{item?.title}</span>
                      <div className={styles.recipeItemContainer}>
                        {item?.recipes?.map((item: any, index: any) => {
                          return <RecipeItem key={item?.id} item={item} />;
                        })}
                      </div>
                    </div>
                  );
                })}
                <div className={styles.recipeButtonsWrapper}>
                  {currentMealPlanDay?.beverages?.enabled && (
                    <>
                      <Button
                        onClick={() => setIsOpenBeveragesModal(true)}
                        title={currentMealPlanDay?.beverages?.title}
                        styleType={"filled"}
                        size={"x-small"}
                        className={styles.recipeButton}
                      />
                      <MealInfoModal
                        isOpen={isOpenBeveragesModal}
                        setIsOpen={setIsOpenBeveragesModal}
                        title={currentMealPlanDay?.beverages?.title}
                        desc={currentMealPlanDay?.beverages?.desc || ""}
                      />
                    </>
                  )}
                  {currentMealPlanDay?.addOn?.enabled && (
                    <>
                      <Button
                        onClick={() => setIsOpenAddOnModal(true)}
                        title={currentMealPlanDay?.addOn?.title}
                        styleType={"filled"}
                        size={"x-small"}
                        className={styles.recipeButton}
                      />
                      <MealInfoModal
                        isOpen={isOpenAddOnModal}
                        setIsOpen={setIsOpenAddOnModal}
                        title={currentMealPlanDay?.addOn?.title}
                        desc={currentMealPlanDay?.addOn?.desc || ""}
                      />
                    </>
                  )}
                  {currentMealPlanDay?.addOnSecond?.enabled && (
                    <>
                      <Button
                        onClick={() => setIsOpenAddOnSecondModal(true)}
                        title={currentMealPlanDay?.addOnSecond?.title}
                        styleType={"filled"}
                        size={"x-small"}
                        className={styles.recipeButton}
                      />
                      <MealInfoModal
                        isOpen={isOpenAddOnSecondModal}
                        setIsOpen={setIsOpenAddOnSecondModal}
                        title={currentMealPlanDay?.addOnSecond?.title}
                        desc={currentMealPlanDay?.addOnSecond?.desc || ""}
                      />
                    </>
                  )}
                </div>
                <div className={styles.recipeItemWrapper}>
                  <span className={styles.title}>{currentMealPlan?.title}</span>
                  <div className={"html-wrapper"}>
                    <div
                      className={clsx(styles.descriptionWrapper)}
                      dangerouslySetInnerHTML={{
                        __html: currentMealPlan?.description || "",
                      }}
                    />
                  </div>
                  {/* <div className={styles.descriptionWrapper}>
                <p>{currentMealPlan?.description}</p>
              </div> */}
                </div>
              </>
            )}
            {!isLoadingMealPlan &&
              !isLoadingBoostcampMeal &&
              boostcampMealPdf &&
              width <= 768 && (
                <Button
                  onClick={onDownloadMealPlan}
                  size={"x-small"}
                  styleType={"filled"}
                  loading={isLoadingDownloadMealPlan}
                  title={"View or Print Meal Plan"}
                  leftIcon={<CalendarIcon style={{ width: "26px", height: "auto" }} />}
                  style={{ background: "#60C055", borderRadius: 24 }}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withMainLayout(MealPlan, true, true, true);
