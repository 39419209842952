import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import styles from "./styles.module.scss";
import "./styles.scss";
import { CookingIcon, IngredientIcon, PrintIcon } from "../../assets/icons";
import RecipePreview from "../RecipePreview";
import Button from "../Button";
import { useAppSelector } from "../../hooks/redux";

const RecipeItem = ({ item }) => {
  const { progress } = useAppSelector((state) => state.processReducer);

  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      console.log("onBeforePrint");
      // setIsPrintLoading(false)
    },
    onAfterPrint: () => {
      console.log("onAfterPrint");
      setIsPrintLoading(false);
    },
    documentTitle: item?.title || "Recipe Title",
  });

  const onPrint = () => {
    try {
      setIsPrintLoading(true);
      handlePrint();
    } catch (e) {
      console.error(e);
      setIsPrintLoading(false);
    }
  };

  function replaceVideoWithLinks(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const iframes = doc.querySelectorAll("iframe");
    iframes.forEach((iframe) => {
      const url = iframe.getAttribute("src");
      if (url) {
        const videoLink = doc.createElement("a");
        videoLink.textContent = "Video Link";
        videoLink.href = url;
        videoLink.className = "printItem_videoLink";
        iframe.replaceWith(videoLink);
      }
    });

    return doc.documentElement.outerHTML;
  }

  const renderName = (it) => {
    if (!!it?.hyperLink) {
      return (
        <span>
          <a
            className={"printItem_ingredientItemLink"}
            href={it?.hyperLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {it?.ingredient?.name}
          </a>
        </span>
      );
    } else {
      return <span>{it?.ingredient?.name}</span>;
    }
  };

  return (
    <>
      <RecipePreview
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        preview={item?.image || ""}
        title={item?.title}
        ingredients={item?.ingredients}
        tags={item?.tags}
        description={item?.preparation}
        cookTimeHours={item?.cookTime?.hours ? `${item?.cookTime?.hours} h` : ""}
        cookTimeMinutes={item?.cookTime?.minutes ? `${item?.cookTime?.minutes} mins` : ""}
      />
      <div
        onClick={() => {
          setIsOpenModal(true);
        }}
        className={styles.wrapper}
      >
        <img
          src={item?.image}
          className={styles.img}
          alt={"Recipe Image"}
          loading="lazy"
          width={70}
          height={60}
        />
        <div className={styles.infoWrapper}>
          <p className={styles.title}>{item?.title}</p>
          {/* <span className={styles.ingredientTitle}>
            <IngredientIcon style={{ color: "#8C42E3" }} />
            <span>{item?.ingredients?.length} ingredients</span>
          </span> */}
          {/* <span className={styles.cookTime}>
            <CookingIcon style={{ color: "#8C42E3" }} />
            <span>
              {item?.cookTime?.hours ? `${item?.cookTime?.hours} h` : ""}{" "}
              {item?.cookTime?.minutes ? `${item?.cookTime?.minutes} mins` : ""}
            </span>
          </span> */}
          {/* <div className={styles.tagsContainer}>
            {item?.tags?.map((item, index) => (
              <div className={styles.tag} key={item?.id}>
                {item?.text}
              </div>
            ))}
          </div> */}
        </div>
        {progress?.printRecipe && (
          <>
            <div className={styles.headerBtn}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onPrint();
                }}
                size={"x-small"}
                styleType={"filled"}
                loading={isPrintLoading}
                title={""}
                leftIcon={<PrintIcon width={20} height={20} />}
                style={{ background: "#60C055", borderRadius: 24 }}
              />
            </div>
            <div className={"printItem_content"}>
              <div className={"printItem_contentContainer"} ref={componentRef}>
                <h2 className={"printItem_headerTitle"}>{item?.title}</h2>
                <img src={item?.image || ""} className={"printItem_headerImg"} />
                <p className={"printItem_sectionTitle"}>Ingredients</p>
                {item?.ingredients?.map((it, index) => {
                  return (
                    <div
                      key={`ingredients-${index}`}
                      className={"printItem_ingredientItem"}
                    >
                      <div className={"printItem_amountWrapper"}>
                        {it?.units?.map((el, i) => {
                          return (
                            <p key={`units-${i}`}>
                              {el?.amount} {el?.unit?.text}
                              {it?.units?.length - 1 !== i ? <span>/</span> : ""}
                            </p>
                          );
                        })}
                      </div>
                      {renderName(it)}
                    </div>
                  );
                })}
                <p className={"printItem_sectionTitle"}>Preparation</p>
                <div className={"html-wrapper"}>
                  <div
                    className={"printItem_shortOverview"}
                    dangerouslySetInnerHTML={{
                      __html: replaceVideoWithLinks(item?.preparation || ""),
                    }}
                  />
                </div>
                <div className={"printItem_tagsWrapper"}>
                  {item?.tags?.map((it, index) => (
                    <div className={"printItem_tag"} key={`tags-${index}`}>
                      {it?.text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RecipeItem;
