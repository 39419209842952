import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import {
  ArrowLeftBigIcon,
  ArrowRightBigIcon,
  CrossIcon,
  FileDownloadIcon,
} from "../../assets/icons";
import Button from "../Button";
import styles from "./styles.module.scss";
import differenceInDays from "date-fns/differenceInDays";
import { Document, Page, pdfjs } from "react-pdf";
import { MoonLoader } from "react-spinners";
import { useWindowDimensions } from "../../hooks";
import { fileDownloader } from "../../services/file.service";

interface IProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  receipes: { first: string; second: string; full: string } | undefined;
  startDate: Date;
  isMealPlan: boolean;
}

const ReceipsModal: FC<IProps> = ({
  isOpen,
  setIsOpen,
  receipes,
  startDate,
  isMealPlan = false,
}): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [currentWeek, setCurrentWeek] = useState<number>(1);

  const { width } = useWindowDimensions();

  const getCurrentWeekFromStart = () => {
    const days = differenceInDays(new Date(startDate), new Date());
    let week = Math.abs(days) / 7;
    if (week <= 1) {
      setCurrentWeek(1);
    } else if (week <= 2) {
      setCurrentWeek(2);
    } else if (week <= 3) {
      setCurrentWeek(3);
    } else if (week <= 4) {
      setCurrentWeek(4);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.position = "fixed";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.position = "relative";
      document.body.style.overflowY = "visible";
    }
  }, [isOpen]);

  useEffect(() => {
    getCurrentWeekFromStart();
  }, []);

  const incrementPage = () => {
    if (page < maxPage) {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const findCurrentReceipes = () => {
    let url = receipes?.first;
    if (currentWeek === 1 || currentWeek === 3) {
      url = receipes?.first;
    } else {
      url = receipes?.second;
    }
    return url;
  };

  const onDownLoader = async () => {
    try {
      setDownloadLoading(true);
      await fileDownloader(receipes?.full || "", "Recipe");
      setDownloadLoading(false);
    } catch (error) {
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = "../../../pdf.worker.js";
  }, []);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          padding: width <= 768 ? "16px" : 0,
          outline: "none",
          border: "none",
          backgroundColor: "transparent",
          width: width <= 768 ? "100vw" : "auto",
        },
      }}
      ariaHideApp={false}
      onRequestClose={() => setIsOpen(false)}
      isOpen={isOpen}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div onClick={() => setIsOpen(false)} className={styles.crossBtn}>
            <CrossIcon />
          </div>
        </div>
        <Document
          file={{
            url: isMealPlan ? receipes?.full : findCurrentReceipes(),
            withCredentials: false,
          }}
          loading={
            <div className={styles.loaderWrapper}>
              <MoonLoader color={"#8c42e3"} />
            </div>
          }
          className={styles.pdfView}
          onLoadError={(e) => console.log(e)}
          onLoadSuccess={(e) => setMaxPage(e?._pdfInfo?.numPages)}
        >
          <Page pageNumber={page} />
        </Document>

        <div className={styles.footer}>
          <div className={styles.navigationBtn}>
            <div onClick={decrementPage} className={styles.prevBtn}>
              <ArrowLeftBigIcon />
            </div>
            <span>
              {page}/{maxPage}
            </span>
            <div onClick={incrementPage} className={styles.nextBtn}>
              <ArrowRightBigIcon />
            </div>
          </div>
          <div className={styles.downloadBtnWrapper}>
            {isMealPlan && (
              <Button
                title={width <= 768 ? "" : "Download Meal Plan"}
                rightIcon={<FileDownloadIcon />}
                styleType={"filled"}
                size={"small"}
                onClick={onDownLoader}
                loading={downloadLoading}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReceipsModal;
