import React, { FC, useEffect, useRef, useState } from "react";
import {
  FireIcon,
  TreasureClosedColor,
  TreasureClosedGray,
  TreasureOpenColor,
  TriangleIcon,
} from "../../assets/icons";
import { Line } from "rc-progress";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useSelector } from "react-redux";
import { useOutsideClick, useWindowDimensions } from "../../hooks";
import { motion } from "framer-motion";
import clsx from "clsx";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

interface IProps {
  type?: "big" | "small";
}

const DiscountPoint: FC<IProps> = ({ type = "small" }): JSX.Element => {
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [isAvailableMystery, setIsAvailableMystery] = useState<boolean>(false);
  const [isShowMysteryBox, setIsShowMysteryBox] = useState<boolean>(false);
  const { progress } = useAppSelector((state) => state.processReducer);
  const { statistics } = useAppSelector((state) => state.statisticsReducer);
  const { boostcamps, buyedBoostcamps, activeBoostcamps } = useAppSelector(
    (state) => state.allBoostcampsReducer
  );

  const dispatch = useAppDispatch();

  const [nextBoostcampId, setNextBoostcampId] = useState<string>("");
  const { width } = useWindowDimensions();

  const mysteryBoxBtn = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const checkIsMysteryBoxActive = () => {
    try {
      const sortedBoostcampsByDate = [...boostcamps];
      sortedBoostcampsByDate.sort(
        (a, b) => +new Date(a.createdAt) - +new Date(b.createdAt)
      );

      const indexOfActiveBoostcamp = sortedBoostcampsByDate.findIndex(
        (el) => el?.id === progress?.id
      );

      if (
        sortedBoostcampsByDate[indexOfActiveBoostcamp + 1] &&
        sortedBoostcampsByDate[indexOfActiveBoostcamp + 1]?.status !== "inprogress"
      ) {
        setNextBoostcampId(sortedBoostcampsByDate[indexOfActiveBoostcamp + 1]?.id);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    checkIsMysteryBoxActive();
  }, [boostcamps]);

  useOutsideClick(mysteryBoxBtn, () => setIsShowMysteryBox(false));

  useEffect(() => {
    if (statistics?.mysteryBox?.startDate && statistics?.mysteryBox?.endDate) {
      const today = new Date();
      const startDateDiscount = new Date(statistics?.mysteryBox?.startDate);
      const endDateDiscount = new Date(statistics?.mysteryBox?.endDate);

      //Disable mistery box
      // if (today > startDateDiscount && today < endDateDiscount) {
      //   setIsAvailableMystery(true);
      // }
    } else {
      setIsAvailableMystery(false);
    }
  }, [statistics]);

  useEffect(() => {
    let total = 0;
    if (progress?.days && progress?.days?.length > 0) {
      progress?.days.forEach((day) =>
        day?.tasks?.forEach((el) => (total += +el?.points))
      );
      setTotalPoints(total);
    }
  }, [progress]);

  return (
    <div style={{ width: type === "big" ? "100%" : "407px" }} className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.iconWrapper}>
          <FireIcon />
        </div>
        <h5>Discount Points</h5>
      </div>
      <p className={styles.description}>
        Complete everyday tasks, collect points and get <span>20% discount</span> on the
        next Boost Camp.
      </p>
      <div className={styles.progressWrapper}>
        <Line
          percent={statistics ? (statistics?.points * 100) / totalPoints : 0}
          strokeWidth={type === "big" ? (width <= 768 ? 6 : 3) : 8}
          trailWidth={type === "big" ? (width <= 768 ? 6 : 3) : 8}
          trailColor="#E4E4E4"
          strokeColor="#F05122"
          style={{ width: "100%" }}
        />
        <span className={styles.points}>
          {statistics?.points || 0}/{totalPoints}
        </span>
        {/* {nextBoostcampId && ( */}
        {/* <>
          <div ref={mysteryBoxBtn} className={styles.mysteryBoxWrapper}>
            <div
              onClick={() => setIsShowMysteryBox((prev) => !prev)}
              className={clsx(styles.mysteryBoxContainer, {
                [styles.mysteryBoxContainerAvailable]: isAvailableMystery,
              })}
            >
              {isAvailableMystery ? (
                <TreasureOpenColor width={28} height={28} />
              ) : (
                <TreasureClosedGray />
              )}
            </div>
            {isShowMysteryBox && (
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
                style={{
                  right: isAvailableMystery
                    ? width <= 768
                      ? "-160%"
                      : width <= 1440
                      ? "-220%"
                      : "-275%"
                    : "-150%",
                }}
                className={styles.mysteryBoxPopUp}
              >
                <div
                  className={clsx(styles.mysteryBoxPopUpContainer, {
                    [styles.mysteryBoxPopUpContainerAvailable]: isAvailableMystery,
                  })}
                >
                  {isAvailableMystery ? (
                    <TreasureOpenColor
                      width={width <= 768 ? 30 : "auto"}
                      height={width <= 768 ? 30 : "auto"}
                    />
                  ) : (
                    <TreasureClosedColor />
                  )}
                  <p className={styles.title}>Mystery Box</p>
                  <p className={styles.subtitle}>
                    {isAvailableMystery ? (
                      <>
                        Don’t miss your chance to use your <span>50% discount</span>
                        (valid until January 25th)
                      </>
                    ) : (
                      "Continue your progress..."
                    )}
                  </p>
                  {isAvailableMystery && nextBoostcampId && (
                    <Button
                      onClick={() => {
                        // dispatch(setIsUseMysteryBox(true));
                        navigate(`/preview/${nextBoostcampId}?useMysterybox=true`);
                      }}
                      title={"USE MY 50% DISCOUNT"}
                      size={"x-small"}
                      styleType={"filled"}
                      style={{ marginTop: 10 }}
                    />
                  )}
                </div>
                <TriangleIcon />
              </motion.div>
            )}
          </div>
        </> */}
        {/* )} */}
      </div>
    </div>
  );
};

export default DiscountPoint;
