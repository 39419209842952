import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import styles from "./styles.module.scss";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import {
  setIsOpenBodyTracker,
  setProgress,
  setProgressDay,
} from "../../store/reducers/ProgressSlice";
import { Button, TrackersInput } from "../index";
import { BodyIcon } from "../../assets/icons";
import clsx from "clsx";
import { ITrackers } from "../../models/ITrackers";
import { setTrackers } from "../../services/progress.service";
import { convertCmtoIn, convertInToCm } from "../../utils";
import { IProgress } from "../../models/IProgress";
import { updateProgressDay } from "../../utils/progress";

interface IProps {
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
}

interface IBodyState {
  cm: number;
  in: number;
}

const BodyTracker: FC<IProps> = ({
  currentProgress,
  setCurrentProgress,
}): JSX.Element => {
  const [activeType, setActiveType] = useState<number>(0);
  const { isOpenBodyTracker, currentDay, progress } = useAppSelector(
    (state) => state.processReducer
  );
  const [bust, setBust] = useState<string>("0");
  const [waist, setWaist] = useState<string>("0");
  const [hips, setHips] = useState<string>("0");
  const [leftUpperThigh, setLeftUpperThigh] = useState<string>("0");
  const [rightUpperThigh, setRightUpperThigh] = useState<string>("0");
  const [leftLowerThigh, setLeftLowerThigh] = useState<string>("0");
  const [rightLowerThigh, setRightLowerThigh] = useState<string>("0");
  const [topLeftArm, setTopLeftArm] = useState<string>("0");
  const [topRightArm, setTopRightArm] = useState<string>("0");

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const onChangeBody = async () => {
    try {
      setIsLoadingSubmit(true);
      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          proportions: {
            bust: {
              cm: activeType === 1 ? +Number(bust).toFixed(1) : convertInToCm(+bust),
              in: activeType === 0 ? +Number(bust).toFixed(1) : convertCmtoIn(+bust),
            },
            waist: {
              cm: activeType === 1 ? +Number(waist).toFixed(1) : convertInToCm(+waist),
              in: activeType === 0 ? +Number(waist).toFixed(1) : convertCmtoIn(+waist),
            },
            hips: {
              cm: activeType === 1 ? +Number(hips).toFixed(1) : convertInToCm(+hips),
              in: activeType === 0 ? +Number(hips).toFixed(1) : convertCmtoIn(+hips),
            },
            leftLowerThigh: {
              cm:
                activeType === 1
                  ? +Number(leftLowerThigh).toFixed(1)
                  : convertInToCm(+leftLowerThigh),
              in:
                activeType === 0
                  ? +Number(leftLowerThigh).toFixed(1)
                  : convertCmtoIn(+leftLowerThigh),
            },
            rightUpperThigh: {
              cm:
                activeType === 1
                  ? +Number(rightUpperThigh).toFixed(1)
                  : convertInToCm(+rightUpperThigh),
              in:
                activeType === 0
                  ? +Number(rightUpperThigh).toFixed(1)
                  : convertCmtoIn(+rightUpperThigh),
            },
            leftUpperThigh: {
              cm:
                activeType === 1
                  ? +Number(leftUpperThigh).toFixed(1)
                  : convertInToCm(+leftUpperThigh),
              in:
                activeType === 0
                  ? +Number(leftUpperThigh).toFixed(1)
                  : convertCmtoIn(+leftUpperThigh),
            },
            rightLowerThigh: {
              cm:
                activeType === 1
                  ? +Number(rightLowerThigh).toFixed(1)
                  : convertInToCm(+rightLowerThigh),
              in:
                activeType === 0
                  ? +Number(rightLowerThigh).toFixed(1)
                  : convertCmtoIn(+rightLowerThigh),
            },
            topLeftArm: {
              cm:
                activeType === 1
                  ? +Number(topLeftArm).toFixed(1)
                  : convertInToCm(+topLeftArm),
              in:
                activeType === 0
                  ? +Number(topLeftArm).toFixed(1)
                  : convertCmtoIn(+topLeftArm),
            },
            topRightArm: {
              cm:
                activeType === 1
                  ? +Number(topRightArm).toFixed(1)
                  : convertInToCm(+topRightArm),
              in:
                activeType === 0
                  ? +Number(topRightArm).toFixed(1)
                  : convertCmtoIn(+topRightArm),
            },
          },
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          onClose();
        }
        setIsLoadingSubmit(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setIsOpenBodyTracker(false));
  };

  useEffect(() => {
    if (handledProgress) {
      // setBust(`${handledProgress?.days[currentDay].trackers.proportions.bust || 0}`);
      // setWaist(`${handledProgress?.days[currentDay].trackers.proportions.waist || 0}`);
      // setHips(`${handledProgress?.days[currentDay].trackers.proportions.hips || 0}`);
      // setLeftUpperThigh(
      //   `${handledProgress?.days[currentDay].trackers.proportions.leftUpperThigh || 0}`
      // );
      // setRightUpperThigh(
      //   `${handledProgress?.days[currentDay].trackers.proportions.rightUpperThigh || 0}`
      // );
      // setLeftLowerThigh(
      //   `${handledProgress?.days[currentDay].trackers.proportions.leftLowerThigh || 0}`
      // );
      // setRightLowerThigh(
      //   `${handledProgress?.days[currentDay].trackers.proportions.rightLowerThigh || 0}`
      // );
      // setTopLeftArm(`${handledProgress?.days[currentDay].trackers.proportions.topLeftArm || 0}`);
      // setTopRightArm(
      //   `${handledProgress?.days[currentDay].trackers.proportions.topRightArm || 0}`
      // );
    }
  }, [handledProgress]);
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpenBodyTracker}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <BodyIcon />
          </div>
          <h5>What’s your proportion today?</h5>
          <div className={styles.btnContainer}>
            <div
              onClick={() => setActiveType(0)}
              className={clsx(styles.typeBtn, {
                [styles.typeBtnActive]: activeType === 0,
              })}
            >
              in
            </div>
            <div
              onClick={() => setActiveType(1)}
              className={clsx(styles.typeBtn, {
                [styles.typeBtnActive]: activeType === 1,
              })}
            >
              cm
            </div>
          </div>
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderValueWrapper}>
            <TrackersInput value={bust} setValue={setBust} label={"Bust"} />
            <TrackersInput value={waist} setValue={setWaist} label={"Waist"} />
            <TrackersInput value={hips} setValue={setHips} label={"Hips"} />
          </div>
          <div className={styles.sliderValueWrapper}>
            <TrackersInput
              value={leftUpperThigh}
              setValue={setLeftUpperThigh}
              label={"Left Upper Thigh"}
            />
            <TrackersInput
              value={rightUpperThigh}
              setValue={setRightUpperThigh}
              label={"Right Upper Thigh"}
            />
            <TrackersInput
              value={rightLowerThigh}
              setValue={setRightLowerThigh}
              label={"Right Lower Thigh"}
            />
          </div>
          <div className={styles.sliderValueWrapper}>
            <TrackersInput
              value={leftLowerThigh}
              setValue={setLeftLowerThigh}
              label={"Left Lower Thigh"}
            />
            <TrackersInput
              value={topLeftArm}
              setValue={setTopLeftArm}
              label={"Top of Left Arm"}
            />
            <TrackersInput
              value={topRightArm}
              setValue={setTopRightArm}
              label={"Top of Right Arm"}
            />
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          backgroundColor={"#FCD930"}
          loading={isLoadingSubmit}
          onClick={onChangeBody}
        />
      </div>
    </Modal>
  );
};

export default BodyTracker;
