import React, { FC, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import Button from "../Button";
import styles from "./styles.module.scss";
import "rc-slider/assets/index.css";
import clsx from "clsx";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import {
  setIsOpenFastingTracker,
  setProgress,
  setProgressDay,
} from "../../store/reducers/ProgressSlice";
import TrackersInput from "../TrackersInput";
import { ITrackers } from "../../models/ITrackers";
import { setTrackers } from "../../services/progress.service";
import Fasting from "../../assets/images/fasting.png";
import { IProgress } from "../../models/IProgress";
import { updateProgressDay } from "../../utils/progress";

interface IProps {
  currentProgress?: IProgress | null;
  setCurrentProgress?: React.Dispatch<React.SetStateAction<IProgress | null>>;
}

const FastingTracker: FC<IProps> = ({
  currentProgress,
  setCurrentProgress,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isOpenFastingTracker, currentDay, progress } = useAppSelector(
    (state) => state.processReducer
  );
  const [activeType, setActiveType] = useState<number>(0);
  const [value, setValue] = useState<string>("0");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const handledProgress = useMemo(
    () => currentProgress || progress,
    [currentProgress, progress]
  );

  const onChangeFasting = async () => {
    try {
      setIsLoadingSubmit(true);

      if (handledProgress?.days[currentDay]?.trackers) {
        const body: ITrackers = {
          ...handledProgress?.days[currentDay]?.trackers,
          eat: +value,
        };
        const { error, result } = await setTrackers(
          handledProgress?.days[currentDay].id || "",
          body
        );
        if (!error && result?.status === 202) {
          if (currentProgress && setCurrentProgress) {
            setCurrentProgress((prev) => updateProgressDay(prev, result));
          } else {
            dispatch(setProgressDay(result.data.data));
          }
          onClose();
        }
      }

      setIsLoadingSubmit(false);
    } catch (error) {
      console.log(error);
      setIsLoadingSubmit(false);
    }
  };

  const onClose = () => {
    dispatch(setIsOpenFastingTracker(false));
  };

  useEffect(() => {
    if (!isOpenFastingTracker) return;
    const eatValue = handledProgress?.days[currentDay]?.trackers?.eat;
    setValue(`${eatValue || 0}`);
  }, [handledProgress, currentDay, isOpenFastingTracker]);

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999999999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          borderRadius: "30px",
          padding: "25px",
          outline: "none",
          overflow: "hidden",
        },
      }}
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen={isOpenFastingTracker}
    >
      <div className={styles.contentBox}>
        <div className={styles.header}>
          <div className={styles.iconWrapper}>
            <img src={Fasting} className={styles.fastingIcon} />
          </div>
          <h5>
            How long did
            <br /> you fast for?
          </h5>
        </div>
        <div className={styles.sliderWrapper}>
          <div className={styles.sliderValueWrapper}>
            {/* <span>{options[activeIndex]}</span> */}
            <div className={styles.inputWrapper}>
              <TrackersInput value={value} setValue={setValue} />
            </div>
            <div className={styles.btnContainer}>
              <div
                onClick={() => setActiveType(0)}
                className={clsx(styles.typeBtn, {
                  [styles.typeBtnActive]: activeType === 0,
                })}
              >
                hrs
              </div>
            </div>
          </div>
        </div>

        <Button
          title={"SUBMIT"}
          styleType={"filled"}
          size={"small"}
          backgroundColor={"#F05122"}
          loading={isLoadingSubmit}
          onClick={onChangeFasting}
        />
      </div>
    </Modal>
  );
};

export default FastingTracker;
