import React, { FC, useMemo, ReactNode, useRef } from "react";
import styles from "./styles.module.scss";
import { PencilSmallIcon, LockIcon, InfoIcon, ChecklistIcon } from "../../assets/icons";
import { useAppSelector } from "../../hooks/redux";
import { motion } from "framer-motion";
import Button from "../Button";
import { useOutsideClick } from "../../hooks";

interface IProps {
  title: string;
  color: string;
  btnColor: string;
  onClick?: (v: boolean) => void;
  formattedValue: string;
  currentIsAvailableTracker?: boolean;
  children: ReactNode;
  isClosed?: boolean;
  isOpenTracker: boolean;
  isOpenInfo: boolean;
  setIsOpenInfo: React.Dispatch<React.SetStateAction<boolean>>;
  infoTitle: string;
  icon: ReactNode;
}

const TeckerMobile: FC<IProps> = ({
  title,
  onClick,
  color,
  btnColor,
  formattedValue,
  isClosed,
  isOpenTracker,
  currentIsAvailableTracker,
  children,
  isOpenInfo,
  setIsOpenInfo,
  infoTitle,
  icon,
}): JSX.Element => {
  const { isAvailableTrackers, isUpdatingTasks } = useAppSelector(
    (state) => state.processReducer
  );

  const handledIsAvailableTrackers = useMemo(
    () => currentIsAvailableTracker || isAvailableTrackers,
    [isAvailableTrackers, currentIsAvailableTracker]
  );

  const onClickHandled = () => {
    if (!isUpdatingTasks) {
      onClick && onClick(true);
    }
  };

  const btnHandledColor = useMemo(
    () => (isUpdatingTasks ? "#7c8691" : btnColor),
    [isUpdatingTasks, btnColor]
  );

  const infoRef = useRef(null);
  useOutsideClick(infoRef, () => setIsOpenInfo(false));

  return (
    <div className={styles.flexRow}>
      <div style={{ background: color }} className={styles.iconWrapper}>
        <div
          onMouseOver={() => setIsOpenInfo(true)}
          onMouseLeave={() => setIsOpenInfo(false)}
          ref={infoRef}
          className={styles.infoBtn}
        >
          {isOpenInfo && (
            <motion.div
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
              className={styles.infoBlock}
            >
              {infoTitle}
            </motion.div>
          )}
          <InfoIcon style={{ color: btnColor }} />
        </div>
        {icon}
      </div>
      <p>{title}</p>
      <div className={styles.btnWrapper}>
        {(formattedValue || isClosed) && !isOpenTracker ? (
          <div style={{ cursor: "pointer" }} onClick={onClickHandled}>
            {
              <div className={styles.editIcon}>
                <div
                  style={{ borderColor: btnHandledColor }}
                  className={styles.editIconWrapper}
                >
                  <PencilSmallIcon color={btnHandledColor} />
                </div>
              </div>
            }
            <Button
              styleType="filled"
              size={"medium"}
              title={isClosed ? (formattedValue ? formattedValue : "0") : formattedValue}
              style={{ padding: "15px 15px", backgroundColor: btnHandledColor }}
            />
          </div>
        ) : handledIsAvailableTrackers ? (
          isOpenTracker ? (
            <>{children}</>
          ) : (
            <Button
              title="Set"
              styleType="outlined-dashed"
              size={"small"}
              color={btnHandledColor}
              backgroundColor={"#fff"}
              onClick={onClickHandled}
            />
          )
        ) : (
          <div style={{ position: "relative" }}>
            <div
              style={{ backgroundColor: color, borderColor: btnHandledColor }}
              className={styles.lockedWrapper}
            />
            <div className={styles.lockedIconWrapper}>
              <LockIcon style={{ color: btnHandledColor }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TeckerMobile;
