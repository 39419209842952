import { POST, GET, PUT } from "../API/axios";
import { AxiosError } from "axios";
interface IResponse {
  error: null | Error | AxiosError;
  result: null | {
    status: number;
    data: any;
  };
}

export const getMe = (): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET("users/me")
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const sendResetPasswordCode = (email: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
    };

    POST(`reset-password/`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
export const changePassword = (token: string, password: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      token,
      password,
    };

    PUT(`reset-password/`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
export const verifyResetPassword = (email: string, code: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
      code,
    };

    POST(`reset-password/verify/`, body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
