import { GET } from "../API/axios";

interface IResponse {
  error: null | object;
  result: null | {
    status: number;
    data: any;
  };
}

export const getInfoBlocks = (): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    GET(`info-blocks/`)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
