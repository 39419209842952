import React, { FC } from "react";
import { CalendarColorIcon } from "../../assets/icons";
import styles from "./styles.module.scss";

interface IProps {
  setIsOpen: () => void;
}

const StickyDayButton: FC<IProps> = ({ setIsOpen }): JSX.Element => {
  return (
    <div onClick={setIsOpen} className={styles.wrapper}>
      <CalendarColorIcon />
    </div>
  );
};

export default StickyDayButton;
