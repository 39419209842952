import React, { FC } from "react";
import Counter from "../Counter";

import styles from "./styles.module.scss";

const Header: FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <p className={styles.title}>
          Powerful and Effective <span>Step By Step Process</span> To Look And
          Feel Amazing Again
        </p>
        <Counter />
      </div>
    </div>
  );
};

export default Header;
