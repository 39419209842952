import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { withSignLayout } from "../../layouts";
import { Button, ForgotPasswordModal, Input, Progress } from "../../components";
import styles from "./styles.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { login, resendCode } from "../../services/auth.service";
import { setToken } from "../../localStorage";
import { useAppDispatch } from "../../hooks/redux";
import { setUser, setToken as setTokenStore } from "../../store/reducers/UserSlice";
import { getMyActiveProgress } from "../../services/progress.service";

const LogIn: FC = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShow, setIsShow] = useState(false);

  //errorState
  const [emailError, setEmailError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [errorCount, setErrorCount] = useState<number>(0);

  const location = useLocation();

  const selectedMonday = location?.state?.selectedMonday;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkIfUserBuyBoostcamp = async (id: any) => {
    try {
      const { error, result } = await getMyActiveProgress();
      if (!error && result?.data) {
        if (result?.data?.data?.id === id) {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onLogin = async () => {
    try {
      if (email.length < 2) {
        setEmailError("Email is required");
        return;
      } else {
        setEmailError("");
      }
      if (password.length < 2) {
        setPasswordError("Password is required");
        return;
      } else {
        setPasswordError("");
      }

      setIsLoading(true);
      const { error, result } = await login(email, password);
      if (!error && result?.status === 201) {
        if (result?.data?.data?.token) {
          setToken(result.data.data.token);
          dispatch(setUser(result.data.data.user));
          dispatch(setTokenStore(result.data.data.token));

          const myActiveBoostcamp = await getMyActiveProgress();

          const hasBoostcamp = myActiveBoostcamp?.result?.data?.data;

          // const isUserAlreadyBuy = await checkIfUserBuyBoostcamp();

          if (selectedMonday && !hasBoostcamp) {
            navigate("/signup-buy", {
              state: { fromSignUp: false, selectedMonday: selectedMonday },
            });
          } else {
            navigate("/home");
          }
          setLoginError("");
        } else {
          await resendCode(result?.data.data?.user?.email);
          navigate("/email-verification", {
            state: {
              email: result?.data.data?.user?.email,
              fromSignUp: false,
              selectedMonday: selectedMonday,
            },
          });
        }
      } else {
        console.log("login error", error);
        //@ts-ignore
        setLoginError(error?.response?.data?.error || "Something went wrong");
        if (errorCount >= 4) {
          setIsShow(true);
        } else {
          setErrorCount((prev) => prev + 1);
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  return (
    <div className={styles.container}>
      {selectedMonday && <Progress currentStep={1} maxStep={2} />}
      <div className={styles.navigationBtnWrapper}>
        <NavLink
          key={"signup"}
          to={"/signup"}
          state={selectedMonday ? { selectedMonday: selectedMonday } : {}}
        >
          New Member
        </NavLink>
        <NavLink
          key={"login"}
          to={"/login"}
          state={selectedMonday ? { selectedMonday: selectedMonday } : {}}
        >
          I have an account
        </NavLink>
      </div>
      <Input
        label="Email Address"
        value={email}
        onChange={onChangeEmail}
        placeholder={"Enter Email Address"}
        type={"email"}
        error={emailError}
      />
      <Input
        label="Password"
        value={password}
        onChange={onChangePassword}
        placeholder={"Enter Password"}
        type={"password"}
        error={passwordError}
      />
      {/* <div className={styles.forgotWrapper}> */}
      <div className={styles.forgotWrapper}>
        <span onClick={() => setIsShow(true)}>Forgot password?</span>
      </div>
      {/* </div> */}
      {loginError && <div className={styles.error}>{loginError}</div>}
      <Button
        title="Sign In"
        styleType={"filled"}
        size={"medium"}
        loading={isLoading}
        onClick={onLogin}
      />
      {/* <div className={styles.supportWrapper}>
        <span>
          If you have any logging troubles, you can contact our{" "}
          <a href="mailto:boost@bridgetshealthykitchen.com">Contact us for support</a>
        </span>
      </div> */}
      {isShow && (
        <ForgotPasswordModal
          isShow={isShow}
          setIsShow={setIsShow}
          onSuccess={() => {
            setErrorCount(0);
          }}
        />
      )}
    </div>
  );
};

export default withSignLayout(LogIn);
