import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatistic } from "../../models/IStatistic";

interface IStatisticState {
  statistics: null | IStatistic;
  trackingOption: "day" | "week" | "month";
  activeTrackingType: "sleep" | "water" | "eat" | "weight" | "body";
  isLoading: boolean;
  activeTrackingBtn: number;
  error: string;
}

const initialState: IStatisticState = {
  isLoading: false,
  trackingOption: "month",
  activeTrackingType: "sleep",
  error: "",
  activeTrackingBtn: 0,
  statistics: null,
};

export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    statisticFetching: (state) => {
      state.isLoading = true;
    },
    statisticFetchingSuccess: (state, action: PayloadAction<IStatistic | null>) => {
      state.isLoading = false;
      state.error = "";
      state.statistics = action.payload;
    },
    statisticFetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    statisticClear: (state) => {
      state.statistics = null;
      state.error = "";
      state.isLoading = false;

      state.trackingOption = "month";
      state.activeTrackingType = "sleep";
      state.activeTrackingBtn = 0;
    },
    setTrackingOption: (state, action: PayloadAction<"day" | "week" | "month">) => {
      state.trackingOption = action.payload;
    },
    setActiveTrackingType: (
      state,
      action: PayloadAction<"sleep" | "water" | "eat" | "weight" | "body">
    ) => {
      state.activeTrackingType = action.payload;
    },
    setActiveTrackingBtn: (state, action: PayloadAction<number>) => {
      state.activeTrackingBtn = action.payload;
    },
  },
});

export const {
  statisticFetching,
  statisticFetchingSuccess,
  statisticFetchingError,
  statisticClear,
  setTrackingOption,
  setActiveTrackingType,
  setActiveTrackingBtn,
} = statisticSlice.actions;

export default statisticSlice.reducer;
