import React, { FC } from "react";
import styles from "./styles.module.scss";
import { StartIcon } from "../../assets/icons";

interface IProps {
  avatar: string;
  name: string;
  city: string;
  rating: number;
  text: string;
  isMobile?: boolean;
}

const ReviewItem: FC<IProps> = ({
  avatar,
  name,
  city,
  rating,
  text,
  isMobile = false,
}): JSX.Element => {
  if (isMobile) {
    return (
      <div className={styles.mobileWrapper}>
        <div className={styles.header}>
          <img src={avatar} className={styles.avatar} />
          <div className={styles.description}>
            <span className={styles.name}>{name}</span>
            <span className={styles.city}>{city}</span>
            <div className={styles.ratingWrapper}>
              {Array(rating)
                .fill(0)
                .map((_, index) => (
                  <StartIcon style={{ width: "20px" }} key={index} />
                ))}
            </div>
          </div>
        </div>
        <p className={styles.text}>
          {text?.length >= 270 ? `${text.slice(0, 270)}...` : text}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatarBlock}>
        <img src={avatar} className={styles.avatar} />
      </div>
      <div className={styles.textBlock}>
        <p className={styles.name}>{name}</p>
        <p className={styles.city}>{city}</p>
        <div className={styles.ratingWrapper}>
          {Array(rating)
            .fill(0)
            .map((_, index) => (
              <StartIcon key={index} />
            ))}
        </div>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default ReviewItem;
