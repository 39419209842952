import instance from "../index";
import { getToken } from "../../localStorage";
import { AxiosError } from "axios";

interface IResponse {
  result: null | {
    status: number;
    data: object;
  };
  error: null | Error | AxiosError;
}

export const GET = async (query: string): Promise<IResponse> => {
  const token = getToken();

  let result = null;
  let error = null;

  await instance
    .get(query, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      error = e;
      console.log(error.response);
    });

  return { result, error };
};

export const POST = async (
  query: string,
  body: object | FormData,
  isformData: boolean = false
): Promise<IResponse> => {
  const token = getToken();
  let result = null;
  let error = null;
  await instance
    .post(query, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": isformData ? "multipart/form-data" : "application/json",
      },
    })
    .then((res) => {
      result = res;
    })
    .catch((e: any) => {
      error = e;
      console.log(e.response);
    });

  return { result, error };
};

export const PUT = async (query: string, body: object): Promise<IResponse> => {
  const token = getToken();
  let result = null;
  let error = null;

  await instance
    .put(query, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      error = e;
      console.log(e.response);
    });

  return { result, error };
};

export const PATCH = async (query: string, body: object): Promise<IResponse> => {
  const token = getToken();
  let result = null;
  let error = null;

  await instance
    .patch(query, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
    })
    .catch((e) => {
      error = e;
    });

  return { result, error };
};

export const DELETE = async (query: string, body: object = {}): Promise<IResponse> => {
  const token = getToken();
  let result = null;
  let error = null;

  await instance
    .delete(query, {
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      result = res;
      return res;
    })
    .catch((e) => {
      error = e;
    });

  return { result, error };
};
