import { POST } from "../API/axios";

interface IResponse {
  error: null | object;
  result: null | {
    status: number;
    data: any;
  };
}

export const signUp = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  referralKey?: string
): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    let body: any = {
      email,
      password,
      firstName,
      lastName,
    };

    if (referralKey) {
      body.referrerKey = referralKey;
    }

    POST("users", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const login = async (email: string, password: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
      password,
    };
    POST("users/auth", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const verifyEmail = async (email: string, code: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
      code,
    };
    POST("verify", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const resendCode = async (email: string): Promise<IResponse> => {
  return new Promise((resolve, reject) => {
    const body = {
      email,
    };
    POST("verify/resend", body)
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};
