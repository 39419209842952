import React, { FC } from "react";
import { LoseWeightIcon } from "../../assets/icons";
import styles from "./styles.module.scss";

interface IProps {
  onClick: () => void;
}

const StickyTrackerButton: FC<IProps> = ({ onClick }) => {
  return (
    <div onClick={onClick} className={styles.menuBtn}>
      <div className={styles.menuCounter}>4</div>
      <LoseWeightIcon style={{ width: "40px" }} />
    </div>
  );
};

export default StickyTrackerButton;
