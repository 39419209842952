import React, { useRef, useEffect } from "react";
import { io, Socket } from "socket.io-client";
import { INotification } from "../models/INotification";
import {
  setNewNotification,
  setNotifications,
} from "../store/reducers/NotificationSlice";
import { useAppDispatch, useAppSelector } from "./redux";

interface INotificationsRes {
  data: Array<INotification>;
}

interface INewNotificationRes {
  data: INotification;
}

export const useSocket = () => {
  const { token } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  let socket: Socket = io(`${process.env.REACT_APP_API_URL}notifications`, {
    extraHeaders: {
      authorization: `Bearer ${token}`,
    },
  });
  const connectToSocket = () => {
    try {
      socket.on("notifications", ({ data }: INotificationsRes) => {
        const sortedNotifications = data?.sort(
          (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)
        );
        dispatch(setNotifications(sortedNotifications));
      });
      socket.on("new_notification", ({ data }: INewNotificationRes) => {
        dispatch(setNewNotification(data));
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    connectToSocket();
    return () => {
      socket.off("notifications");
      socket.off("new_notification");
    };
  }, [token]);
};
