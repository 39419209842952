import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import { withMainLayout } from "../../layouts";
import {
  DailyTaskSetItem,
  DiscountPoint,
  BoostcampPreview,
  SleepTracker,
  WaterTracker,
  FastingTracker,
  WeightTracker,
  BodyTracker,
  StickyTrackerButton,
  TrackerListMenu,
  OnboardingTooltip,
} from "../../components";
import styles from "./styles.module.scss";
import { ChecklistIcon, SleepIcon, WaterIcon } from "../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  fetchBuyedBoostcamps,
  fetchMyBoostcamps,
} from "../../store/actions/AllBoostcampsActions";
import { useWindowDimensions } from "../../hooks";
import PulseLoader from "react-spinners/PulseLoader";
import { IBoostcamp } from "../../models/IBoostcamp";
import {
  setCurrentDay,
  setIsOpenFastingTracker,
  setIsOpenSleepTracker,
  setIsOpenWaterTracker,
  setIsOpenWeightTracker,
} from "../../store/reducers/ProgressSlice";
import { fetchStatistic } from "../../store/actions/StatisticActions";
import Fasting from "../../assets/images/fasting.png";
import { setIsOpenTrackerList } from "../../store/reducers/ModalSlice";
import clsx from "clsx";
import { setGeneralOnboardingStep } from "../../store/reducers/OnboardingSlice";
import {
  fetchOnboarding,
  fetchOnboardingSteps,
  finishOnboarding,
} from "../../store/actions/OnboardingAction";
import { GeneralOnboardingStepsEnum } from "../../models/ITooltip";

interface IProps {}

const Home: FC<IProps> = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const [sortedBoost, setSortedBoost] = useState<IBoostcamp[]>([]);
  const [isTrackerListOpen, setIsTrackerListOpen] = useState<boolean>(false);

  const { boostcamps, isLoading, buyedBoostcamps } = useAppSelector(
    (state) => state.allBoostcampsReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);
  const { progress, currentDay } = useAppSelector((state) => state.processReducer);
  const { isOpenTrackerList } = useAppSelector((state) => state.modalReducer);

  const dailyTaskListRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);

  const isClosed =
    progress?.days[currentDay]?.isCompleted || progress?.days[currentDay]?.isClose;

  const {
    showGeneralOnboarding,
    generalOnboardingStep,
    generalOnboardingSteps,
    generalOnboardingStepIndex,
  } = useAppSelector((state) => state.onboardingReducer);

  useEffect(() => {
    const copy = [...boostcamps];
    copy.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt));
    const sortedByStatus = copy.map((item, index) => {
      if (item?.inProgress && !item?.isCompleted) {
        return { ...item, status: "inprogress" };
      } else if (item?.isCompleted) {
        return { ...item, status: "finished" };
      } else if (!item?.inProgress && !item?.isCompleted) {
        return { ...item, status: "blocked" };
      } else {
        return { ...item, status: "blocked" };
      }
    });

    let isHaveFinishedBoost: boolean = false;
    let indexOfLastFinishedBoost: number = 0;

    for (let i = 0; i < sortedByStatus.length; i++) {
      if (sortedByStatus[i].status === "finished") {
        isHaveFinishedBoost = true;
        indexOfLastFinishedBoost = i;
      }
    }

    if (buyedBoostcamps?.length === 0 && sortedByStatus?.length > 0) {
      sortedByStatus[0].status = "forbuy";
    }
    if (isHaveFinishedBoost) {
      if (
        sortedByStatus[indexOfLastFinishedBoost + 1] &&
        sortedByStatus[indexOfLastFinishedBoost + 1]?.status !== "inprogress"
      ) {
        sortedByStatus[indexOfLastFinishedBoost + 1].status = "forbuy";
      }
    }
    //@ts-ignore
    setSortedBoost(sortedByStatus);
  }, [boostcamps, buyedBoostcamps?.length]);

  const onOpenTrackerMenu = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
    dispatch(setIsOpenTrackerList(true));
  };

  useEffect(() => {
    //If has current day or dont have progress return
    if (currentDay || !progress) return;
    //If not than
    if (!user?.testMode) {
      //set for passed test for real user
      dispatch(setCurrentDay(progress?.passedDays || 0));
    } else {
      //set 0 for test user
      dispatch(setCurrentDay(0));
    }
  }, [progress, user, currentDay, dispatch]);

  useEffect(() => {
    dispatch(fetchBuyedBoostcamps());
    dispatch(fetchMyBoostcamps());
    // dispatch(fetchProgress());
    dispatch(fetchStatistic("month"));

    //add if need to ckeck tutorial
    // dispatch(finishOnboarding({ general: false }));
    dispatch(fetchOnboarding());
  }, []);

  const hasBuyedBoostacamp = useMemo(
    () => !!buyedBoostcamps && buyedBoostcamps?.length > 0,
    [buyedBoostcamps]
  );

  const isActiveTasksOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.TASKS,
    [generalOnboardingStep]
  );
  const isActiveDiscountOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.DISCOUNT,
    [generalOnboardingStep]
  );

  const isActiveOnboarding = useMemo(
    () => isActiveTasksOnboarding || isActiveDiscountOnboarding,
    [isActiveTasksOnboarding, isActiveDiscountOnboarding]
  );

  const isActiveOpenOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.OPEN_BOOST,
    [progress, generalOnboardingStep]
  );
  const isActiveClosedOnboarding = useMemo(
    () => generalOnboardingStep?.step === GeneralOnboardingStepsEnum.CLOSED_BOOST,
    [progress, generalOnboardingStep]
  );

  const showGeneralOnbAndHasBuyed = useMemo(
    () => showGeneralOnboarding && hasBuyedBoostacamp,
    [showGeneralOnboarding, hasBuyedBoostacamp]
  );

  const isActiveBoostOnboarding = useMemo(
    () =>
      showGeneralOnbAndHasBuyed &&
      (isActiveOpenOnboarding ||
        isActiveClosedOnboarding ||
        isActiveTasksOnboarding ||
        isActiveDiscountOnboarding),
    [
      showGeneralOnbAndHasBuyed,
      isActiveOpenOnboarding,
      isActiveClosedOnboarding,
      isActiveTasksOnboarding,
      isActiveDiscountOnboarding,
    ]
  );

  const goToNextOnboardingStep = () => {
    if (isActiveBoostOnboarding)
      dispatch(setGeneralOnboardingStep(generalOnboardingStepIndex + 1));
    if (generalOnboardingStepIndex + 1 >= generalOnboardingSteps?.length)
      dispatch(finishOnboarding({ general: true }));
  };

  const skipOnboarding = () => {
    dispatch(finishOnboarding({ general: true }));
  };

  useEffect(() => {
    if (
      showGeneralOnbAndHasBuyed &&
      !isLoading &&
      width <= 768 &&
      isActiveTasksOnboarding
    ) {
      dispatch(setIsOpenTrackerList(true));
    } else {
      dispatch(setIsOpenTrackerList(false));
    }
  }, [isLoading, isActiveTasksOnboarding, width, showGeneralOnbAndHasBuyed]);

  useEffect(() => {
    if (showGeneralOnbAndHasBuyed) {
      dispatch(fetchOnboardingSteps("GENERAL"));
    }
  }, [showGeneralOnbAndHasBuyed]);

  return (
    <>
      <SleepTracker />
      <WaterTracker />
      <FastingTracker />
      <WeightTracker />
      <BodyTracker />

      <div ref={pageRef} className={styles.wrapper}>
        {width > 1440 && (
          <div
            className={clsx(
              styles.discountsContainer,
              showGeneralOnbAndHasBuyed &&
                isActiveDiscountOnboarding &&
                styles.discountsContainerActive
            )}
            // onClick={goToNextOnboardingStepDiscount}
          >
            {showGeneralOnbAndHasBuyed && isActiveDiscountOnboarding && (
              <OnboardingTooltip
                step={generalOnboardingStepIndex + 1}
                maxSteps={generalOnboardingSteps.length}
                title={generalOnboardingStep?.title}
                text={generalOnboardingStep?.description}
                onClickBtn={goToNextOnboardingStep}
                className={styles.discountsContainerTooltip}
                onClickSkip={skipOnboarding}
              />
            )}
            <DiscountPoint />
            {/* <FriendDiscount /> */}
          </div>
        )}
        <div className={styles.boostcampsList}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <PulseLoader color="#8c42e3" />
            </div>
          ) : (
            sortedBoost.map((item, index) => {
              return (
                <div
                  className={clsx(
                    styles.boostPreview,
                    showGeneralOnbAndHasBuyed &&
                      ((isActiveOpenOnboarding && index === 0) ||
                        (isActiveClosedOnboarding && index === 1)) &&
                      styles.boostPreviewActive
                  )}
                  key={`${item?.id}-${index}`}
                >
                  <BoostcampPreview
                    progress={
                      showGeneralOnbAndHasBuyed
                        ? isActiveOpenOnboarding && index === 0
                          ? "inprogress"
                          : isActiveClosedOnboarding && index === 1
                          ? "blocked"
                          : item?.status
                        : item?.status
                    }
                    data={item}
                    isFirst={index === 0}
                    isLast={index === sortedBoost.length - 1}
                    isOnlyOne={sortedBoost.length <= 1}
                  />
                  {showGeneralOnbAndHasBuyed &&
                    ((isActiveOpenOnboarding && index === 0) ||
                      (isActiveClosedOnboarding && index === 1)) && (
                      <OnboardingTooltip
                        step={generalOnboardingStepIndex + 1}
                        maxSteps={generalOnboardingSteps.length}
                        title={generalOnboardingStep?.title}
                        text={generalOnboardingStep?.description}
                        onClickBtn={goToNextOnboardingStep}
                        className={styles.boostPreviewTooltip}
                        onClickSkip={skipOnboarding}
                      />
                    )}
                </div>
              );
            })
          )}
        </div>
        <div
          ref={dailyTaskListRef}
          className={clsx(
            styles.dailyTaskList,
            showGeneralOnbAndHasBuyed && isActiveOnboarding && styles.dailyTaskListActive
          )}
        >
          {width > 768 && (
            <div
              className={clsx(
                styles.dailyTaskListContainer,
                showGeneralOnbAndHasBuyed &&
                  isActiveTasksOnboarding &&
                  styles.dailyTaskListContainerActive
              )}
            >
              {showGeneralOnbAndHasBuyed && isActiveTasksOnboarding && (
                <OnboardingTooltip
                  step={generalOnboardingStepIndex + 1}
                  maxSteps={generalOnboardingSteps.length}
                  title={generalOnboardingStep?.title}
                  text={generalOnboardingStep?.description}
                  onClickBtn={goToNextOnboardingStep}
                  className={styles.dailyTaskListTooltip}
                  onClickSkip={skipOnboarding}
                />
              )}
              <h3 className={styles.dailyTaskListTitle}>TRACKERS</h3>
              <DailyTaskSetItem
                icon={<ChecklistIcon />}
                title="Current weight"
                color={"#60C05533"}
                btnColor={"#60C055"}
                onClick={() => dispatch(setIsOpenWeightTracker(true))}
                value={progress?.days[currentDay]?.trackers || null}
                type={"weight"}
                infoPosition={"bottom"}
                auto
              />
              <DailyTaskSetItem
                icon={<img src={Fasting} className={styles.fasting} />}
                title="Fasting window"
                color={"#F0512233"}
                btnColor={"#F05122"}
                onClick={() => dispatch(setIsOpenFastingTracker(true))}
                value={progress?.days[currentDay]?.trackers || null}
                type={"fasting"}
                auto
              />
              <DailyTaskSetItem
                icon={<WaterIcon />}
                title="Water intake"
                color={"#79DCD533"}
                btnColor={"#79DCD5"}
                onClick={() => dispatch(setIsOpenWaterTracker(true))}
                value={progress?.days[currentDay]?.trackers || null}
                type={"water"}
                auto
              />
              {/* <DailyTaskSetItem
            icon={<BodyIcon />}
            title="What’s your proportion today?"
            color={"#FCD93033"}
            btnColor={"#FCD930"}
            onClick={() => (isClosed ? null : dispatch(setIsOpenBodyTracker(true)))}
            value={progress?.days[currentDay]?.trackers || null}
            type={"body"}
          /> */}
              <DailyTaskSetItem
                icon={<SleepIcon />}
                title="Sleep tracker"
                color={"#8C42E326"}
                btnColor={"#8C42E3"}
                onClick={() => dispatch(setIsOpenSleepTracker(true))}
                value={progress?.days[currentDay]?.trackers || null}
                type={"sleep"}
                auto
              />
            </div>
          )}
          {width <= 1440 && width > 768 && (
            <div
              className={clsx(
                styles.discountsContainer,
                showGeneralOnbAndHasBuyed &&
                  isActiveDiscountOnboarding &&
                  styles.discountsContainerActive
              )}
              // onClick={goToNextOnboardingStepDiscount}
            >
              {showGeneralOnbAndHasBuyed && isActiveDiscountOnboarding && (
                <OnboardingTooltip
                  step={generalOnboardingStepIndex + 1}
                  maxSteps={generalOnboardingSteps.length}
                  title={generalOnboardingStep?.title}
                  text={generalOnboardingStep?.description}
                  onClickBtn={goToNextOnboardingStep}
                  className={styles.discountsContainerTooltip}
                  onClickSkip={skipOnboarding}
                />
              )}
              <DiscountPoint />
              {/* <FriendDiscount /> */}
            </div>
          )}
        </div>
        {width <= 768 && (
          <div
            className={clsx(
              styles.discountsContainer,
              showGeneralOnbAndHasBuyed &&
                isActiveDiscountOnboarding &&
                styles.discountsContainerActive
            )}
            // onClick={goToNextOnboardingStepDiscount}
          >
            {showGeneralOnbAndHasBuyed && isActiveDiscountOnboarding && (
              <OnboardingTooltip
                step={generalOnboardingStepIndex + 1}
                maxSteps={generalOnboardingSteps.length}
                title={generalOnboardingStep?.title}
                text={generalOnboardingStep?.description}
                onClickBtn={goToNextOnboardingStep}
                className={styles.discountsContainerTooltip}
                onClickSkip={skipOnboarding}
              />
            )}
            <DiscountPoint type="big" />
            {/* <FriendDiscount type="big" /> */}
          </div>
        )}
      </div>
      {width <= 768 && !isOpenTrackerList && (
        <div className={styles.stickyMenu}>
          <StickyTrackerButton onClick={onOpenTrackerMenu} />
        </div>
      )}
      <TrackerListMenu />
    </>
  );
};

export default withMainLayout(Home, false, false, false, "main");
