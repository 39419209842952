import { AppDispatch } from "../store";
import { getMe } from "../../services/user.service";
import { userSlice } from "../reducers/UserSlice";

export const fetchUser = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.userFetching());
    const { error, result } = await getMe();
    if (error) {
      dispatch(
        userSlice.actions.userFetchingError(
          error.message || "Something went wrong"
        )
      );
    }
    if (!error && result?.status === 200) {
      dispatch(userSlice.actions.userFetchingSuccess(result.data.data || null));
    }
  } catch (e) {
    dispatch(userSlice.actions.userFetchingError("Something went wrong"));
  }
};
