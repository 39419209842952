import { AppDispatch } from "../store";
import { getStatistics } from "../../services/statistic.service";
import { statisticSlice } from "../reducers/StatisticSlice";

export const fetchStatistic =
  (type: "day" | "week" | "month") => async (dispatch: AppDispatch) => {
    try {
      dispatch(statisticSlice.actions.statisticFetching());
      const { error, result } = await getStatistics(type);
      if (error) {
        dispatch(
          statisticSlice.actions.statisticFetchingError(
            error.message || "Something went wrong"
          )
        );
      }
      if (!error && result?.status === 200) {
        dispatch(
          statisticSlice.actions.statisticFetchingSuccess(result.data.data || null)
        );
      }
    } catch (e) {
      dispatch(statisticSlice.actions.statisticFetchingError("Something went wrong"));
    }
  };
